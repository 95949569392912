//import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../App.css';
import '../assets/css/main.css'
import '../assets/css/responsive.css'
import '../assets/css/vendor/animate.min.css'
// import './assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/slick.css'
import '../assets/js/main.js'
// import './assets/css/vendor/animate.min.css'
import Section1 from '../../src/components/Section1';
import Section1New from '../../src/components/Section1New';
import Section1New1 from '../../src/components/Section1New1';
import Section2 from '../../src/components/Section2';
import IntiutiveLuxury from '../../src/components/IntiutiveLuxury';
import IntiutiveTech from '../../src/components/IntiutiveTech';
import Sustainability from '../../src/components/Sustainability';
import IntiutiveLiving from '../../src/components/IntiutiveLiving';
import WelcomeCurosity from '../../src/components/WelcomeCurosity';
import OurProperties from '../../src/components/OurProperties';
import ServerMaintainance from '../../src/components/ServerMaintainance';
import {NavLink,Link} from "react-router-dom";
function Home() {
  return ( 

    <>
     {/* <ServerMaintainance/> */}
    <Section1New1/>
           <Section2/>
           {/* <OurProperties/> */}
           <IntiutiveLuxury/>
           <IntiutiveTech/>
           <Sustainability/>
           <IntiutiveLiving/>
           <WelcomeCurosity/>
    
          
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default Home;
