'use client'



import React, { useState } from 'react'
import { sendEnquireData } from '../dataService/DataService';
//import nodemailer from "nodemailer"
import { toast } from "react-toastify";
import { useMutation } from 'react-query';



import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlider1 from './Slider1';
import CustomSlider2 from './Slider2';
import CustomSlider3 from './Slider3';
import CustomSlider4 from './Slider4';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import Image from 'next/image'
// import { useSpring, animated } from '@react-spring/web'
// import { useState,useEffect,useRef } from 'react';
// import Slider from "react-slick";
export default function Section1() {
    const [formData, setFormData] = useState({
        name: '',
         phone: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const newErrors = { ...errors };

        if (name === "name" && !value) {
            newErrors.name = "Please enter full name";
        } else if (name === "name") {
            delete newErrors.name; // Clear the error if the field is valid
        }

        if (name === "email" && !value) {
            newErrors.email = "Please enter email";
        } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = "Please enter valid email";
        } else if (name === "email") {
            delete newErrors.email;
        }

        if (name === "phone" && !value) {
            newErrors.phone = "Please enter phone no.";
        } else if (name === "phone" && !/^\d{10}$/.test(value)) {
            newErrors.phone = "Please enter valid phone no.";
        } else if (name === "phone") {
            delete newErrors.phone;
        }

        if (name === "message" && !value) {
            newErrors.message = "Please enter message";
        } else if (name === "message") {
            delete newErrors.message;
        }

        // Set the updated errors object
        setErrors(newErrors);

    };
    function sendEmail() {
        console.log("button blicked ")
        console.log("formData")
        console.log(formData)
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Please enter full name";
        }
        if (!formData.email) {
            newErrors.email = "Please enter email";
        }
        if (!formData.phone) {
            newErrors.phone = "Please enter phone no.";
        }
        if (!formData.message) {
            newErrors.message = "Please enter message";
        }
        if (Object.keys(newErrors).length === 0) {
            sendEnquireDat.mutate(formData);
            setErrors(newErrors);
        } else {
            setErrors(newErrors);

        }
    }

    const sendEnquireDat = useMutation(sendEnquireData, {
        onError: (error) => {
            toast.error(error.message);

        },
        onSuccess: (data) => {

            toast.success("Enquiry submitted. We will contact you soon.");
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: '',
            });
            // console.log("Succesful",{data});
        },
    });
    
return (
<>

<section className="welcome-our-curiosity">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <form action="#" method="get">
                    
                        <div className="curiosity-form m-auto">
                            <div className='row'>
                            <div className='col-md-4'>
                                <div className="form-input mb-4">
                                    <input type="text" placeholder="Your name"  id="" className="form-control border-0 rounded-0" name="name" value={formData.name} onChange={handleChange} required />
                                    <p className="form-error text-danger">
                                                        {errors.name ? errors.name : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                            <div className="form-input mb-4">
                                    <input type="email"placeholder="Your email"   id="" className="form-control border-0 rounded-0" name="email" value={formData.email} onChange={handleChange} required/>
                                    <p className="form-error text-danger">
                                                        {errors.email ? errors.email : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                            <div className="form-input mb-4">
                                    <input type="tel"placeholder="Phone"   id="" className="form-control border-0 rounded-0" name="phone" value={formData.phone} onChange={handleChange} required/>
                                    <p className="form-error text-danger">
                                                        {errors.phone ? errors.phone : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-input h-100">
                                    <textarea placeholder="Message" className="textarea-field form-control border-0 rounded-0" name="message" value={formData.message} onChange={handleChange} required></textarea>
                                    <p className="form-error text-danger">
                                                        {errors.message ? errors.message : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-12 text-end mt-4'>
                                <div className="form-submit">
                                {sendEnquireDat.isLoading ? (<button className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                            <span role="status">Loading...</span>
                                                        </button>) : (<button type="submit" onClick={sendEmail} className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white">Submit</button>)}
                                    {/* <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white"/> */}
                                </div>
                            </div>
                        </div>
                            <div className="form-checkbox d-none d-md-none justify-content-center position-relative">
                                <label htmlFor="TermConditionOne" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermConditionOne"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                            
                        </div>
                        <div className="form-checkbox d-none mt-lg-5 mt-4 justify-content-center position-relative">
                            <label htmlFor="TermCondition" className="d-flex align-items-center">
                                <input type="checkbox" name="" id="TermCondition"/>
                                <div className="check-term"></div>
                                <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>


</>




)
    

}