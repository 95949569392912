import React, { useState } from 'react'
import { sendEnquireData } from '../dataService/DataService';
//import nodemailer from "nodemailer"
import { toast } from "react-toastify";
import { Form } from 'react-router-dom';
import { useMutation } from 'react-query';

const PartnerWithUs = () => {
  const [formData, setFormData] = useState({
    name: '',
     phone: '',
    email: '',
    message: '',
});
const [errors, setErrors] = useState({});

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const newErrors = { ...errors };

    if (name === "name" && !value) {
        newErrors.name = "Please enter full name";
    } else if (name === "name") {
        delete newErrors.name; // Clear the error if the field is valid
    }

    if (name === "email" && !value) {
        newErrors.email = "Please enter email";
    } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
        newErrors.email = "Please enter valid email";
    } else if (name === "email") {
        delete newErrors.email;
    }

    if (name === "phone" && !value) {
        newErrors.phone = "Please enter phone no.";
    } else if (name === "phone" && !/^\d{10}$/.test(value)) {
        newErrors.phone = "Please enter valid phone no.";
    } else if (name === "phone") {
        delete newErrors.phone;
    }

    if (name === "message" && !value) {
        newErrors.message = "Please enter message";
    } else if (name === "message") {
        delete newErrors.message;
    }

    // Set the updated errors object
    setErrors(newErrors);

};
function sendEmail() {
    console.log("button blicked ")
    console.log("formData")
    console.log(formData)
    const newErrors = {};
    if (!formData.name) {
        newErrors.name = "Please enter full name";
    }
    if (!formData.email) {
        newErrors.email = "Please enter email";
    }
    if (!formData.phone) {
        newErrors.phone = "Please enter phone no.";
    }
    if (!formData.message) {
        newErrors.message = "Please enter message";
    }
    if (Object.keys(newErrors).length === 0) {
        sendEnquireDat.mutate(formData);
        setErrors(newErrors);
    } else {
        setErrors(newErrors);

    }
}

const sendEnquireDat = useMutation(sendEnquireData, {
    onError: (error) => {
        toast.error(error.message);

    },
    onSuccess: (data) => {

        toast.success("Enquiry submitted. We will contact you soon.");
        setFormData({
            name: '',
            phone: '',
            email: '',
            message: '',
        });
        // console.log("Succesful",{data});
    },
});

  return (
    <>
      {/* <!-- Banner Inner --> */}
      <section>
        <div className="banner-inner partner-with-us overflow-hidden">
          <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
              <div className="col-md-8">
                <div className="banner-title text-center">
                  <h1><span>Partner</span> with us</h1>
                  <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                    <div className="logo-circle">
                      <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Short Intro --> */}
      <section>
        <div className="short-intro together-we-strong mt-0">
          <div className="container container-custom">
            <div className="row">
              <div className="col-md-12">
                <div className="short-intro-heading">
                  <div className="heading-type-two">
                    <h2>Trust us to be <span>trusted</span></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="partner-des text-center">
                  <p>We are not just building ordinary homes on empty lands, we are crafting an experience unmatched in comfort, hospitality and lifestyle benefits.<br/> With us, your land isn’t just in safe hands; it is in the hands of those who carry a legacy of trust in crafting exceptional properties that generate value.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Partner --> */}
      <section>
        <div className="comfort comfort-land-owner rentalpool">
          <div className="comfort-block comfort-type-two position-relative">
            <div className="comfort-visual">
              <img src="/images/visuals/clients-talking.jpg" alt="" className="img-fluid" />
            </div>
            <div className="comfort-panel d-flex align-items-center position-absolute start-0 top-0 w-100 h-100">
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <div className="comfort-description">
                      <div className="heading-type-two">
                        <div className="small-heading">
                        <hr/>
                          <span>RENTAL POOL </span>
                        </div>
                        <h2>For land owners</h2>
                      </div>
                      <div className="comfort-body">
                        <p>Unlock the revenue potential of your land while enjoying the benefits of our brand's expertise in crafting extraordinary living spaces for discerning buyers.</p>
                        <div className="comfirt-action mt-4 d-none">
                          <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                            <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next" /></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Work Process --> */}
      <section>
        <div className="work-process">
          <div className="container container-custom">
            <div className="row">
              <div className="col-md-12">
                <div className="heading-type-two">
                  <div className="small-heading">
                    <span>THE PROCESS</span>
                  </div>
                  <h2>Your land, our expertise</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="work-step ms-auto me-auto position-relative">
                  <ul className="list-unstyled m-0 p-0">
                    <li>
                      <div className="work-step-card">
                        <div className="work-step-card-icon rounded-pill d-flex align-items-center justify-content-center position-absolute top-0 start-0 end-0 me-auto ms-auto">
                          <span>01</span>
                        </div>
                        <div className="work-step-card-body">
                          <div className="mb-3 mt-3">
                            <h3>Have <span>prime land</span></h3>
                          </div>
                          <p>We begin by assessing the potential, value and location of your land.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="work-step-card">
                        <div className="work-step-card-icon rounded-pill d-flex align-items-center justify-content-center position-absolute top-0 start-0 end-0 me-auto ms-auto">
                          <span>02</span>
                        </div>
                        <div className="work-step-card-body">
                          <div className="mb-3 mt-3">
                            <h3>Sign an <span>agreement</span></h3>
                          </div>
                          <p>We enter an exclusive partnership to build properties on your land.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="work-step-card">
                        <div className="work-step-card-icon rounded-pill d-flex align-items-center justify-content-center position-absolute top-0 start-0 end-0 me-auto ms-auto">
                          <span>03</span>
                        </div>
                        <div className="work-step-card-body">
                          <div className="mb-3 mt-3">
                            <h3>A seamless <span>experience</span></h3>
                          </div>
                          <p>Our process-based software takes care of your entire purchase journey.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="work-step-card">
                        <div className="work-step-card-icon rounded-pill d-flex align-items-center justify-content-center position-absolute top-0 start-0 end-0 me-auto ms-auto">
                          <span>04</span>
                        </div>
                        <div className="work-step-card-body">
                          <div className="mb-3 mt-3">
                            <h3>Access all <span>documents</span></h3>
                          </div>
                          <p>All documents are available on our app for your viewing and reference.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="work-step-card">
                        <div className="work-step-card-icon rounded-pill d-flex align-items-center justify-content-center position-absolute top-0 start-0 end-0 me-auto ms-auto">
                          <span>05</span>
                        </div>
                        <div className="work-step-card-body">
                          <div className="mb-3 mt-3">
                            <h3>Stay <span>up-to-date</span></h3>
                          </div>
                          <p>Track project development and construction status with our user-friendly app.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Tie Up --> */}
      <section>
        <div className="contactus-form partners-form-contact tie-up">
          <div className="container container-custom">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-xl-7 col-md-6">
                    <div className="contactus-form-description partners-contact-form ps-0">
                      <div className="heading-type-two">
                        <h2><span>Get in touch</span> with us</h2>
                      </div>
                      <div className="contact-des mt-lg-4 mb-4 pt-2 pb-xl-2">
                        <p>If you're interested in building something beyond ordinary homes.</p>
                      </div>
                      <div className="form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <input type="text" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Name" name="name" value={formData.name} onChange={handleChange} required />
                            </div>
                            <p className="form-error text-danger">
                              {errors.name ? errors.name : ""}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <input type="text" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} required />
                            </div>
                            <p className="form-error text-danger">
                              {errors.phone ? errors.phone : ""}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-4">
                              <input type="email" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required />
                            </div>
                            <p className="form-error text-danger">
                              {errors.email ? errors.email : ""}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-4">
                              <textarea className="form-control atamosphere-input rounded-0 bg-transparent" rows="5" placeholder="Message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                            </div>
                            <p className="form-error text-danger">
                              {errors.message ? errors.message : ""}
                            </p>
                          </div>
                          {/* <div className="col-md-12">
                            <div className="contactus-form-radio mt-2 mb-xl-4 mb-2">
                              <div className="form-checkbox d-flex position-relative">
                                <label for="TermCondition" className="d-flex align-items-center">
                                  <input type="checkbox" name="" id="TermCondition" />
                                  <div className="check-term"></div>
                                  <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="col-md-12">
                            <div className="contactus-form-input mt-3">
                              <button type="submit" className="btn btn-yellow w-100 rounded-0 fw-medium text-uppercase">Submit</button>
                            </div>
                          </div> */}
                           <div className="col-md-12">
                                                    <div className="contactus-form-input mt-3">
                                                        {sendEnquireDat.isLoading ? (<button className="btn btn-yellow w-100 rounded-0 fw-medium text-uppercase" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                            <span role="status">Loading...</span>
                                                        </button>) : (<button type="submit" onClick={sendEmail} className="btn btn-yellow w-100 rounded-0 fw-medium text-uppercase">Submit</button>)}


                                                    </div>
                                                </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-md-6">
                    <div className="features-window d-flex align-items-start">
                      <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3 mb-0">
                        <span className="d-flex overflow-hidden"><img src="/images/visuals/guy-talking-on-phone.jpg" alt="" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PartnerWithUs