import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({  ...settings }) => {
    return (
        <Slider {...settings} >
            <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart keycards</span>
                                            <p>Securely enter with smart keycards for keyless convenience.</p>
                                            {/* <p>Enter your residence securely through smart keycards enabled for frictionless entry offering a keyless convenience that is innovative yet reassuring.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/mob-app.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Access Amenities </span>
                                            <p>Unlock exclusive amenities with a simple touch of technology.</p>
                                            {/* <p>Tap into effortless luxury at your fingertips. Access amenities, make requests, and seamlessly manage every aspect of your living experience.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smartly-controlled.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart controls</span>
                                            <p>Elevate your lifestyle with intuitive smart controls for personalised comfort.</p>

                                            {/* <p>Embrace a living space that adapts to your preferences through intuitive controls, effortlessly enhancing comfort while you focus on what truly matters.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-living.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Track Earnings</span>
                                            <p>Effortlessly monitor earnings and property performance with intuitive tracking.</p>
                                            {/* <p>From personalized digital frames to dynamic settings managed via our app, your daily interactions are elevated by intuitive and intelligent technology.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          
        </Slider>
    );
};

export default CustomSlider;
