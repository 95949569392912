import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({  ...settings }) => {
    return (
        <Slider {...settings} >
            <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/renowned-architects.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                        <span>Renowned Architects</span>
                                                        <p>They craft a world with every imaginable indulgence under one roof.</p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/acclaimed-designers.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Acclaimed Designers</span>
                                                    <p>They blend luxury with intuitive design to create captivating spaces.
                                                    </p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/bespoke-designs.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Bespoke Designs</span>
                                                    <p>A design-forward residence that reflects and celebrates your individuality.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/gated-community.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Gated Community</span>
                                                    <p>A safe haven with controlled access points and security personnel.</p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/renowned-architects.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Renowned Architects</span>
                                                    <p>They craft a world with every imaginable indulgence under one roof.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            {/* <div className="slide-arrows">
                                            <button 
                                            
                                            className="btn btn-yellow-arrow yellow-prev"><i className="fa fa-chevron-left"></i></button>
                                            <button
                                              
                                              className="btn btn-yellow-arrow yellow-next"><i className="fa fa-chevron-right"></i></button>
                                        </div> */}
        </Slider>
    );
};

export default CustomSlider;
