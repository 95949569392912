import React from 'react'
import {Route, Routes} from "react-router-dom"
import Blog from "../pages/Blog"
import Home from "../pages/Home"
import CustomerRelation from "../pages/CustomerRelation"
import MediaGallery from "../pages/MediaGallery"
import OurBoard from "../pages/OurBoard"
import OurManagement from "../pages/OurManagement"
import OurTeam from "../pages/OurTeam"
import ResponsibleDevelopment from "../pages/ResponsibleDevelopment"
import SocialInfluence from "../pages/SocialInfluence"
import PartnerWithUs from "../pages/PartnerWithUs"
import PleaseNote from "../pages/PleaseNote"
import Header from "../pages/Header"
import Footer from "../pages/Footer"
const AllRoutes = () => {
  return (
    <>
    <Routes>
    <Route  path="/" element={<><Header/> <Home /> <Footer/> </>}  />
    {/* <Route  path="/" element={<> <PleaseNote />  </>}  /> */}
<Route  path="/Blog" element={<><Header/> <Blog /><Footer/>  </>}  />
<Route  path="/CustomerRelation" element={<> <Header/><CustomerRelation /> <Footer/> </>}  />
<Route  path="/MediaGallery" element={<><Header/> <MediaGallery /> <Footer/> </>}  />
<Route  path="/OurBoard" element={<><Header/> <OurBoard /> <Footer/> </>}  />
<Route  path="/OurManagement" element={<> <Header/><OurManagement /> <Footer/> </>}  />
<Route  path="/OurTeam" element={<><Header/> <OurTeam /> <Footer/> </>}  />
<Route  path="/ResponsibleDevelopment" element={<><Header/> <ResponsibleDevelopment /> <Footer/> </>}  />
<Route  path="/SocialInfluence" element={<><Header/> <SocialInfluence /> <Footer/> </>}  />
<Route  path="/PartnerWithUs" element={<><Header/> <PartnerWithUs /> <Footer/> </>}  />


    </Routes>
    
    </>
  )
}

export default AllRoutes