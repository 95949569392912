'use client'
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import IntiutiveTechSlider from './IntiutiveTechSlider';
import CustomSlider2 from './Slider2';
import CustomSlider3 from './Slider3';
import CustomSlider4 from './Slider4';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import 'bootstrap/dist/js/bootstrap.bundle.min.js';
 import { NavLink } from 'react-router-dom';
// import Image from 'next/image'
// import { useSpring, animated } from '@react-spring/web'
// import { useState,useEffect,useRef } from 'react';
// import Slider from "react-slick";
export default function Section1() {
    const sliderSettings = {
        // dots: true,
         infinite: true,
         speed: 500,
         slidesToShow: 4,
         slidesToScroll: 1,
         swipeToSlide: true,
         responsive: [
           {
             breakpoint: 1024,
             settings: {
               slidesToShow: 3,
             },
           },
           {
             breakpoint: 768, 
             settings: {
               slidesToShow: 2,
             },
           },
           {
             breakpoint: 576,
             settings: {
               slidesToShow: 2,
             },
           },
         ],
       };

       // Define an array of element IDs you want to click
// const elementIDs = ['element1', 'element2', 'element3', 'element4'];

// // Function to click an element by its ID
// function clickElementByID(id) {
//   const element = document.getElementById(id);
//   if (element) {
//     element.click();
//     console.log(`Clicked element with ID: ${id}`);
//   } else {
//     console.error(`Element with ID ${id} not found`);
//   }
// }

// // Function to click elements in a loop with a delay
// function clickElementsWithDelay() {
//   let index = 0;

//   function clickNextElement() {
//       const id = elementIDs[index];
//       clickElementByID(id);
//       index++;
//       setTimeout(clickNextElement, 3000); // Delay in milliseconds (1 second in this case)
//         if(index===4){
//             index=0
//         }
//   }

//   // Start clicking elements
//   clickNextElement();
// }

// // Call the function to start clicking elements
// clickElementsWithDelay();



const elementIDs = ['element1', 'element2', 'element3', 'element4'];
let shouldStopLoop = false; // Flag to control the loop

// Function to click an element by its ID
function clickElementByID(id) {
  const element = document.getElementById(id);
  if (element) {
    element.click();
    //console.log(`Clicked element with ID: ${id}`);
  } else {
    //console.error(`Element with ID ${id} not found`);
  }
}

// Function to stop the loop when any element is hovered
function stopLoopOnHover() {
  shouldStopLoop = true;
}

// Function to resume the loop when the mouse moves out of an element
function resumeLoopOnMouseOut() {
  shouldStopLoop = false;
  clickElementsWithDelay(); // Resume the loop
}

// Function to click elements in a loop with a delay
function clickElementsWithDelay() {
  let index = 0;

  function clickNextElement() {
    if (shouldStopLoop) {
      console.log("Loop stopped due to element hover.");
      return;
    }

    const id = elementIDs[index];
    clickElementByID(id);
    index++;
    if (index === elementIDs.length) {
      index = 0;
    }

    setTimeout(clickNextElement, 3000); // Delay in milliseconds (3 seconds in this case)
    elementIDs.forEach((id) => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener('mouseover', stopLoopOnHover);
      element.addEventListener('mouseout', resumeLoopOnMouseOut);
    }
  });
  }

  // Add a mouseover event listener to each element to stop the loop
  

  // Start clicking elements
  clickNextElement();
}

// Call the function to start clicking elements
clickElementsWithDelay();

   
return (
<>

    <section className="intuitive-tech d-none d-md-block pb-0">
        <div className="container">
            <div className="row mb-xl-3 mb-lg-4 mb-3 align-items-center">
                <div className="col-lg-6 col-md-7">
                    <div className="intuitive-heading">
                        <div className="small-heading">
                            <hr/><span>Intuitive tech</span>
                        </div>
                        <div className="secondary-heading">
                            <h2>Seamless living, smartly powered</h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-5 d-none d-md-block">
                    <div className="intuitive-action text-end">
                        <a className="yellow-btn-wrap d-none" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                            <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 position-relative d-none d-md-block">
                    <nav>
                        <div className="nav nav-tabs smart-tech-tabs border-0 d-flex" id="nav-tab" role="tablist">

                            <div className="left-side-tabs d-flex justify-content-start flex-wrap w-50">
                                <button className="nav-link position-relative active p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-smartkeyboard-tab" data-bs-toggle="tab" data-bs-target="#nav-smartkeyboard" type="button" role="tab" aria-controls="nav-smartkeyboard" aria-selected="true">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div id="element1" className="intuitive-card-body">
                                            <span>Smart keycards</span>
                                            <p>Securely enter with smart keycards for keyless convenience.</p>
                                        </div>
                                    </div>
                                </button>
                                <button className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartMobApp-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartMobApp" type="button" role="tab" aria-controls="nav-SmartMobApp" aria-selected="false">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/mob-app.svg" alt=""/>
                                        </div>
                                        <div id="element2" className="intuitive-card-body">
                                            <span>Access Amenities</span>
                                            <p>Unlock exclusive amenities with a simple touch of technology.</p>
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="right-side-tabs d-flex justify-content-end flex-wrap w-50">
                                <button className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartControlled-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartControlled" type="button" role="tab" aria-controls="nav-SmartControlled" aria-selected="false">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/smartly-controlled.svg" alt=""/>
                                        </div>
                                        <div id="element3" className="intuitive-card-body">
                                            <span>Smart controls</span>
                                            <p>Elevate your lifestyle with intuitive smart controls for personalised comfort.</p>
                                        </div>
                                    </div>
                                </button>

                                <button className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartLiving-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartLiving" type="button" role="tab" aria-controls="nav-SmartLiving" aria-selected="false">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/smart-living.svg" alt=""/>
                                        </div>
                                        <div id="element4" className="intuitive-card-body">
                                            <span>Track Earnings</span>
                                            <p>Effortlessly monitor earnings and property performance with intuitive tracking.</p>
                                        </div>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </nav>
                    <div className="intuitive-visual text-center position-absolute bottom-0 start-0 end-0 m-auto w-50 overflow-hidden">
                        <div className="iphone-frame m-auto position-relative z-3">
                            <img src="/images/icon/iphone-frame.svg" alt="iPhone" title="iPhone" />
                        </div>
                        <div className="tab-content position-absolute m-auto start-0 end-0 w-100 top-0" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-smartkeyboard" role="tabpanel" aria-labelledby="nav-smartkeyboard-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartMobApp" role="tabpanel" aria-labelledby="nav-SmartMobApp-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartControlled" role="tabpanel" aria-labelledby="nav-SmartControlled-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartLiving" role="tabpanel" aria-labelledby="nav-SmartLiving-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="smartech-mobile-slider d-block d-md-none">
            <div className="slider-inner-wrap position-relative">

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart keycards</span>
                                            <p>Securely enter with smart keycards for keyless convenience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Access Amenities</span>
                                            <p>Unlock exclusive amenities with a simple touch of technology.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart controls</span>
                                            <p>Elevate your lifestyle with intuitive smart controls for personalised comfort.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Track Earnings</span>
                                            <p>Effortlessly monitor earnings and property performance with intuitive tracking.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section className="intuitive-tech d-block d-md-none pb-0">
        <div className="container">
            <div className="row mb-xl-3 mb-lg-4 mb-3 align-items-center">
                <div className="col-lg-6 col-md-7">
                    <div className="intuitive-heading">
                        <div className="small-heading">
                            <hr/><span>Intuitive tech</span>
                        </div>
                        <div className="secondary-heading">
                            <h2>Seamless living, smartly powered</h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-5 d-none d-md-block">
                    <div className="intuitive-action text-end">
                        <a className="yellow-btn-wrap d-none" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                            <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 position-relative d-none d-md-block">
                    <nav>
                        <div className="nav nav-tabs smart-tech-tabs border-0 d-flex" id="nav-tab" role="tablist">

                            <div className="left-side-tabs d-flex justify-content-start flex-wrap w-50">
                                <a className="nav-link position-relative active p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-smartkeyboard-tab" data-bs-toggle="tab" data-bs-target="#nav-smartkeyboard" type="button" role="tab" aria-controls="nav-smartkeyboard" aria-selected="true">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body">
                                            <span>Smart keycards</span>
                                            <p>Securely enter with smart keycards for keyless convenience.</p>
                                        </div>
                                    </div>
                                </a>
                                <a className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartMobApp-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartMobApp" type="button" role="tab" aria-controls="nav-SmartMobApp" aria-selected="false">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/mob-app.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body">
                                            <span>Access Amenities</span>
                                            <p>Unlock exclusive amenities with a simple touch of technology.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="right-side-tabs d-flex justify-content-end flex-wrap w-50">
                                <a className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartControlled-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartControlled" type="button" role="tab" aria-controls="nav-SmartControlled" aria-selected="false">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/smartly-controlled.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body">
                                            <span>Smart controls</span>
                                            <p>Elevate your lifestyle with intuitive smart controls for personalised comfort.</p>
                                        </div>
                                    </div>
                                </a>

                                <a className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartLiving-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartLiving" type="button" role="tab" aria-controls="nav-SmartLiving" aria-selected="false">
                                    <div className="intuitive-card mt-0">
                                        <div className="intuitive-card-icon">
                                            <img src="/images/icon/smart-living.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body">
                                            <span>Track Earnings</span>
                                            <p>Effortlessly monitor earnings and property performance with intuitive tracking.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </nav>
                    <div className="intuitive-visual text-center position-absolute bottom-0 start-0 end-0 m-auto w-50 overflow-hidden">
                        <div className="iphone-frame m-auto position-relative z-3">
                            <img src="/images/icon/iphone-frame.svg" alt="iPhone" title="iPhone" />
                        </div>
                        <div className="tab-content position-absolute m-auto start-0 end-0 w-100 top-0" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-smartkeyboard" role="tabpanel" aria-labelledby="nav-smartkeyboard-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartMobApp" role="tabpanel" aria-labelledby="nav-SmartMobApp-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartControlled" role="tabpanel" aria-labelledby="nav-SmartControlled-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartLiving" role="tabpanel" aria-labelledby="nav-SmartLiving-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="smartech-mobile-slider d-block d-md-none">
            <div className="slider-inner-wrap position-relative">
<IntiutiveTechSlider/>
                {/* <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart keycards</span>
                                            <p>Enter your residence securely through smart keycards enabled for frictionless entry offering a keyless convenience that is innovative yet reassuring.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart mobile-app enabled</span>
                                            <p>Tap into effortless luxury at your fingertips. Access amenities, make requests, and seamlessly manage every aspect of your living experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smartly controlled</span>
                                            <p>Embrace a living space that adapts to your preferences through intuitive controls, effortlessly enhancing comfort while you focus on what truly matters.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home" />
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart living</span>
                                            <p>From personalized digital frames to dynamic settings managed via our app, your daily interactions are elevated by intuitive and intelligent technology.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </div>
    </section>

{/* <section className="intuitive-tech pb-0">
        <div className="container">
            <div className="row mb-xl-3 mb-lg-4 mb-3 align-items-center">
                <div className="col-lg-6 col-md-7">
                    <div className="intuitive-heading">
                        <div className="small-heading">
                            <hr/><span>Intuitive tech</span>
                        </div>
                        <div className="secondary-heading">
                            <h2>Seamless living, <span className="yellow-text">smartly powered</span></h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-5 d-none d-md-block">
                    <div className="intuitive-action text-end">
                        <NavLink to="/MediaGallery" className="yellow-btn-wrap"><span className="btn theme-btn yellow-theme-btn">Learn more</span> <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span></NavLink>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 position-relative d-none d-md-block">
                    <nav>
                        <div className="nav nav-tabs smart-tech-tabs border-0 d-flex" id="nav-tab" role="tablist">
                          
                        <div className="left-side-tabs d-flex justify-content-start flex-wrap w-50">
                            <a className="nav-link position-relative active p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-smartkeyboard-tab" data-bs-toggle="tab" data-bs-target="#nav-smartkeyboard" type="button" role="tab" aria-controls="nav-smartkeyboard" aria-selected="true">
                                <div className="intuitive-card mt-0">
                                    <div className="intuitive-card-icon">
                                        <img src="/images/icon/smart-keycards.svg" alt=""/>
                                    </div>
                                    <div className="intuitive-card-body">
                                        <span>Digital keycards</span>
                                        <p>Securely enter with smart keycards for keyless convenience.</p>
                                    </div>
                                </div>
                              </a>
                              <a className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartMobApp-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartMobApp" type="button" role="tab" aria-controls="nav-SmartMobApp" aria-selected="false">
                                <div className="intuitive-card mt-0">
                                    <div className="intuitive-card-icon">
                                        <img src="/images/icon/mob-app.svg" alt=""/>
                                    </div>
                                    <div className="intuitive-card-body">
                                        <span>Track Earning</span>
                                        <p>Effortlessly monitor earnings and property performance with intuitive tracking.</p>
                                    </div>
                                </div>
                              </a>
                        </div>

                          <div className="right-side-tabs d-flex justify-content-end flex-wrap w-50">
                            <a className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartControlled-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartControlled" type="button" role="tab" aria-controls="nav-SmartControlled" aria-selected="false">
                                <div className="intuitive-card mt-0">
                                    <div className="intuitive-card-icon">
                                        <img src="/images/icon/smartly-controlled.svg" alt=""/>
                                    </div>
                                    <div className="intuitive-card-body">
                                        <span>Smart Controls</span>
                                        <p>Elevate your lifestyle with intuitive smart controls for personalised comfort.</p>
                                    </div>
                                </div>
                              </a>
    
                              <a className="nav-link position-relative p-0 ps-lg-4 ps-3 bg-transparent border-0" id="nav-SmartLiving-tab" data-bs-toggle="tab" data-bs-target="#nav-SmartLiving" type="button" role="tab" aria-controls="nav-SmartLiving" aria-selected="false">
                                <div className="intuitive-card mt-0">
                                    <div className="intuitive-card-icon">
                                        <img src="/images/icon/smart-living.svg" alt=""/>
                                    </div>
                                    <div className="intuitive-card-body">
                                        <span>Access Amenities</span>
                                        <p>Unlock exclusive amenities with a simple touch of technology.</p>
                                    </div>
                                </div>
                              </a>
                          </div>

                        </div>
                      </nav>
                      <div className="intuitive-visual text-center position-absolute bottom-0 start-0 end-0 m-auto w-50 overflow-hidden">
                        <div className="iphone-frame m-auto position-relative z-3">
                            <img src="/images/icon/iphone-frame.svg" alt="iPhone" title="iPhone"/>
                        </div>
                        <div className="tab-content position-absolute m-auto start-0 end-0 w-100 top-0" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-smartkeyboard" role="tabpanel" aria-labelledby="nav-smartkeyboard-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home"/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartMobApp" role="tabpanel" aria-labelledby="nav-SmartMobApp-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home"/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartControlled" role="tabpanel" aria-labelledby="nav-SmartControlled-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home"/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-SmartLiving" role="tabpanel" aria-labelledby="nav-SmartLiving-tab" tabindex="0">
                                <div className="position-relative iphone-ui m-auto overflow-hidden">
                                    <div className="app-ui-design">
                                        <img src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home"/>
                                    </div>
                                </div>
                            </div>
                          </div>
                    </div>
            </div>
        </div>
            </div>
            
        <div className="smartech-mobile-slider d-block d-md-none">
            <div className="slider-inner-wrap position-relative">
                
                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartkeyboard.jpg" alt="Control Your Home" title="Control Your Home"/>
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart keycards</span>
                                            <p>Enter your residence securely through smart keycards enabled for frictionless entry offering a keyless convenience that is innovative yet reassuring.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smartlly-controlled.jpg" alt="Control Your Home" title="Control Your Home"/>
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart mobile-app enabled</span>
                                            <p>Tap into effortless luxury at your fingertips. Access amenities, make requests, and seamlessly manage every aspect of your living experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/control-your-home.jpg" alt="Control Your Home" title="Control Your Home"/>
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smartly controlled</span>
                                            <p>Embrace a living space that adapts to your preferences through intuitive controls, effortlessly enhancing comfort while you focus on what truly matters.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item">
                    <div className="smart-slide-img overflow-hidden m-auto">
                        <img className="img-fluid" src="/images/visuals/smart-living.jpg" alt="Control Your Home" title="Control Your Home"/>
                    </div>
                    <div className="smart-slide-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="intuitive-card d-flex align-items-start">
                                        <div className="intuitive-card-icon mt-1">
                                            <img src="/images/icon/smart-keycards.svg" alt=""/>
                                        </div>
                                        <div className="intuitive-card-body ps-3">
                                            <span className="mt-0">Smart living</span>
                                            <p>From personalized digital frames to dynamic settings managed via our app, your daily interactions are elevated by intuitive and intelligent technology.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section> */}

</>




)
    

}