
  // Function to handle scroll event
  function handleScroll() {
    const header = document.querySelector('header');
    const logo = document.getElementById('logo');
    const headerContainer = document.getElementById('header-container');
    const bars = document.querySelectorAll(".bar");

    if (window.scrollY > 100) {
      header.classList.add('sticky');
     // logo.src = "../images/icon/text-logo.svg";
      headerContainer.classList.add('container-fluid');
      bars.forEach(bar => {
        bar.style.backgroundColor = "#ffffff";
      });
    } else {
      header.classList.remove('sticky');

      // Check if ".atmosphere-living-body" exists
      const atmosphereLivingBody = document.querySelector('.atmosphere-living-body');
      if (atmosphereLivingBody) {
        logo.src = "../images/icon/logo-green.svg";
        bars.forEach(bar => {
          bar.style.backgroundColor = "#333333";
        });
      } else {
      //  logo.src = "../images/icon/logo.svg";
      }

      headerContainer.classList.remove('container-fluid');
    }
  }

  // Attach scroll event listener to the window
  window.addEventListener('scroll', handleScroll);
