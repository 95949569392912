import React from 'react'

const OurTeam = () => {
  return (
    <>

{/* <!-- Short Intro --> */}
    <section className="short-intro">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <h1>Our <span>Team</span></h1>
                    <div className="short-intro-bg my-5">
                        <img src="/images/icon/underline.png" alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="short-intro-text">
                        <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Management --> */}
    <section className="management">
        <div className="container container-custom">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="heading-type-two">
                        <h2>Our <span>Team</span></h2>
                    </div>
                </div>
                <div className="col-md-2 d-none">
                    <div className="management-action mt-4">
                        <a href="" className="btn btn-atmosphere btn-yellow">Know more</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member1.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member2.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member3.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member4.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member5.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member6.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member7.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member8.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member9.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member10.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member11.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member12.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


   
    <section className="contact-form-inner">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-form mt-0">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="contact-form-heading text-center">
                                    <h2>Our doors are wide open and we welcome your curiosity</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="text" className="form-control" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="email" className="form-control" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-5">
                                        <div className="contact-form-radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                                <label className="form-check-label" for="terms">
                          I accept <b>Terms &amp; Conditions</b> for procesing personal data
                        </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-form-input">
                                    <button type="submit" className="btn btn-light w-100">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <section className="short-intro">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <h1>Our <span>Team</span></h1>
                    <div className="short-intro-bg my-5">
                        <img src="/images/icon/underline.png" alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="short-intro-text">
                        <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="management">
        <div className="container container-custom">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="heading-type-two">
                        <h2>Our <span>Team</span></h2>
                    </div>
                </div>
                <div className="col-md-2 d-none">
                    <div className="management-action mt-4">
                        <a href="" className="btn btn-atmosphere btn-yellow">Know more</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member1.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member2.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member3.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member4.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member5.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member6.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member7.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member8.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member9.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member10.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member11.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/team-member12.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="contact-form-inner">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-form mt-0">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="contact-form-heading text-center">
                                    <h2>Our doors are wide open and we welcome your curiosity</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="text" className="form-control" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="email" className="form-control" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-5">
                                        <div className="contact-form-radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                                <label className="form-check-label" for="terms">
                          I accept <b>Terms &amp; Conditions</b> for procesing personal data
                        </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-form-input">
                                    <button type="submit" className="btn btn-light w-100">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>
  )
}

export default OurTeam