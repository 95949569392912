import React from 'react'

const Blog = () => {
  return (
    <>

{/* <!-- Banner Inner --> */}
    <section className="banner-inner blog-visual position-relative">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="banner-title text-center">
                        <h1>News &amp; Blogs</h1>
                        <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                            <div className="logo-circle">
                                <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Blog --> */}
    <section className="blog">
        <div className="container container-custom">
            <div className="row">        
                <div className="col-md-4 pe-xl-5 pe-lg-4">
                    <div className="blog-sidebar">
                        <div className="blog-box blog-top-box">
                            <div className="search-box">
                                <form action="#" method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Search..." aria-describedby="button-addon2"/>
                                        <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </div>
                                </form>
                            </div>
                            <div className="category pt-xl-4 pt-2">
                                <h3>Categories</h3>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center smooth position-relative active" aria-current="page" href="#"><span className="smooth">Architecture</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center smooth position-relative" href="#"><span className="smooth">Luxury Home</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center smooth position-relative" href="#"><span className="smooth">Property</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center smooth position-relative" href="#"><span className="smooth">Realstate</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center smooth position-relative" href="#"><span className="smooth">Residence</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="blog-box blog-middle-box">
                            <h3>Popular Posts</h3>
                            <div className="popular-post pt-4">
                                <div className="popular-post-card d-flex">
                                    <div className="popular-post-visual">
                                        <img src="/images/visuals/post-thumbnail1.jpg" alt=""/>
                                    </div>
                                    <div className="popular-post-body">
                                        <a href="">Apartments designed by the leading interior experts</a>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </div>
                                <div className="popular-post-card d-flex">
                                    <div className="popular-post-visual">
                                        <img src="/images/visuals/post-thumbnail1.jpg" alt=""/>
                                    </div>
                                    <div className="popular-post-body">
                                        <a href="">Nature inspired designs in the heart of metropolis</a>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </div>
                                <div className="popular-post-card d-flex">
                                    <div className="popular-post-visual">
                                        <img src="/images/visuals/post-thumbnail1.jpg" alt=""/>
                                    </div>
                                    <div className="popular-post-body">
                                        <a href="">Nature inspired designs in the heart of metropolis</a>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-box blog-bottom-box">
                            <h3>Tag Cloud</h3>
                            <div className="tag pt-2">
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Architecture</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Flat</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Home</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">House</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Land</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Property</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Real estate</a>
                                <a href="#" className="btn btn-border mb-2 me-2 text-capitalize rounded-0 bg-transparent">Residence</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /col-md-4 -->             */}
                <div className="col-md-8">
                    <div className="blog-post">
                        <div className="blog-post-visual">
                            <img src="/images/visuals/blog-post1.jpg" alt="" className="img-fluid"/>
                            <a href="#" className="arrow-btn position-absolute rounded-pill d-flex align-items-center justify-content-center bg-white">
                                <img src="/images/icon/arrow-angle.svg" alt=""/>
                            </a>
                        </div>
                        <div className="blog-post-body">
                            <h2>Apartments designed by the leading interior experts</h2>
                            <p>Atmosphere living is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book...</p>
                            <ul className="blog-detail list-unstyled m-0 d-flex align-items-center">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <span><img src="/images/icon/user.svg" alt="User" title="User"/></span>
                                        <span>Atmosphere Living</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <span><img src="/images/icon/calendar.svg" alt="Post Date" title="Post Date"/></span>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <span><img src="/images/icon/comment.svg" alt="Comments" title="Comments"/></span>
                                        <span>No Comments</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- /blog-post --> */}

                    <div className="blog-post">
                        <div className="blog-post-visual">
                            <img src="/images/visuals/blog-post1.jpg" alt="" className="img-fluid"/>
                            <a href="#" className="arrow-btn position-absolute rounded-pill d-flex align-items-center justify-content-center bg-white">
                                <img src="/images/icon/arrow-angle.svg" alt=""/>
                            </a>
                        </div>
                        <div className="blog-post-body">
                            <h2>Apartments designed by the leading interior experts</h2>
                            <p>Atmosphere living is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book...</p>
                                <ul className="blog-detail list-unstyled m-0 d-flex align-items-center">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <span><img src="/images/icon/user.svg" alt="User" title="User"/></span>
                                            <span>Atmosphere Living</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <span><img src="/images/icon/calendar.svg" alt="Post Date" title="Post Date"/></span>
                                            <span>May, 06, 2023</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <span><img src="/images/icon/comment.svg" alt="Comments" title="Comments"/></span>
                                            <span>No Comments</span>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    {/* <!-- /blog-post --> */}
                    
                    <div className="blog-post">
                        <div className="blog-post-visual">
                            <img src="/images/visuals/blog-post1.jpg" alt="" className="img-fluid"/>
                            <a href="#" className="arrow-btn position-absolute rounded-pill d-flex align-items-center justify-content-center bg-white">
                                <img src="/images/icon/arrow-angle.svg" alt=""/>
                            </a>
                        </div>
                        <div className="blog-post-body">
                            <h2>Apartments designed by the leading interior experts</h2>
                            <p>Atmosphere living is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book...</p>
                                <ul className="blog-detail list-unstyled m-0 d-flex align-items-center">
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <span><img src="/images/icon/user.svg" alt="User" title="User"/></span>
                                            <span>Atmosphere Living</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <span><img src="/images/icon/calendar.svg" alt="Post Date" title="Post Date"/></span>
                                            <span>May, 06, 2023</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <span><img src="/images/icon/comment.svg" alt="Comments" title="Comments"/></span>
                                            <span>No Comments</span>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    {/* <!-- /blog-post --> */}
                    
                    <div className="pagination">
                        <ul className="nav">
                            <li><a href="#" className="d-flex align-items-center justify-content-center rounded-pill fw-bold"><i className="fa fa-angle-double-left" aria-hidden="true"></i></a></li>
                            <li><a href="#" className="d-flex align-items-center justify-content-center rounded-pill fw-bold active">1</a></li>
                            <li><a href="#" className="d-flex align-items-center justify-content-center rounded-pill fw-bold">2</a></li>
                            <li><a href="#" className="d-flex align-items-center justify-content-center rounded-pill fw-bold"><i className="fa fa-angle-double-right" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                    {/* <!-- /pagination --> */}

                </div>
                {/* <!-- /col-md-8 --> */}

            </div>
        </div>
    </section>

    {/* <!--Welcome Our Curiosity--> */}
    <section className="welcome-our-curiosity">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <form action="#" method="get">
                        <div className="curiosity-form m-auto d-flex align-items-center">
                            <div className="form-input w-100">
                                <input type="text" placeholder="Your name" name="" id="" className="form-control border-0 rounded-0" />
                            </div>
                            <div className="form-input w-100">
                                <input type="email" placeholder="Your email" name="" id="" className="form-control border-0 rounded-0" />
                            </div>
                            <div className="form-checkbox d-flex d-md-none justify-content-center position-relative">
                                <label for="TermCondition" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermCondition"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                            <div className="form-submit">
                                <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" />
                            </div>
                        </div>
                        <div className="form-checkbox d-none d-md-flex mt-lg-5 mt-4 justify-content-center position-relative">
                            <label for="TermCondition1" className="d-flex align-items-center">
                                <input type="checkbox" name="" id="TermCondition1"/>
                                <div className="check-term"></div>
                                <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
     {/* <section className="banner-inner blog-visual">
        <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
                <div className="col-md-8">
                    <div className="banner-title text-center">
                        <h1>Blog</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

   
    <section className="blog">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-4 pe-5">
                    <div className="blog-sidebar">
                        <div className="blog-box blog-top-box">
                            <div className="search-box">
                                <form action="">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Search..." aria-describedby="button-addon2"/>
                                        <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </div>
                                </form>
                            </div>
                            <div className="category pt-4">
                                <h3>Categories</h3>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#">Architecture</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Luxury Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Property</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Realstate</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Residence</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="blog-box blog-middle-box">
                            <h3>Popular Posts</h3>
                            <div className="popular-post pt-4">
                                <div className="popular-post-card">
                                    <div className="popular-post-visual">
                                        <img src="/images/visuals/post-thumbnail1.jpg" alt=""/>
                                    </div>
                                    <div className="popular-post-body">
                                        <a href="">Apartments designed by the leading interior experts</a>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </div>
                                <div className="popular-post-card">
                                    <div className="popular-post-visual">
                                        <img src="/images/visuals/post-thumbnail1.jpg" alt=""/>
                                    </div>
                                    <div className="popular-post-body">
                                        <a href="">Nature inspired designs in the heart of metropolis</a>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </div>
                                <div className="popular-post-card">
                                    <div className="popular-post-visual">
                                        <img src="/images/visuals/post-thumbnail1.jpg" alt=""/>
                                    </div>
                                    <div className="popular-post-body">
                                        <a href="">Nature inspired designs in the heart of metropolis</a>
                                        <span>May, 06, 2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-box blog-bottom-box">
                            <h5>Tag Cloud</h5>
                            <div className="tag pt-4">
                                <a href="" className="btn btn-border mb-1">Architecture</a>
                                <a href="" className="btn btn-border mb-1">Flat</a>
                                <a href="" className="btn btn-border mb-1">Home</a>
                                <a href="" className="btn btn-border mb-1">House</a>
                                <a href="" className="btn btn-border mb-1">Land</a>
                                <a href="" className="btn btn-border mb-1">Property</a>
                                <a href="" className="btn btn-border mb-1">Real estate</a>
                                <a href="" className="btn btn-border mb-1">Residence</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="blog-post">
                        <div className="blog-post-visual">
                            <img src="/images/visuals/blog-post1.jpg" alt="" className="img-fluid"/>
                            <a href="" className="arrow-btn">
                                <img src="/images/icon/arrow-angle.svg" alt=""/>
                            </a>
                        </div>
                        <div className="blog-post-body">
                            <h2>Apartments designed by the leading interior experts</h2>
                            <p>Atmosphere living is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book...</p>
                            <ul className="nav blog-detail">
                                <li>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    <span>Atmosphere Living</span>
                                </li>
                                <li>
                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                    <span>May, 06, 2023</span>
                                </li>
                                <li>
                                    <i className="fa fa-commenting" aria-hidden="true"></i>
                                    <span>No Comments</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="blog-post">
                        <div className="blog-post-visual">
                            <img src="/images/visuals/blog-post1.jpg" alt="" className="img-fluid"/>
                            <a href="" className="arrow-btn">
                                <img src="/images/icon/arrow-angle.svg" alt=""/>
                            </a>
                        </div>
                        <div className="blog-post-body">
                            <h2>Apartments designed by the leading interior experts</h2>
                            <p>Atmosphere living is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book...</p>
                            <ul className="nav blog-detail">
                                <li>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    <span>Atmosphere Living</span>
                                </li>
                                <li>
                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                    <span>May, 06, 2023</span>
                                </li>
                                <li>
                                    <i className="fa fa-commenting" aria-hidden="true"></i>
                                    <span>No Comments</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="blog-post">
                        <div className="blog-post-visual">
                            <img src="/images/visuals/blog-post1.jpg" alt="" className="img-fluid"/>
                            <a href="" className="arrow-btn">
                                <img src="/images/icon/arrow-angle.svg" alt=""/>
                            </a>
                        </div>
                        <div className="blog-post-body">
                            <h2>Apartments designed by the leading interior experts</h2>
                            <p>Atmosphere living is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book...</p>
                            <ul className="nav blog-detail">
                                <li>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    <span>Atmosphere Living</span>
                                </li>
                                <li>
                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                    <span>May, 06, 2023</span>
                                </li>
                                <li>
                                    <i className="fa fa-commenting" aria-hidden="true"></i>
                                    <span>No Comments</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="pagination">
                        <ul className="nav">
                            <li><a href=""><i className="fa fa-angle-double-left" aria-hidden="true"></i></a></li>
                            <li><a href="" className="active">1</a></li>
                            <li><a href="">2</a></li>
                            <li><a href=""><i className="fa fa-angle-double-right" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section className="contact-form">
        <div className="container container-custom">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="contact-form-heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-md-7">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="contact-form-input">
                                <input type="text" className="form-control" placeholder="Your Name"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-form-input">
                                <input type="email" className="form-control" placeholder="Your Email"/>
                            </div>
                        </div>
                        <div className="col-md-8 mt-5">
                            <div className="contact-form-radio">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                    <label className="form-check-label" for="terms">
                  I accept <b>Terms &amp; Conditions</b> for procesing personal data
                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contact-form-input">
                        <button type="submit" className="btn btn-light w-100">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    
    </>
  )
}

export default Blog