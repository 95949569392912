import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({ ...settings }) => {
    return (
        <Slider {...settings} >
            <div className="sustainability-card">
                <div className="sustainability-card-icon">
                    <img src="/images/icon/eco-friendly.svg" alt="" />
                </div>
                {/* <!-- /sustainability-card-icon --> */}
                <div className="sustainability-slider-body responsible-card-text">
                    <span>Eco-conscious</span>
                    <p>We craft modern living spaces with an eco-conscious touch.</p>
                    <img src="" alt="" />
                </div>
                {/* <!-- /sustainability-slider-body --> */}
            </div>
            {/* <!-- /sustainability-card --> */}
            <div className="sustainability-card">
                <div className="sustainability-card-icon">
                    <img src="/images/icon/vegetation.svg" alt="" />
                </div>
                {/* <!-- /sustainability-card-icon --> */}
                <div className="sustainability-slider-body responsible-card-text">
                    <span>Lush Living</span>
                    <p>We blend urban comfort seamlessly with serene natural surroundings.</p>
                </div>
            </div>
            {/* <!-- /sustainability-card --> */}
            <div className="sustainability-card">
                <div className="sustainability-card-icon">
                    <img src="/images/icon/recycling.svg" alt="" />
                </div>
                {/* <!-- /sustainability-card-icon --> */}
                <div className="sustainability-slider-body responsible-card-text">
                    <span>Waste Wise</span>
                    <p>We use smart recycling solutions for efficient waste and water management.</p>
                </div>
                {/* <!-- /sustainability-slider-body --> */}
            </div>
            <div className="sustainability-card">
                <div className="sustainability-card-icon">
                    <img src="/images/icon/recycling.svg" alt="" />
                </div>
                {/* <!-- /sustainability-card-icon --> */}
                <div className="sustainability-slider-body responsible-card-text">
                    <span>Carbon Conscious</span>
                    <p>We are responsible in our operations to minimise our environmental impact.</p>
                </div>
                {/* <!-- /sustainability-slider-body --> */}
            </div>

        </Slider>
    );
};

export default CustomSlider;
