import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({  ...settings }) => {
    return (
        <Slider {...settings} >
            <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/rental-income.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                <div className='f-wraper'>
                                                    <span>Rental Income</span>
                                                    <p>Generate rental income from your property when not in use.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/flexible-availability.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Vacation Home</span>
                                                    <p>A second home where you can live the holiday life as you please.</p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/prestigious-property.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Prestigious Property</span>
                                                    <p>For those who value exclusivity and exceptional living experiences.</p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/effortless-ownership.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Effortless Ownership</span>
                                                    <p>We take care of all the hassle, from operations to maintenance.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/rental-income.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Rental Income</span>
                                                    <p>Generate rental income from your property when not in use.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
        </Slider>
    );
};

export default CustomSlider;
