import React,{useEffect} from 'react'
import {NavLink,Link,useLocation} from "react-router-dom";

const Header = ({setIsSideBarCollapsed}) => {

  function removeClassOnClick(elementId, className) {
    var element = document.getElementById(elementId);
    if (element) {
      element.classList.remove(className);
      element.removeAttribute("aria-modal");
      element.removeAttribute("role");
      var nextElement = element.nextElementSibling;
      if(nextElement){
      nextElement.remove();
    }
    var bodyElement = document.body;
if (bodyElement){
    // Remove the inline style attribute
    bodyElement.removeAttribute("style");
  }
    }
   
  }
  // var adjacentParagraph = document.querySelector("offcanvas-backdrop");
  // if (adjacentParagraph) {
  //   adjacentParagraph.setAttribute("id", "fadingdiv");
  // }
  const location = useLocation();
  useEffect(() => {
    // Check if the current location includes the hash fragment
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
 <header>
        {/* <!-- Top Bar --> */}
        <div className="top-bar">
            <div className="container container-custom">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact-information">
                            <div className="contact-number me-4">
                                <a href="tel:+02235230121"><img src="/images/icon/phone-icon.svg" alt="Phone Icon"/>022-35230121</a>
                            </div>
                            <div className="contact-email">
                                <a href="mailto:info@theatmosphereliving.com"><i className='fa fa-envelope me-2'></i>info@theatmosphereliving.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Navigation Menu --> */}
        <nav className="navbar navigation-menu not-fixed-on-top">
            <div className="container container-custom" id="header-container">
                <NavLink className="navbar-brand" to="/"><img id="logo" src="/images/icon/logo.svg" alt="Logo"/></NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <div className="toggler-icon">
              <div className="bar1" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar2" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar3" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
            </div>
          </button>
                <div onClick={removeClassOnClick("offcanvasNavbar", "show")} className="offcanvas offcanvas-end offcanvas-menu" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img src="/images/icon/logo-icon.svg" alt="Logo Icon"/></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/OurManagement">Leadership</NavLink>
                            </li>
                            <li className="nav-item dropdown d-none">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About us
                </a>
                                <ul className="dropdown-menu">
                                    {/* <!-- <li><a className="dropdown-item" href="our-story.html">Our story</a></li> --> */}
                                    <li><NavLink className="dropdown-item" to="/OurBoard">Our Board</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/OurManagement">Our management</NavLink></li>
                                    {/* <!-- <li><a className="dropdown-item" href="our-team.html">Our team</a></li>
                    <li><a className="dropdown-item" href="partner-with-us.html">Our Partners</a></li> --> */}
                                </ul>
                            </li>
                            {/* <!--<li className="nav-item">
                <a className="nav-link" href="branded-residences.html">Branded residences</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="amenities.html">Amenities</a>
              </li>
               <li className="nav-item">
                <a className="nav-link" href="creating-lifestyle.html">Creating Lifestyle</a>
              </li> --> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/ResponsibleDevelopment">Responsible development</NavLink>
                            </li>
                            <li className="nav-item d-none">
                                <NavLink className="nav-link" to="/MediaGallery">Media Gallery</NavLink>
                            </li>
                            <li className="nav-item dropdown d-none">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Knowledge Centre
                </a>
                                <ul className="dropdown-menu">
                                    {/* <li><NavLink className="dropdown-item" to="/MediaGallery">Media/Gallery</NavLink></li> */}
                                    <li><NavLink className="dropdown-item" to="/SocialInfluence">Social Influence</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/Blog">News &amp; Blogs</NavLink></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                          <NavLink class="nav-link " to="/PartnerWithUs">Partner with us</NavLink>
                        </li>
                            <li className="nav-item">
                                <NavLink className="nav-link " to="/CustomerRelation" role="button">Rental pool</NavLink>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/CustomerRelation#ContactUsAtmosphereSection" role="button">Contact us</Link>
                            </li>
                        </ul>
                        <div className="visit-atamosphere text-white d-none">
                            <span className="text-uppercase d-block w-100">Also Visit :</span>
                            <div className="visit-now p-3 d-inline-block mt-3">
                              <NavLink target="_blank" to="https://www.atmospherecore.com">
                                <img src="/images/icon/logo-atmosphere-core.svg" alt="Atamosphere Living" title="Atamosphere Living"/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <nav className="navbar navigation-menu fixed-on-top">
            <div className="container container-custom" id="header-container">
                <NavLink className="navbar-brand" to="/"><img id="logo" src="/images/icon/text-logo.svg" alt="Logo"/></NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar1" aria-controls="offcanvasNavbar1" aria-label="Toggle navigation">
            <div className="toggler-icon">
              <div className="bar1" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar2" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar3" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
            </div>
          </button>
                <div onClick={removeClassOnClick("offcanvasNavbar1", "show")} className="offcanvas offcanvas-end offcanvas-menu" tabindex="-1" id="offcanvasNavbar1" aria-labelledby="offcanvasNavbar1Label">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbar1Label"><img src="/images/icon/logo-icon.svg" alt="Logo Icon"/></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/OurManagement">Leadership</NavLink>
                            </li>
                            <li className="nav-item dropdown d-none">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About us
                </a>
                                <ul className="dropdown-menu">
                                    {/* <!-- <li><a className="dropdown-item" href="our-story.html">Our story</a></li> --> */}
                                    <li><NavLink className="dropdown-item" to="/OurBoard">Our Board</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/OurManagement">Our management</NavLink></li>
                                    {/* <!-- <li><a className="dropdown-item" href="our-team.html">Our team</a></li>
                    <li><a className="dropdown-item" href="partner-with-us.html">Our Partners</a></li> --> */}
                                </ul>
                            </li>
                            {/* <!--<li className="nav-item">
                <a className="nav-link" href="branded-residences.html">Branded residences</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="amenities.html">Amenities</a>
              </li>
               <li className="nav-item">
                <a className="nav-link" href="creating-lifestyle.html">Creating Lifestyle</a>
              </li> --> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/ResponsibleDevelopment">Responsible development</NavLink>
                            </li>
                            <li className="nav-item d-none">
                                <NavLink className="nav-link" to="/MediaGallery">Media Gallery</NavLink>
                            </li>
                            <li className="nav-item dropdown d-none">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Knowledge Centre
                </a>
                                <ul className="dropdown-menu">
                                    {/* <li><NavLink className="dropdown-item" to="/MediaGallery">Media/Gallery</NavLink></li> */}
                                    <li><NavLink className="dropdown-item" to="/SocialInfluence">Social Influence</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/Blog">News &amp; Blogs</NavLink></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                          <NavLink class="nav-link " to="/PartnerWithUs">Partner with us</NavLink>
                        </li>
                            <li className="nav-item">
                                <NavLink className="nav-link " to="/CustomerRelation" role="button">Rental pool</NavLink>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/CustomerRelation#ContactUsAtmosphereSection" role="button">Contact us</Link>
                            </li>
                        </ul>
                        <div className="visit-atamosphere text-white  d-none">
                            <span className="text-uppercase d-block w-100">Also Visit :</span>
                            <div className="visit-now p-3 d-inline-block mt-3">
                              <NavLink target="_blank" to="https://www.atmospherecore.com">
                                <img src="/images/icon/logo-atmosphere-core.svg" alt="Atamosphere Living" title="Atamosphere Living"/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
{/* <header>
        <div className="top-bar">
            <div className="container container-custom">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact-information">
                            <div className="contact-number">
                                <a href="tel:+918544674241"><img src="/images/icon/phone-icon.svg" alt="Phone Icon"/>8544674241</a>
                            </div>
                            <div className="contact-email">
                                <a href="mailto:info@atmosphereliving.com"><img src="/images/icon/plane-icon.svg" alt="Plane Icon"/>info@atmosphereliving.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nav className="navbar navigation-menu">
            <div className="container container-custom" id="header-container">
                <NavLink className="navbar-brand" to="/"><img id="logo" src="/images/icon/logo.svg" alt="Logo"/></NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <div className="toggler-icon">
              <div className="bar1" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar2" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar3" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
            </div>
          </button>
                <div onClick={removeClassOnClick("offcanvasNavbar", "show")} className="offcanvas offcanvas-end offcanvas-menu" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img src="/images/icon/logo-icon.svg" alt="Logo Icon"/></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">About us</a>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/OurBoard">Our Board</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/OurManagement">Our management</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/ResponsibleDevelopment">Responsible Development</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Knowledge Centre </a>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/MediaGallery">Media/Gallery</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/SocialInfluence">Social Influence</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/Blog">News &amp; Blogs</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/CustomerRelation" role="button">Contact Us</NavLink>
                            </li>
                        </ul>
                        <div className="visit-atamosphere text-white">
                            <span className="text-uppercase d-block w-100">Also Visit :</span>
                            <div className="visit-now p-3 d-inline-block mt-3">
                                <img src="/images/icon/visit-atamosphere.svg" alt="Atamosphere Living" title="Atamosphere Living"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header> */}

    {/* <header>
    <div className="top-bar">
      <div className="container container-custom">
        <div className="row">
          <div className="col-md-12">
            <div className="contact-information">
              <div className="contact-number">
                <a href="tel:+918544674241"><img src="/images/icon/phone-icon.svg" alt="Phone Icon"/>8544674241</a>
              </div>
              <div className="contact-email">
                <a href="mailto:info@atmosphereliving.com"><img src="/images/icon/plane-icon.svg"
                    alt="Plane Icon"/>info@atmosphereliving.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav className="navbar navigation-menu">
      <div className="container container-custom"id="header-container">
        <NavLink className="navbar-brand" to="/"><img id="logo" src="/images/icon/logo.svg" alt="Logo"/></NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <div className="toggler-icon">
              <div className="bar1" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar2" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
              <div className="bar3" style={{"backgroundColor": 'rgb(255, 255, 255)'}}></div>
            </div>
          </button>
        <div className="offcanvas offcanvas-end offcanvas-menu" tabindex="-1" id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img src="/images/icon/logo-icon.svg"
                alt="Logo Icon"/></h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  About us
                </a>
                <ul className="dropdown-menu">
                  <li><NavLink className="dropdown-item" to="/OurBoard">Our Board</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/OurManagement">Our management</NavLink></li>
                   <li><NavLink className="dropdown-item" to="/OurTeam">Our team</NavLink></li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/ResponsibleDevelopment">Responsible Development</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Knowledge Centre
                </a>
                <ul className="dropdown-menu">
                  <li><NavLink className="dropdown-item" to="/MediaGallery">Media/Gallery</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/SocialInfluence">Social Influence</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/Blog">Blog</NavLink></li>
                </ul>
              </li>
             
              <li className="nav-item">
                <NavLink className="nav-link" to="/CustomerRelation" role="button">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header> */}
    
    </>
  )
}

export default Header