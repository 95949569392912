'use client'
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlider1 from './SustainabilitySlider';

 import 'bootstrap/dist/css/bootstrap.min.css';
 import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { NavLink } from 'react-router-dom';
// import Image from 'next/image'
// import { useSpring, animated } from '@react-spring/web'
// import { useState,useEffect,useRef } from 'react';
// import Slider from "react-slick";
export default function Section1() {
    const sliderSettings = {
       // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      };
    
return (
<>
<section>
        <div className="sustainability position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="sustainability-text">
                            <div className="sustainability-heading">
                                <div className="small-heading">
                                    <hr/><span>INTUITIVE LIVING</span>
                                </div>
                                <div className="secondary-heading">
                                    <h2><span className="green-text"> Responsible </span>Development</h2>
                                </div>
                            </div>
                            <div className="sustainability-body">
                                <p className="mb-4">Mindful in our approach, we intuitively integrate sustainable practices into our operations for a harmonious and eco-conscious living environment.</p>
                                {/* <NavLink to="/MediaGallery" className="green-btn-wrap"><span className="btn theme-btn green-theme-btn">Learn more</span> <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span></NavLink> */}
                                <div class="intuitive-action mt-lg-5 mt-4 d-none">
                                    <NavLink to="/MediaGallery" class="green-btn-wrap d-inline-block" href="#"><span class="btn theme-btn green-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                                        <span class="ms-3"><img src="/images/icon/arrowgreen-right-icon.svg" alt="Next" title="Next"/></span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="sustainability-visual bg-image-none ps-xl-3 mt-lg-0 mt-5">
                            <img src="/images/visuals/sustainability.jpg" alt="Sustainability" title="Sustainability" />
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="sustainability-visual ps-xl-3 mt-lg-0 mt-5">
                            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators slider-dots d-flex align-items-center">
                                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                  </div>
                                <div className="carousel-inner">
                                  <div className="carousel-item active" data-bs-interval="2000">
                                    <img src="/images/visuals/sustainability.jpg" alt="Sustainability" title="Sustainability"/>
                                  </div>
                                  <div className="carousel-item" data-bs-interval="2000">
                                    <img src="/images/visuals/sustainability.jpg" alt="Sustainability" title="Sustainability"/>
                                  </div>
                                  <div className="carousel-item">
                                    <img src="/images/visuals/sustainability.jpg" alt="Sustainability" title="Sustainability"/>
                                  </div>
                                </div>
                              </div>
                            
                        </div>
                    </div> */}
                </div>
    
                <div className="responsible-card-slides position-relative">
                    <div className="responsible-cards d-flex">
                    <CustomSlider1 tabIndex={0} {...sliderSettings} />
                      
                        {/* <!-- /sustainability-card --> */}
                    </div>
                    {/* <!-- /responsible-cards --> */}
                </div>
                {/* <!-- /responsible-card-slides --> */}
            </div>
        </div>
    </section>
</>




)
    

}