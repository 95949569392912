'use client'
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlider1 from './Slider1';
import CustomSlider2 from './Slider2';
import CustomSlider3 from './Slider3';
import CustomSlider4 from './Slider4';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import Image from 'next/image'
// import { useSpring, animated } from '@react-spring/web'
// import { useState,useEffect,useRef } from 'react';
// import Slider from "react-slick";
export default function Section1() {
    // document.addEventListener('DOMContentLoaded', function () {
   // const toggleButton = document.getElementsByClassName('imgtoogle');

   useEffect(()=>{

   
    const img1 = document.getElementById('img1');
    const img2 = document.getElementById('img2');
    const img3 = document.getElementById('img3');
    const img4 = document.getElementById('img4');
    const img5 = document.getElementById('img5');

    const item1 = document.getElementById('item1');
    const item2 = document.getElementById('item2');
    const item3 = document.getElementById('item3');
    const item4 = document.getElementById('item4');
    const item5 = document.getElementById('item5');

    item1.addEventListener('click', () => {
        img1.style.display = 'block';
        img2.style.display = 'none';
        img3.style.display = 'none';
        img4.style.display = 'none';
        img5.style.display = 'none';
  
});
item2.addEventListener('click', () => {
    img2.style.display = 'none';
    img1.style.display = 'none';
    img3.style.display = 'none';
    img4.style.display = 'none';
    img5.style.display = 'block';

});
item3.addEventListener('click', () => {
    img3.style.display = 'none';
    img2.style.display = 'block';
    img1.style.display = 'none';
    img4.style.display = 'none';
    img5.style.display = 'none';

});

item4.addEventListener('click', () => {
    img4.style.display = 'none';
    img2.style.display = 'none';
    img3.style.display = 'block';
    img1.style.display = 'none';
    img5.style.display = 'none';

});
item5.addEventListener('click', () => {
    img5.style.display = 'none';
    img2.style.display = 'none';
    img3.style.display = 'none';
    img1.style.display = 'none';
    img4.style.display = 'block';

});
},[])
   // });
    // const sliderSettings = {
    //    // dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     swipeToSlide: true,
    //     responsive: [
    //       {
    //         breakpoint: 1024,
    //         settings: {
    //           slidesToShow: 3,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1,
    //         },
    //       },
    //     ],
    //   };
    
return (
<>

<section>
        <div className="intuitive-living">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 pe-md-4">
                        <div className="intuitive-luxury-text">
                            <div className="small-heading">
                                <hr/><span>INTUITIVE LIVING</span>
                            </div>
                            <div className="secondary-heading">
                                <h2>A well curated lifestyle for your <span className="yellow-text">everyday well-being</span></h2>
                                <p className="mt-lg-5 mt-3">Genuine and thoughtful in our pursuit to elevate everyday well-being, we have carefully curated every aspect of your lifestyle for an intuitive living experience.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="download-brochure-action mt-lg-5 mb-lg-0 mb-4 text-lg-end">
                            <a target='blank' href="/images/AtmosphereLivingBrochure.pdf" class="yellow-btn-wrap"><span class="btn theme-btn yellow-theme-btn rounded-0 text-uppercase text-white fw-semibold">Download Brochure <img src="/images/icon/download.svg" alt="Download" title="Download"/></span></a>
                            <a href=""></a>
                        </div>
                    </div>
                    
                </div>
                <div className="row mt-lg-4 mt-3 d-none d-md-flex">
                    <div className="col-md-8">
                        <div className="visual-lifestyle">
                        <img id="img1"  src="/images/visuals/lifestyle.jpg" alt="LifeStyle" title="LifeStyle"/>
                        <img id="img2" style={{"display":'none'}} src="/images/visuals/girlyoga.jpg" alt="LifeStyle" title="LifeStyle"/>
                        <img id="img3" style={{"display":'none'}} src="/images/visuals/girl-playing-with-daugter.jpg" alt="LifeStyle" title="LifeStyle"/>
                        <img id="img4" style={{"display":'none'}} src="/images/visuals/flowers-pluck.jpg" alt="LifeStyle" title="LifeStyle"/>
                        <img id="img5" style={{"display":'none'}} src="/images/visuals/waterplanting.jpg" alt="LifeStyle" title="LifeStyle"/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="intuitive-living-accordion ms-auto">
                            <div className="accordion" id="accordionExample">
                                <div id="item1" className="accordion-item item1">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Urban Green Spaces</button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Unwind in a lush space for outdoor meals, community events, barbecues, and more.</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="item2" className="accordion-item item2">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Holistic Retreats
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Embark on a transformative journey of self-discovery and personal growth.</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="item3" className="accordion-item item3">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Wellness Spaces
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Discover a world of well-being with facilities designed to invigorate your mind, body and soul.</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="item4" className="accordion-item item4">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            Forested Areas 
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Immerse in the beauty of nature and find tranquillity amidst the verdant greenery.</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="item5" className="accordion-item item5">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false"
                                            aria-controls="collapseFive">
                                            Community Garden
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Foster a sense of community and connection with nature while nurturing your green thumb.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-block d-md-none">
                    <div className="col-12">
                        <div className="intuitive-living-mobile-slider">
                            <div id="IntuitiveLivingSlider" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                  <div className="carousel-item active">
                                    <div className="lifestyle-slider-image">
                                        <img className="img-fluid w-100" src="/images/visuals/lifestyle.jpg" alt="LifeStyle" title="LifeStyle"/>
                                    </div>
                                    <div className="lifestyle-slider-text mt-4">
                                        <h2 className="mb-2">Urban Green Spaces</h2>
                                        <p>Unwind in a lush space for outdoor meals, community events, barbecues, and more.</p>
                                    </div> 
                                  </div>
                                  <div className="carousel-item">
                                    <div className="lifestyle-slider-image">
                                        <img className="img-fluid w-100" src="/images/visuals/waterplanting.jpg" alt="Water Planting" title="Water Planting"/>
                                    </div>
                                    <div className="lifestyle-slider-text mt-4">
                                        <h2 className="mb-2">Holistic Retreats</h2>
                                        <p>Embark on a transformative journey of self-discovery and personal growth.</p>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="lifestyle-slider-image">
                                        <img className="img-fluid w-100" src="/images/visuals/girlyoga.jpg" alt="Girl Yoga" title="Girl Yoga"/>
                                    </div>
                                    <div className="lifestyle-slider-text mt-4">
                                        <h2 className="mb-2">Wellness Spaces</h2>
                                        <p>Discover a world of well-being with facilities designed to invigorate your mind, body and soul.</p>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="lifestyle-slider-image">
                                        <img className="img-fluid w-100" src="/images/visuals/girl-playing-with-daugter.jpg" alt="Girl Yoga" title="Girl Yoga"/>
                                    </div>
                                    <div className="lifestyle-slider-text mt-4">
                                        <h2 className="mb-2">Forested Areas</h2>
                                        <p>Immerse in the beauty of nature and find tranquillity amidst the verdant greenery.</p>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="lifestyle-slider-image">
                                        <img className="img-fluid w-100" src="/images/visuals/flowers-pluck.jpg" alt="Flowers Pluck" title="Flowers Pluck"/>
                                    </div>
                                    <div className="lifestyle-slider-text mt-4">
                                        <h2 className="mb-2">Community Garden</h2>
                                        <p>Foster a sense of community and connection with nature while nurturing your green thumb. </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="slider-mobile-arrow position-absolute start-0 w-100 d-flex justify-content-between h-100">
                                    <button className="carousel-control-prev opacity-100 rounded-pill" type="button" data-bs-target="#IntuitiveLivingSlider" data-bs-slide="prev">
                                        <img src="/images/icon/prev-arrow.svg" alt="Previous" title="Previous"/>
                                      </button>
                                      <button className="carousel-control-next opacity-100 rounded-pill" type="button" data-bs-target="#IntuitiveLivingSlider" data-bs-slide="next">
                                          <img src="/images/icon/next-arrow.svg" alt="Previous" title="Previous"/>
                                      </button>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /row --> */}

            </div>
        </div>
    </section>


</>




)
    

}