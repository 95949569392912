import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import './assets/css/core.css'
import './assets/css/main.css'
import './assets/css/responsive.css'
import './assets/css/vendor/animate.min.css'
import { ToastContainer } from "react-toastify";
// import './assets/css/vendor/bootstrap.min.css'
import './assets/css/vendor/slick.css'
import './assets/js/main.js'
// import './assets/css/vendor/animate.min.css'
import Section1 from '../src/components/Section1';
import Section2 from '../src/components/Section2';
import IntiutiveLuxury from '../src/components/IntiutiveLuxury';
import IntiutiveTech from '../src/components/IntiutiveTech';
import Sustainability from '../src/components/Sustainability';
import IntiutiveLiving from '../src/components/IntiutiveLiving';
import WelcomeCurosity from '../src/components/WelcomeCurosity';
import {NavLink,Link} from "react-router-dom";
import AllRoutes from "../src/routes/AllRoutes"
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return ( 

    <>
    
   <AllRoutes />
    
   <ToastContainer 
   position="bottom-right"
   autoClose={5000}
   hideProgressBar={false}
   newestOnTop={false}
   closeOnClick
   rtl={false}
   pauseOnFocusLoss
   draggable
   pauseOnHover
   theme="light"/>
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
