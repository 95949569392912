'use client'
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlider1 from './Slider1';
import CustomSlider2 from './Slider2';
import CustomSlider3 from './Slider3';
import CustomSlider4 from './Slider4';
import { NavLink } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import Image from 'next/image'
// import { useSpring, animated } from '@react-spring/web'
// import { useState,useEffect,useRef } from 'react';
// import Slider from "react-slick";
export default function Section1() {
    // const sliderSettings = {
    //    // dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     swipeToSlide: true,
    //     responsive: [
    //       {
    //         breakpoint: 1024,
    //         settings: {
    //           slidesToShow: 3,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1,
    //         },
    //       },
    //     ],
    //   };
    
return (
<>
<section>
        <div className="intutive-luxury">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 order-md-0 order-2">
                        <div className="features-window d-flex flex-wrap align-items-start">
                            <div className="window-visual window-large-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                <span className="d-flex overflow-hidden"><img src="/images/visuals/intuitive-luxury-large-feature.jpg" alt=""/></span>
                            </div>
                            <div className="window-visual window-thumb-visual w-100 overflow-hidden position-absolute start-0 bottom-0 pe-3 pb-3">
                                <span className="d-flex overflow-hidden"><img src="/images/visuals/intuitive-luxury-thumb-feature.jpg" alt=""/></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-0 order-1">
                        <div className="intuitive-luxury-text">
                            <div className="small-heading">
                                <hr/><span>intuitive luxury</span>
                            </div>
                            <div className="secondary-heading">
                                <h2>Intuitive luxury in <span className="yellow-text">everyday lifestyle</span></h2>
                                <p className="mt-lg-5 mt-md-4 mt-3">Luxury is when the time is yours to pursue what’s meaningful. It is when you are heard before you speak, and your desires are exceeded. It resides in thoughtful hospitality that delights at every turn. It is an experience that is personal and elevated with an intuitive touch.</p>
                                <p className="mt-3">We are crafting an experience where intuitive luxury permeates your everyday lifestyle, and consistently ordinary transcends into extraordinary.</p>
                            </div>
                            <div className="inuitive-action mt-lg-5 mt-md-4 mt-3">
                                <NavLink to="/MediaGallery" className="yellow-btn-wrap d-none" ><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                                    <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-4 mt-5 align-items-center ">
                    <div className="col-md-6">
                        <div className="intuitive-luxury-text">
                            <div className="small-heading">
                                <hr/><span>INTUITIVE HOSPITALITY</span>
                            </div>
                            <div className="secondary-heading">
                                <h2>Rooted in the heritage of <span className="yellow-text">hospitality excellence</span></h2>
                                <p className="mt-lg-5 mt-md-4 mt-3">We didn’t pick our name; we inherited it. Atmosphere Core is a renowned hospitality company with a legacy of crafting extraordinary experiences through its exceptional properties.</p>
                                <p className="mt-3">Rooted in this heritage and with your desires at heart, we are weaving intuitive luxury into the exclusive lifestyle offered at Atmosphere Living.</p>
                            </div>
                            <div className="inuitive-action mt-lg-5 mt-md-4 mt-3">
                                <NavLink to="/MediaGallery" className="yellow-btn-wrap d-none" ><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                                    <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="features-window brand-heritage-window d-flex flex-wrap justify-content-end align-items-start">
                            <div className="window-visual window-large-visual ms-0 w-100 overflow-hidden position-relative pe-3 pb-3">
                                <span className="d-flex overflow-hidden"><img src="/images/visuals/brand-haritage-large.jpg" alt=""/></span>
                            </div>
                            <div className="window-visual window-thumb-visual w-100 overflow-hidden position-absolute end-0 bottom-0 pe-3 pb-3">
                                <span className="d-flex overflow-hidden"><img src="/images/visuals/brand-haritage-thumb.jpg" alt=""/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
{/* <section className="intutive-luxury">
        <div className="container">
            <div className="row responsive-col-reverse align-items-center">
                <div className="col-lg-6 col-md-6">
                    <div className="intuitive-luxury-image">
                        <img src="/images/visuals/intuitive-luxury.png" alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="intuitive-luxury-text">
                        <div className="small-heading">
                            <hr/><span>intuitive luxury</span>
                        </div>
                        <div className="secondary-heading">
                            <h2><span className="yellow-text">Intuitive luxury</span> in everyday lifestyle</h2>
                            <p className="mt-5">Luxury is when the time is yours to pursue what’s meaningful. It is when you are heard before you speak, and your desires are exceeded. It resides in thoughtful hospitality that delights at every turn. It is an experience that feels personal and elevated with an intuitive touch. </p>
                            <p className="mt-3">We are crafting an experience where intuitive luxury permeates your everyday lifestyle, and consistently ordinary transcends into extraordinary.</p>
                        </div>
                        <div className="intuitive-btn">
                            <NavLink to="/MediaGallery" className="btn theme-btn">learn more</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row section-space align-items-center">
                <div className="col-lg-6 col-md-6">
                    <div className="intuitive-luxury-text">
                        <div className="small-heading">
                            <hr/><span>brand heritage</span>
                        </div>
                        <div className="secondary-heading">
                            <h2><span className="yellow-text">Rooted</span> in the <span className="yellow-text">heritage</span> of hospitatlity excellence</h2>
                            <p className="mt-5">We didn’t choose our name; we inherited it. Atmosphere Hotels and Resorts has a long-standing tradition of providing exceptional hospitality experiences.</p>
                            <p className="mt-3">Rooted in this heritage and with your desires at heart, we are weaving intuitive luxury into the exclusive lifestyle offered at Atmosphere Living.</p>
                        </div>
                        <div className="intuitive-btn">
                            <NavLink to="/MediaGallery" className="btn theme-btn">learn more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="intuitive-luxury-image brand-heritage-image">
                        <img src="/images/visuals/brand-haritage.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section> */}


</>




)
    

}