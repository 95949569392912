import axios from "axios";

export const sendEnquireData = async (formData) => {
    try {
      const { data } = await axios.post(
        `/api/contact-enquiry`
      ,formData);
     console.log(data)
      return data;
    } catch (err) {
        console.log("err")
        console.log(err)
      throw Error(err);
    }
  };