import React from 'react'

const OurBoard = () => {
  return (
    <>
    
    {/* <!-- Banner Inner --> */}
    <section>
        <div className="banner-inner customerrelation-visual overflow-hidden">
            <div className="container container-custom h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-md-8">
                        <div className="banner-title text-center">
                            <h1>Our <span>Board</span></h1>
                            <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                                <div className="logo-circle">
                                    <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                                </div>
                            </div>
                            <div className="col-md-12 d-none">
                                <div className="mt-lg-5 mt-4">
                                    <p className="text-white m-0">Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Founder --> */}
    <section>
        <div className="founder overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="founder-info">
                            <div className="row">
                                <div className="col-lg-4 col-md-5">
                                    <div className="founder-wrap" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="founder-img">
                                            <img src="/images/visuals/founder1.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="founder-about text-center">
                                            <h3>Andrew Davies</h3>
                                            <span>Director</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-md-4 --> */}
                                <div className="col-lg-8 col-md-7">
                                    <div className="about-founder-text" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="founder-message">
                                            <h2>Director’s <span>message</span></h2>
                                        </div>
                                        <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <div className="founder-action mt-4">
                                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">read more</span>
                                                <span className="ms-4"><img src="/images/icon/vertically-arrow.svg" alt="Next" title="Next"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-md-8 --> */}
                            </div>
                            {/* <!-- /row --> */}
                        </div>
                        {/* <!-- /founder-info --> */}
                    </div>
                    {/* <!-- /col-12 --> */}

                    <div className="col-12">
                        <div className="founder-info">
                            <div className="row">
                                <div className="col-lg-8 col-md-7">
                                    <div className="about-founder-text text-end" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="founder-message">
                                            <h2>Co - <span>Founder</span></h2>
                                        </div>
                                        <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                                        <div className="founder-action mt-4">
                                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">read more</span>
                                                <span className="ms-4"><img src="/images/icon/vertically-arrow.svg" alt="Next" title="Next"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-md-8 --> */}
                                <div className="col-lg-4 col-md-5">
                                    <div className="founder-wrap reverse-img ms-lg-auto" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="founder-img">
                                            <img src="/images/visuals/founder2.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="founder-about text-center">
                                            <h3>Andrew	Davies</h3>
                                            <span>Co-Founder</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-md-4 --> */}
                            </div>
                            {/* <!-- /row --> */}
                        </div>
                        {/* <!-- /founder-info --> */}
                    </div>
                    {/* <!-- /col-12 --> */}

                    <div className="col-12">
                        <div className="founder-info">
                            <div className="row">
                                <div className="col-lg-4 col-md-5">
                                    <div className="founder-wrap" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="founder-img">
                                            <img src="/images/visuals/founder3.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="founder-about text-center">
                                            <h3>Cynthia Dalton</h3>
                                            <span>Co-founder</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-lg-4 --> */}
                                <div className="col-lg-8 col-md-7">
                                    <div className="about-founder-text" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="founder-message">
                                            <h2>Co - <span>Founder</span></h2>
                                        </div>
                                        <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <div className="founder-action mt-4">
                                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">read more</span>
                                                <span className="ms-4"><img src="/images/icon/vertically-arrow.svg" alt="Next" title="Next"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-lg-8 --> */}
                            </div>
                            {/* <!-- /row --> */}
                        </div>
                        {/* <!-- /founder-info --> */}
                    </div>
                    {/* <!-- /col-12 --> */}
                    
                </div>
                {/* <!-- /row --> */}
            </div>
            {/* <!-- /container --> */}
        </div>
        {/* <!-- /founder --> */}
    </section>

    {/* <!-- Management --> */}
    <section>
        <div className="management overflow-hidden">
            <div className="container container-custom">
                <div className="row align-items-center" data-aos="fade-down" data-aos-duration="1000">
                    <div className="col-lg-9 col-md-7">
                        <div className="heading-type-two text-capitalize">
                            <h2>Our <span>management</span></h2>
                        </div>
                    </div>
                    <div className="col-lg-3  col-md-5 text-end">
                        <div className="management-action">
                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                                <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="2500">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management1.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about text-center">
                                <h3>Abhijeet Sharma</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="2000">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management2.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about dark-green-bg text-center">
                                <h3>Rachel Hughes</h3>
                                <span>Sales Head</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="1500">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management3.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about text-center">
                                <h3>Emily Gray</h3>
                                <span>Human Resource</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="1000">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management4.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about dark-green-bg text-center">
                                <h3>Alison Grant</h3>
                                <span>Designation</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <!--Welcome Our Curiosity--> */}
    <section>
        <div  className="welcome-our-curiosity">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading text-center">
                            <h2>Our doors are wide open and we welcome your curiosity</h2>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <form action="#" method="get">
                            <div className="curiosity-form m-auto d-flex align-items-center">
                                <div className="form-input w-100">
                                    <input type="text" placeholder="Your name" name="" id="" className="form-control border-0 rounded-0" />
                                </div>
                                <div className="form-input w-100">
                                    <input type="email" placeholder="Your email" name="" id="" className="form-control border-0 rounded-0" />
                                </div>
                                <div className="form-checkbox d-flex d-md-none justify-content-center position-relative">
                                    <label for="TermCondition" className="d-flex align-items-center">
                                        <input type="checkbox" name="" id="TermCondition"/>
                                        <div className="check-term"></div>
                                        <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                    </label>
                                </div>
                                <div className="form-submit">
                                    <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" />
                                </div>
                            </div>
                            <div className="form-checkbox d-none d-md-flex mt-lg-5 mt-4 justify-content-center position-relative">
                                <label for="TermCondition1" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermCondition1"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section className="banner-inner blog-visual position-relative">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="banner-title text-center">
                    <h1>Our <span>Board</span></h1>
                        <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                            <div className="logo-circle">
                                <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                            </div>
                        </div>
                        <div className="short-intro-text mt-xl-5 mt-4">
                        <p className='text-white'>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="founder">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="founder-media-object">
                        <div className="row justify-content-around">
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="founder-media-object-visual">
                                    <img src="/images/visuals/founder1.jpg" alt="" className="img-fluid"/>
                                    <div className="founder-about">
                                        <h3>Andrew Davies</h3>
                                        <span>Director</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="fade-left" data-aos-duration="1000">
                                <div className="founder-media-object-body">
                                    <div className="heading-type-two">
                                        <h2>Director’s <span>message</span></h2>
                                    </div>
                                    <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...</p>
                                    <div className="founder-action mt-4">
                                        <a href="" className="btn btn-atmosphere btn-yellow">Read more</a>
                                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-around flex-row-reverse">
                            <div className="col-md-3" data-aos="fade-left" data-aos-duration="1000">
                                <div className="founder-media-object-visual">
                                    <img src="/images/visuals/founder2.jpg" alt="" className="img-fluid"/>
                                    <div className="founder-about">
                                        <h3>Andrew Davies</h3>
                                        <span>Co-Founder</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="fade-right" data-aos-duration="1000">
                                <div className="founder-media-object-body">
                                    <div className="heading-type-two">
                                        <h2>Co - <span>Founder</span></h2>
                                    </div>
                                    <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...</p>
                                    <div className="founder-action mt-4">
                                        <a href="" className="btn btn-atmosphere btn-yellow">Read more</a>
                                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-around">
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="founder-media-object-visual">
                                    <img src="/images/visuals/founder3.jpg" alt="" className="img-fluid"/>
                                    <div className="founder-about">
                                        <h3>Cynthia Dalton</h3>
                                        <span>Co-Founder</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="fade-left" data-aos-duration="1000">
                                <div className="founder-media-object-body">
                                    <div className="heading-type-two">
                                        <h2>Co - <span>Founder</span></h2>
                                    </div>
                                    <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...</p>
                                    <div className="founder-action mt-4">
                                        <a href="" className="btn btn-atmosphere btn-yellow">Read more</a>
                                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="management">
        <div className="container container-custom">
            <div className="row align-items-center" data-aos="fade-down" data-aos-duration="1000">
                <div className="col-md-9">
                    <div className="heading-type-two">
                        <h2>Our <span>management</span></h2>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-action mt-4">
                        <a href="our-management.html" className="btn btn-atmosphere btn-yellow">Know more</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="2500">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management1.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="2000">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management2.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Rachel Hughes</h4>
                            <span>Sales Head</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="1500">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management3.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Emily Gray</h4>
                            <span>Human Resource</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management4.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Alison Grant</h4>
                            <span>Designation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="contact-form-inner">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-form mt-0">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="contact-form-heading text-center">
                                    <h2>Our doors are wide open and we welcome your curiosity</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="text" className="form-control" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="email" className="form-control" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-5">
                                        <div className="contact-form-radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                                <label className="form-check-label" for="terms">
                          I accept <b>Terms &amp; Conditions</b> for procesing personal data
                        </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-form-input">
                                    <button type="submit" className="btn btn-light w-100">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>
  )
}

export default OurBoard