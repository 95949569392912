import React, { useState } from 'react'
import { sendEnquireData } from '../dataService/DataService';
//import nodemailer from "nodemailer"
import { toast } from "react-toastify";
import { Form } from 'react-router-dom';
import { useMutation } from 'react-query';

const CustomerRelation = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const newErrors = { ...errors };

        if (name === "name" && !value) {
            newErrors.name = "Please enter full name";
        } else if (name === "name") {
            delete newErrors.name; // Clear the error if the field is valid
        }

        if (name === "email" && !value) {
            newErrors.email = "Please enter email";
        } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = "Please enter valid email";
        } else if (name === "email") {
            delete newErrors.email;
        }

        if (name === "phone" && !value) {
            newErrors.phone = "Please enter phone no.";
        } else if (name === "phone" && !/^\d{10}$/.test(value)) {
            newErrors.phone = "Please enter valid phone no.";
        } else if (name === "phone") {
            delete newErrors.phone;
        }

        if (name === "message" && !value) {
            newErrors.message = "Please enter message";
        } else if (name === "message") {
            delete newErrors.message;
        }

        // Set the updated errors object
        setErrors(newErrors);

    };
    function sendEmail() {
        console.log("button blicked ")
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Please enter full name";
        }
        if (!formData.email) {
            newErrors.email = "Please enter email";
        }
        if (!formData.phone) {
            newErrors.phone = "Please enter phone no.";
        }
        if (!formData.message) {
            newErrors.message = "Please enter message";
        }
        if (Object.keys(newErrors).length === 0) {
            sendEnquireDat.mutate(formData);
            setErrors(newErrors);
        } else {
            setErrors(newErrors);

        }
    }

    const sendEnquireDat = useMutation(sendEnquireData, {
        onError: (error) => {
            toast.error(error.message);

        },
        onSuccess: (data) => {

            toast.success("Enquiry submitted. We will contact you soon.");
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: '',
            });
            // console.log("Succesful",{data});
        },
    });

    return (
        <>

            <section className="banner-inner customerrelation-visual">
                <div className="container container-custom h-100">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-8">
                            <div className="banner-title text-center">
                                <h1><span>Rental</span> Pool</h1>
                                <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                                    <div className="logo-circle">
                                        <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Customer Relation --> */}
            <section className="customer-relation">

                <div className="customer-relation-panel">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-lg-5 order-2 order-md-0">
                                <div className="customer-relation-description">
                                    <div className="heading-type-two">
                                        <div className="small-heading">
                                            <hr/>
                                            <span>Rental Pool</span>
                                        </div>
                                        <h2>For homebuyers</h2>
                                    </div>
                                    <div className="customer-relation-point">
                                        <p>The benefits of an Atmosphere Living branded residence go beyond just property ownership. We handle all operations and management, including the rental pool while you enjoy the benefits of owning a second home. Your property appreciates annually, stays in pristine condition, and can be easily sold off through our network of investors. Our second home and resort locations also come with lucrative rent-back agreements. </p>
                                        <div className="privilege-accordion p-0">
                                            <div className="accordion" id="accordion-privilege">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <span>01</span>Vacation Home
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordion-privilege">
                                                        <div className="accordion-body">
                                                            <p>A second home where you can live the holiday life as you please.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            <span>02</span>Rental Earnings
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion-privilege">
                                                        <div className="accordion-body">
                                                            <p>Generate rental income from your property during your non-occupancy.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <span>03</span>Thoughtful Amenities
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordion-privilege">
                                                        <div className="accordion-body">
                                                            <p>Enjoy concierge services and privileges tailored to your needs.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 order-1 order-md-0">
                                <div className="customer-relation-visual d-flex justify-content-end">
                                    <img src="/images/visuals/custmer-relation2.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Tie Up --> */}
            <section id='ContactUsAtmosphereSection' className="contactus-form tie-up">
                <div className="container container-custom">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row align-items-center">
                                <div className="col-xl-5 col-md-6">
                                    <div className="features-window d-flex align-items-start">
                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                            <span className="d-flex overflow-hidden"><img src="/images/visuals/guy-talking-on-phone.jpg" alt="" /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-md-6">
                                    <div className="contactus-form-description">
                                        <div className="heading-type-two">
                                            <div className="small-heading">
                                                <span>Contact us</span>
                                            </div>
                                            <h2>Get in <span>touch</span> with us</h2>
                                        </div>
                                        <div className="contact-des mt-lg-4 mb-4 pt-2 pb-xl-2">
                                            <p>Your branded residence is more than a property; it's an investment in yourself.</p>
                                        </div>
                                        <div className="form">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-4">
                                                        <input type="text" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Name" name="name" value={formData.name} onChange={handleChange} required />
                                                    </div>
                                                    <p className="form-error text-danger">
                                                        {errors.name ? errors.name : ""}
                                                    </p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-4">
                                                        <input type="text" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} required />
                                                    </div>
                                                    <p className="form-error text-danger">
                                                        {errors.phone ? errors.phone : ""}
                                                    </p>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <input type="email" className="form-control atamosphere-input rounded-0 bg-transparent" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required />
                                                    </div>
                                                    <p className="form-error text-danger">
                                                        {errors.email ? errors.email : ""}
                                                    </p>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <textarea className="form-control atamosphere-input rounded-0 bg-transparent" rows="5" placeholder="Message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                                                    </div>
                                                    <p className="form-error text-danger">
                                                        {errors.message ? errors.message : ""}
                                                    </p>
                                                </div>
                                                {/* <div className="col-md-12">
                                            <div className="contactus-form-radio mt-2 mb-xl-4 mb-2">
                                                <div className="form-checkbox d-flex position-relative">
                                                    <label for="TermCondition" className="d-flex align-items-center">
                                                        <input type="checkbox" name="" id="TermCondition"/>
                                                        <div className="check-term"></div>
                                                        <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> */}
                                                <div className="col-md-12">
                                                    <div className="contactus-form-input mt-3">
                                                        {sendEnquireDat.isLoading ? (<button className="btn btn-yellow w-100 rounded-0 fw-medium text-uppercase" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                            <span role="status">Loading...</span>
                                                        </button>) : (<button type="submit" onClick={sendEmail} className="btn btn-yellow w-100 rounded-0 fw-medium text-uppercase">Submit</button>)}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Contact-type--> */}
            <section className="contact-type">
                <div className="container container-custom">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-type-block">

                                <ul className="list-unstyled m-0 d-flex align-items-center">
                                    <li>
                                        <div className="contact-card">
                                            <div className="contact-card-icon">
                                                <img src="/images/icon/office-icon.svg" alt="Office" />
                                            </div>
                                            <div className="contact-card-body">
                                                <h4>Office</h4>
                                                <p>Unit No.114,1st Floor, Inspire BKC,G Block, BKC Main Road, Bandra Kurla Complex, Mumbai, Maharashtra 400051.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-card">
                                            <div className="contact-card-icon">
                                                <img src="/images/icon/phone-green-icon.svg" alt="Phone" />
                                            </div>
                                            <div className="contact-card-body">
                                                <h4>Phone</h4>
                                                <p><a href="tel:919876543211">+91 9876543211</a></p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-card">
                                            <div className="contact-card-icon">
                                                <img src="/images/icon/email-icon.svg" alt="Email" />
                                            </div>
                                            <div className="contact-card-body">
                                                <h4>Email</h4>
                                                <p><a href="mailto:info@theatmosphereliving.com">info@theatmosphereliving.com</a></p>
                                            </div>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className="contact-card">
                                            <div className="contact-card-body">
                                                <h4>Follow us</h4>
                                                <div className="social-media mt-lg-4">
                                                    <a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                                    <a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                                    <a href=""><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                                    <a href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Map --> */}
            <section className="map">
                <div className="container container-custom">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-type-two text-center">
                                <h2>Locate us <span>on map</span></h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="map-url">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7542.078853235297!2d72.85386759516706!3d19.062004069113065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e6d45289db%3A0xacb6e48fcf15a4e4!2sInspire%20BKC!5e0!3m2!1sen!2sin!4v1726640984354!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15084.033389799633!2d72.8577633!3d19.0633706!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e6d45289db%3A0xacb6e48fcf15a4e4!2sInspire%20BKC!5e0!3m2!1sen!2sin!4v1694763503202!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="banner-inner customerrelation-visual">
        <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
                <div className="col-md-8">
                    <div className="banner-title text-center">
                        <h1>Customer <span>Relations</span></h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section className="customer-relation">

        <div className="customer-relation-panel h-100">
            <div className="container container-custom h-100">
                <div className="row align-items-center h-100">
                    <div className="col-md-6">
                        <div className="customer-relation-description">
                            <div className="heading-type-two">
                                <div className="small-heading">
                                    <span>CUSTOMER RELATIONS</span>
                                </div>
                                <h2>Customer <span>Relations</span></h2>
                            </div>
                            <div className="customer-relation-point">
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis, nisi nobis veritatis accusantium reiciendis non expedita error aliquam amet aliquid commodi harum impedit voluptatum! Necessitatibus magni consequuntur
                                    nostrum sint nemo.</p>
                                <div className="privilege-accordion p-0">
                                    <div className="accordion" id="accordion-privilege">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <span>01</span>Vacation Home
                        </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordion-privilege">
                                                <div className="accordion-body">
                                                    <p>This is a unique opportunity for homeowners to profit from their property during periods of non-occupancy.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <span>02</span>Rental Earnings
                        </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion-privilege">
                                                <div className="accordion-body">
                                                    <p>This is a unique opportunity for homeowners to profit from their property during periods of non-occupancy.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <span>03</span>Thoughtful Amenities
                        </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordion-privilege">
                                                <div className="accordion-body">
                                                    <p>This is a unique opportunity for homeowners to profit from their property during periods of non-occupancy.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="customer-relation-visual">
                            <img src="/images/visuals/custmer-relation2.jpg" alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section className="contactus-form tie-up">
        <div className="container container-custom">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="row flex-row-reverse align-items-center">
                        <div className="col-md-6">
                            <div className="contactus-form-description">
                                <div className="heading-type-two">
                                    <h2>Get in <span>touch</span> with us</h2>
                                </div>
                                <p>If you have any queries, suggestions, or requests relating to Atmosphere Living & experiences, please use the form below to let us know. We shall get back to you at the earliest possible.</p>
                                <div className="form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <input type="text" className="form-control form-input" placeholder="Name"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <input type="text" className="form-control form-input" placeholder="Phone"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <input type="email" className="form-control form-input" placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <textarea className="form-control" rows="4" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="contactus-form-radio">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                                    <label className="form-check-label" for="terms">
                            I accept <b>Terms &amp; Conditions</b> for procesing personal data
                          </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="contactus-form-input mt-3">
                                                <button type="submit" className="btn btn-yellow py-3 w-100 rounded-0">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="atmosphere-window-box">
                                <div className="atmosphere-window">
                                    <img src="/images/visuals/couple-talking.jpg" alt=""/>
                                </div>
                                <div className="atmosphere-window-border"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

   
    <section className="contact-type">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-type-block">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="contact-type-card">
                                    <div className="contact-type-icon">
                                        <img src="/images/icon/office-icon.svg" alt="Office"/>
                                    </div>
                                    <div className="contact-type-body">
                                        <h4>Office</h4>
                                        <p>#123, First Floor, Mumbai, Maharashtra</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-type-card">
                                    <div className="contact-type-icon">
                                        <img src="/images/icon/phone-green-icon.svg" alt="Phone"/>
                                    </div>
                                    <div className="contact-type-body">
                                        <h4 className="text-green">Phone</h4>
                                        <p><a href="">+91 9876543211</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-type-card">
                                    <div className="contact-type-icon">
                                        <img src="/images/icon/email-icon.svg" alt="Email"/>
                                    </div>
                                    <div className="contact-type-body">
                                        <h4>Email</h4>
                                        <p><a href="">info@atmosphereliving.in</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="social-media">
                                    <h4>Follow us</h4>
                                    <a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    <a href=""><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                    <a href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section className="map">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading-type-two text-center">
                        <h2>Locate us <span>on map</span></h2>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="map-url">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54889.006308006006!2d76.82105155469645!3d30.702572248274095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f936ed6a2b757%3A0x898668d7061b40f0!2sPanchkula%2C%20Haryana!5e0!3m2!1sen!2sin!4v1685593163340!5m2!1sen!2sin"
                            width="100%" height="500" style={{"border":0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

        </>
    )
}

export default CustomerRelation