import React from 'react'

const MediaGallery = () => {
  return (
    <>
    
    {/* <!-- Banner Inner --> */}
    <section className="banner-inner customerrelation-visual">
        <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
                <div className="col-md-8">
                    <div className="banner-title text-center">
                        <h1>Media <span>Gallery</span></h1>
                        <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                            <div className="logo-circle">
                                <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- World Experience --> */}
    <section>
        <div className="world-experience mt-lg-5 mt-4">
            <div className="container container-custom">
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="heading-type-two">
                            <h2>World class <span>experience</span></h2>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="download-brochure-action mb-lg-0 mb-4 text-lg-end">
                            <a target="blank" href="/images/AtmosphereLivingBrochure.pdf" className="yellow-btn-wrap"><span className="btn theme-btn yellow-theme-btn rounded-0 text-uppercase text-white fw-semibold">Download Brochure <img src="/images/icon/download.svg" alt="Download" title="Download"/></span></a>
                            <a href=""></a>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12">
                        <div className="world-experience-tab">
                            <ul className="nav nav-pills mb-3 d-flex flex-md-nowrap justify-content-between align-items-center" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-feature1-tab" data-bs-toggle="pill" data-bs-target="#pills-feature1" type="button" role="tab" aria-controls="pills-feature1" aria-selected="true">
                    <img src="/images/icon/service.svg" alt=""/>
                    <span>EXCLUSIVE SERVICESs</span>
                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-feature2-tab" data-bs-toggle="pill" data-bs-target="#pills-feature2" type="button" role="tab" aria-controls="pills-feature2" aria-selected="false">
                    <img src="/images/icon/service-3.svg" alt=""/>
                    <span>Concierge Services</span>
                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-feature3-tab" data-bs-toggle="pill" data-bs-target="#pills-feature3" type="button" role="tab" aria-controls="pills-feature3" aria-selected="false">
                    <img src="/images/icon/pool.svg" alt=""/>
                    <span>Rental Pool</span>
                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-feature4-tab" data-bs-toggle="pill" data-bs-target="#pills-feature4" type="button" role="tab" aria-controls="pills-feature4" aria-selected="false">
                    <img src="/images/icon/thumbs-up.svg" alt=""/>
                    <span>Exclusive Benefits</span>
                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-feature5-tab" data-bs-toggle="pill" data-bs-target="#pills-feature5" type="button" role="tab" aria-controls="pills-feature5" aria-selected="false">
                    <img src="/images/icon/community.svg" alt=""/>
                    <span>Prestigious Community</span>
                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="world-experience-tapcontent mt-4 pt-2">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-feature1" role="tabpanel" aria-labelledby="pills-feature1-tab" tabIndex="0">
                                    <div className="row gx-1">
                                        <div className="col-md-6">
                                            <div className="row gx-1">
                                                <div className="col-md-12">
                                                    <img src="/images/visuals/media1.jpg" alt="" className="img-fluid"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <img src="/images/visuals/media2.jpg" alt="" className="img-fluid"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <img src="/images/visuals/media3.jpg" alt="" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row gx-1">
                                                <div className="col-md-6">
                                                    <img src="/images/visuals/media4.jpg" alt="" className="img-fluid"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <img src="/images/visuals/media5.jpg" alt="" className="img-fluid"/>
                                                </div>
                                                <div className="col-md-12">
                                                    <img src="/images/visuals/media6.jpg" alt="" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-feature2" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">2</div>
                                <div className="tab-pane fade" id="pills-feature3" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">3</div>
                                <div className="tab-pane fade" id="pills-feature4" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">4</div>
                                <div className="tab-pane fade" id="pills-feature5" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">5</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="property investment pb-0">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading-type-two">
                        <h2>Also <span>see</span></h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="property-block">
                        <div className="row gx-1">
                            <div className="col-md-4 property-column">
                                <div className="property-card">
                                    <div className="property-card-visual overflow-hidden">
                                        <img src="/images/visuals/property1.jpg" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="property-card-body px-3">
                                        <span className="text-white d-block text-center">Press Releases</span>
                                        <p>Lorem Ipsum is simply text of the printing and typesetting industry.</p>
                                        <div className="property-action d-none">
                                            <a href="">VIEW</a>
                                            <img src="" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 property-column">
                                <div className="property-card">
                                    <div className="property-card-visual overflow-hidden">
                                        <img src="/images/visuals/property2.jpg" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="property-card-body px-3">
                                        <span className="text-white d-block text-center">Awards</span>
                                        <p>Lorem Ipsum is simply text of the printing and typesetting industry.</p>
                                        <div className="property-action d-none">
                                            <a href="">VIEW</a>
                                            <img src="" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 property-column">
                                <div className="property-card">
                                    <div className="property-card-visual overflow-hidden">
                                        <img src="/images/visuals/property3.jpg" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="property-card-body px-3">
                                        <span className="text-white d-block text-center">Media Kit</span>
                                        <p>Lorem Ipsum is simply text of the printing and typesetting industry.</p>
                                        <div className="property-action d-none">
                                            <a href="">VIEW</a>
                                            <img src="" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div className="media-gallery-slides">
            <div className="media-gallery-wrap">
                <ul className="list-unstyled m-0 p-0 d-flex align-items-end">
                    <li>
                        <div className="gallery-img smooth overflow-hidden">
                            <span>
                                <img src="/images/visuals/gallery-image3.jpg" alt=""/>
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="gallery-img smooth overflow-hidden">
                            <span>
                                <img src="/images/visuals/gallery-image2.jpg" alt=""/>
                            </span>
                        </div>
                    </li>
                    <li className="current-slide">
                        <div className="gallery-img smooth overflow-hidden">
                            <span>
                                <img src="/images/visuals/gallery-image1.jpg" alt=""/>
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="gallery-img smooth overflow-hidden">
                            <span>
                                <img src="/images/visuals/gallery-image2.jpg" alt=""/>
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="gallery-img smooth overflow-hidden">
                            <span>
                                <img src="/images/visuals/gallery-image3.jpg" alt=""/>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <section className="welcome-our-curiosity">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <form action="#" method="get">
                        <div className="curiosity-form m-auto d-flex align-items-center">
                            <div className="form-input w-100">
                                <input type="text" placeholder="Your name" name="" id="" className="form-control border-0 rounded-0" />
                            </div>
                            <div className="form-input w-100">
                                <input type="email" placeholder="Your email" name="" id="" className="form-control border-0 rounded-0" />
                            </div>
                            <div className="form-checkbox d-flex d-md-none justify-content-center position-relative">
                                <label for="TermCondition" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermCondition"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                            <div className="form-submit">
                                <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" />
                            </div>
                        </div>
                        <div className="form-checkbox d-none d-md-flex mt-lg-5 mt-4 justify-content-center position-relative">
                            <label for="TermCondition1" className="d-flex align-items-center">
                                <input type="checkbox" name="" id="TermCondition1"/>
                                <div className="check-term"></div>
                                <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>


    {/* <section className="banner-inner sustainability-visual">
        <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
                <div className="col-md-8">
                    <div className="banner-title text-center">
                        <h1>Media/Gallery</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="world-experience">
        <div className="container container-custom">
            <div className="row align-items-center">
                <div className="col-md-9">
                    <div className="heading-type-two">
                        <h2>World class <span>experience</span></h2>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-action mt-4">
                        <a href="" className="btn btn-atmosphere btn-yellow">Download Media Kit</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="world-experience-tab">
                        <ul className="nav nav-pills mb-3 d-flex justify-content-between align-items-center" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-feature1-tab" data-bs-toggle="pill" data-bs-target="#pills-feature1" type="button" role="tab" aria-controls="pills-feature1" aria-selected="true">
                  <img src="/images/icon/service.svg" alt=""/>
                  <span>Branded Service Residences</span>
                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-feature2-tab" data-bs-toggle="pill" data-bs-target="#pills-feature2" type="button" role="tab" aria-controls="pills-feature2" aria-selected="false">
                  <img src="/images/icon/service-3.svg" alt=""/>
                  <span>Concierge Services</span>
                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-feature3-tab" data-bs-toggle="pill" data-bs-target="#pills-feature3" type="button" role="tab" aria-controls="pills-feature3" aria-selected="false">
                  <img src="/images/icon/pool.svg" alt=""/>
                  <span>Rental Pool</span>
                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-feature4-tab" data-bs-toggle="pill" data-bs-target="#pills-feature4" type="button" role="tab" aria-controls="pills-feature4" aria-selected="false">
                  <img src="/images/icon/thumbs-up.svg" alt=""/>
                  <span>Exclusive Benefits</span>
                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-feature5-tab" data-bs-toggle="pill" data-bs-target="#pills-feature5" type="button" role="tab" aria-controls="pills-feature5" aria-selected="false">
                  <img src="/images/icon/community.svg" alt=""/>
                  <span>Prestigious Community</span>
                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="world-experience-tapcontent mt-4">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-feature1" role="tabpanel" aria-labelledby="pills-feature1-tab" tabIndex="0">
                                <div className="row gx-1">
                                    <div className="col-md-6">
                                        <div className="row gx-1">
                                            <div className="col-md-12">
                                                <img src="/images/visuals/media1.jpg" alt="" className="img-fluid"/>
                                            </div>
                                            <div className="col-md-6">
                                                <img src="/images/visuals/media2.jpg" alt="" className="img-fluid"/>
                                            </div>
                                            <div className="col-md-6">
                                                <img src="/images/visuals/media3.jpg" alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row gx-1">
                                            <div className="col-md-6">
                                                <img src="/images/visuals/media4.jpg" alt="" className="img-fluid"/>
                                            </div>
                                            <div className="col-md-6">
                                                <img src="/images/visuals/media5.jpg" alt="" className="img-fluid"/>
                                            </div>
                                            <div className="col-md-12">
                                                <img src="/images/visuals/media6.jpg" alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-feature2" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">2</div>
                            <div className="tab-pane fade" id="pills-feature3" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">3</div>
                            <div className="tab-pane fade" id="pills-feature4" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">4</div>
                            <div className="tab-pane fade" id="pills-feature5" role="tabpanel" aria-labelledby="pills-feature2-tab" tabIndex="0">5</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="property investment">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading-type-two">
                        <h2>Also <span>see</span></h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="property-block">
                        <div className="row gx-1">
                            <div className="col-md-4 property-column">
                                <div className="property-card">
                                    <div className="property-card-visual">
                                        <img src="/images/visuals/apartments.jpg" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="property-card-body px-3">
                                        <span>Press Releases</span>
                                        <p>Lorem Ipsum is simply text of the printing and typesetting industry.</p>
                                        <div className="property-action d-none">
                                            <a href="">VIEW</a>
                                            <img src="" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 property-column">
                                <div className="property-card">
                                    <div className="property-card-visual">
                                        <img src="/images/visuals/Villas1.jpg" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="property-card-body px-3">
                                        <span>Awards</span>
                                        <p>Lorem Ipsum is simply text of the printing and typesetting industry.</p>
                                        <div className="property-action d-none">
                                            <a href="">VIEW</a>
                                            <img src="" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 property-column">
                                <div className="property-card">
                                    <div className="property-card-visual">
                                        <img src="/images/visuals/hotel.jpg" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="property-card-body px-3">
                                        <span>Media Kit</span>
                                        <p>Lorem Ipsum is simply text of the printing and typesetting industry.</p>
                                        <div className="property-action d-none">
                                            <a href="">VIEW</a>
                                            <img src="" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="py-5 my-5">
        <div className="slick">
            <div><span><img src="/images/visuals/gallery-image1.jpg" alt=""/></span></div>
            <div><span><img src="/images/visuals/gallery-image2.jpg" alt=""/></span></div>
            <div><span><img src="/images/visuals/gallery-image3.jpg" alt=""/></span></div>
            <div><span><img src="/images/visuals/gallery-image1.jpg" alt=""/></span></div>
            <div><span><img src="/images/visuals/gallery-image2.jpg" alt=""/></span></div>
            <div><span><img src="/images/visuals/gallery-image3.jpg" alt=""/></span></div>
        </div>
    </section>

    <section className="contact-form">
        <div className="container container-custom">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="contact-form-heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-md-7">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="contact-form-input">
                                <input type="text" className="form-control" placeholder="Your Name"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-form-input">
                                <input type="email" className="form-control" placeholder="Your Email"/>
                            </div>
                        </div>
                        <div className="col-md-8 mt-5">
                            <div className="contact-form-radio">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                    <label className="form-check-label" for="terms">
                    I accept <b>Terms &amp; Conditions</b> for procesing personal data
                  </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contact-form-input">
                        <button type="submit" className="btn btn-light w-100">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>
  )
}

export default MediaGallery