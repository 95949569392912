import React from 'react'

const SocialInfluence = () => {
  return (
   <>
     {/* <!-- Banner Inner --> */}
    <section>
        <div className="banner-inner customerrelation-visual overflow-hidden">
            <div className="container container-custom h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-md-8">
                        <div className="banner-title text-center">
                            <h1>Social <span>Influence</span></h1>
                            <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                                <div className="logo-circle">
                                    <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Find Us --> */}
    <section>
        <div className="find-us">
            <div className="container">
                    <div className="row align-items-center mb-lg-5 mb-4">
                        <div className="col-md-12">
                            <div className="heading-type-two">
                                <h2>Where to <span>find us</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="social-links list-unstyled m-0 p-0 d-flex">
                                <li>
                                    <a href="#" className="text-decoration-none smooth d-flex align-items-center">
                                        <div className="social-media-icon d-flex align-items-center justify-content-center rounded-pill smooth">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-username">
                                            <h5>Facebook</h5>
                                            <span>&#64;atmosphereliving</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-decoration-none smooth d-flex align-items-center">
                                        <div className="social-media-icon d-flex align-items-center justify-content-center rounded-pill smooth">
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-username">
                                            <h5>Twitter</h5>
                                            <span>&#64;atmosphereliving</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-decoration-none smooth d-flex align-items-center">
                                        <div className="social-media-icon d-flex align-items-center justify-content-center rounded-pill smooth">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-username">
                                            <h5>Instagram</h5>
                                            <span>&#64;atmosphereliving</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-decoration-none smooth d-flex align-items-center">
                                        <div className="social-media-icon d-flex align-items-center justify-content-center rounded-pill smooth">
                                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-username">
                                            <h5>Youtube</h5>
                                            <span>&#64;atmosphereliving</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    </section>

    {/* <!-- Latest On Facebook --> */}
    <section>
        <div className="facebook-posts py-4">
            <div className="container container-custom">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="heading-type-two social-box">
                            <div className="social-icon facebook">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </div>
                            <h2>Latest on <span>facebook</span></h2>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="inuitive-action text-md-end mt-md-0 mt-4">
                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">View All</span>
                                <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-xl-5 pt-4">
                        <img src="/images/visuals/facebook1.png" alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Latest On Instagram --> */}
    <section>
        <div className="instagram-posts py-4">
            <div className="container container-custom">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="heading-type-two social-box">
                            <div className="social-icon instagram">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </div>
                            <h2>Latest on <span>instagram</span></h2>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="inuitive-action text-md-end mt-md-0 mt-4">
                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">View All</span>
                                <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-5">
                        <img src="/images/visuals/instagram1.png" alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!--Welcome Our Curiosity--> */}
    <section>
        <div  className="welcome-our-curiosity">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading text-center">
                            <h2>Our doors are wide open and we welcome your curiosity</h2>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <form action="#" method="get">
                            <div className="curiosity-form m-auto d-flex align-items-center">
                                <div className="form-input w-100">
                                    <input type="text" placeholder="Your name" name="" id="" className="form-control border-0 rounded-0" />
                                </div>
                                <div className="form-input w-100">
                                    <input type="email" placeholder="Your email" name="" id="" className="form-control border-0 rounded-0" />
                                </div>
                                <div className="form-checkbox d-flex d-md-none justify-content-center position-relative">
                                    <label for="TermCondition" className="d-flex align-items-center">
                                        <input type="checkbox" name="" id="TermCondition"/>
                                        <div className="check-term"></div>
                                        <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                    </label>
                                </div>
                                <div className="form-submit">
                                    <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" />
                                </div>
                            </div>
                            <div className="form-checkbox d-none d-md-flex mt-lg-5 mt-4 justify-content-center position-relative">
                                <label for="TermCondition1" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermCondition1"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
     {/* <section className="banner-inner sustainability-visual">
        <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
                <div className="col-md-8">
                    <div className="banner-title text-center">
                    <h1>Social <span>Influence</span> </h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="find-us pb-5">
        <div className="container container-custom">
            <div className="row align-items-center mb-5">
                <div className="col-md-12">
                    <div className="heading-type-two">
                        <h2>Where to <span>find us</span></h2>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-3 socialmedia-box">
                    <div className="socialmedia-card">
                        <div className="social-media-icon">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                        <div className="social-media-body">
                            <h5>Facebook</h5>
                            <span>&#64;atmosphereliving</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 socialmedia-box">
                    <div className="socialmedia-card">
                        <div className="social-media-icon">
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                        <div className="social-media-body">
                            <h5>Twitter</h5>
                            <span>&#64;atmosphereliving</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 socialmedia-box">
                    <div className="socialmedia-card">
                        <div className="social-media-icon">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                        <div className="social-media-body">
                            <h5>Instagram</h5>
                            <span>&#64;atmosphereliving</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 socialmedia-box">
                    <div className="socialmedia-card">
                        <div className="social-media-icon">
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                        </div>
                        <div className="social-media-body">
                            <h5>Youtube</h5>
                            <span>&#64;atmosphereliving</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div className="facebook-posts py-4">
        <div className="container container-custom">
            <div className="row align-items-center">
                <div className="col-md-9">
                    <div className="heading-type-two social-box">
                        <div className="social-icon facebook">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                        <h2>Latest on <span>facebook</span></h2>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="lifestyle-action">
                        <a href="" className="btn btn-atmosphere btn-yellow">View All</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 pt-5">
                    <img src="/images/visuals/facebook1.png" alt="" className="img-fluid"/>
                </div>
            </div>
        </div>
    </div>

    <div className="instagram-posts py-4">
        <div className="container container-custom">
            <div className="row align-items-center">
                <div className="col-md-9">
                    <div className="heading-type-two social-box">
                        <div className="social-icon instagram">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                        <h2>Latest on <span>instagram</span></h2>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="lifestyle-action">
                        <a href="" className="btn btn-atmosphere btn-yellow">View All</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 pt-5">
                    <img src="/images/visuals/instagram1.png" alt="" className="img-fluid"/>
                </div>
            </div>
        </div>
    </div>

    <section className="contact-form-inner">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-form mt-0">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="contact-form-heading text-center">
                                    <h2>Our doors are wide open and we welcome your curiosity</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="text" className="form-control" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="email" className="form-control" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-5">
                                        <div className="contact-form-radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                                <label className="form-check-label" for="terms">
                        I accept <b>Terms &amp; Conditions</b> for procesing personal data
                      </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-form-input">
                                    <button type="submit" className="btn btn-light w-100">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
   </>
  )
}

export default SocialInfluence