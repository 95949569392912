//import img from 'next/image'
import { useSpring, animated } from '@react-spring/web'
import { useState, useEffect, useRef } from 'react';
export default function Section1() {

    const config = { duration: 2050 };
    const configauto = { duration: 2500 }; // Adjust the duration as needed
    let isTransitioning = false;
    const buttonRef = useRef(null);
    const [isClicked, setClicked] = useState(false);
    // image 1 animation bool
    const [isAnimated11, setIsAnimated11] = useState(false);
    const [isAnimated22, setIsAnimated22] = useState(false);
    const [isAnimated33, setIsAnimated33] = useState(false);
    const [isAnimated44, setIsAnimated44] = useState(false);
    const [isAnimated55, setIsAnimated55] = useState(false);
    const [isAnimated1, setIsAnimated1] = useState(false);
    const mediaQueryValues = {
        verytiny:'scale(0.4) translateX(1000px) translateY(0px)',
        tiny:'scale(0.5) translateX(615px) translateY(0px)',
        small: 'scale(0.6) translateX(745px) translateY(0px)',  // TranslateX value for small screens
        medium: 'scale(0.65) translateX(840px) translateY(0px) ',
        big: 'scale(0.65) translateX(960px) translateY(0px)', // TranslateX value for medium screens
        large: 'scale(0.72) translateX(1080px) translateY(0px)',  // TranslateX value for large screens
      };
      const mediaQueryValues1 = {
        verytiny:'scale(0.4) translateX(-1000px) translateY(0px)',
        tiny:'scale(0.5) translateX(-615px) translateY(0px)',
        small: 'scale(0.6) translateX(-745px) translateY(0px)',  // TranslateX value for small screens
        medium: 'scale(0.65) translateX(-840px) translateY(0px) ',
        big: 'scale(0.65) translateX(-960px) translateY(0px)', // TranslateX value for medium screens
        large: 'scale(0.72) translateX(-1080px) translateY(0px)',  // TranslateX value for large screens
      };
      const getTranslateXValue = () => {
        if (window.innerWidth <= 767) {
            return mediaQueryValues.verytiny;
          } else if (window.innerWidth <= 991) {
          return mediaQueryValues.tiny;
        } else if (window.innerWidth <= 1199) {
          return mediaQueryValues.small;
        } else if (window.innerWidth <= 1499) {
            return mediaQueryValues.medium;
        } else if (window.innerWidth <= 1780) {
            return mediaQueryValues.big;
        } else {
          return mediaQueryValues.large;
        }
      };
      const initialTransformX = getTranslateXValue();
      const getTranslateXValue1 = () => {
        if (window.innerWidth <= 767) {
            return mediaQueryValues1.verytiny;
          } else if (window.innerWidth <= 991) {
          return mediaQueryValues1.tiny;
        } else if (window.innerWidth <= 1199) {
          return mediaQueryValues1.small;
        } else if (window.innerWidth <= 1499) {
            return mediaQueryValues1.medium;
        } else if (window.innerWidth <= 1780) {
            return mediaQueryValues1.big;
        } else {
          return mediaQueryValues1.large;
        }
      };
      const initialTransformX1 = getTranslateXValue1();
    // image 1 animation variables
    const [targetValues, setTargetValues] = useState({
        xx11: 0,
        transformx11: initialTransformX,
        opacityx11: 1,
        config: config,
    });
    const [initialValues, setInitialValues] = useState({
        xx11: 0,
        transformx11: initialTransformX,
        opacityx11: 1,
        config: config,
    });
    const { xx11, transformx11, opacityx11 } = useSpring({
        from: initialValues,
        to: targetValues,
        config: config,
    });

    // image 1 animation variables
    const [targetValues2, setTargetValues2] = useState({
        xx22: 0,
        transformx22: initialTransformX,
        opacityx22: 1,
        config: config,
    });
    const [initialValues2, setInitialValues2] = useState({
        xx22: 0,
        transformx22: initialTransformX,
        opacityx22: 1,
        config: config,
    });
    const { xx22, transformx22, opacityx22 } = useSpring({
        from: initialValues2,
        to: targetValues2,
        config: config,
    });

    // image 1 animation variables
    const [targetValues3, setTargetValues3] = useState({
        xx33: 0,
        transformx33: initialTransformX,
        opacityx33: 1,
        config: config,
    });
    const [initialValues3, setInitialValues3] = useState({
        xx33: 0,
        transformx33: initialTransformX,
        opacityx33: 1,
        config: config,
    });
    const { xx33, transformx33, opacityx33 } = useSpring({
        from: initialValues3,
        to: targetValues3,
        config: config,
    });

    // image 1 animation variables
    const [targetValues4, setTargetValues4] = useState({
        xx44: 0,
        transformx44: initialTransformX,
        opacityx44: 1,
        config: config,
    });
    const [initialValues4, setInitialValues4] = useState({
        xx44: 0,
        transformx44: initialTransformX,
        opacityx44: 1,
        config: config,
    });
    const { xx44, transformx44, opacityx44 } = useSpring({
        from: initialValues4,
        to: targetValues4,
        config: config,
    });

    // image 1 animation variables
    const [targetValues5, setTargetValues5] = useState({
        xx55: 0,
        transformx55: initialTransformX,
        opacityx55: 1,
        config: config,
    });
    const [initialValues5, setInitialValues5] = useState({
        xx55: 0,
        transformx55: initialTransformX,
        opacityx55: 1,
        config: config,
    });
    const { xx55, transformx55, opacityx55 } = useSpring({
        from: initialValues5,
        to: targetValues5,
        config: config,
    });



    // main page button click
    const handleButtonClick = () => {
        setIsAnimated11((prev) => true);
        setIsAnimated1((prev) => true);
        setClicked(true);
        transformx11auto.reset();
        if (isStartAnimated == 4) {
            setIsStartAnimated(1)
        } else {
            setIsStartAnimated((prev) => prev + 1);
        }
        //setIsStartAnimated((prev) => prev+1);
        transformx11t.reset();
        //handleClickt1();
        // Update the target values when the button is clicked
        setTargetValues((prev) => {
            if (!isAnimated11) {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx11: 0,
                    transformx11: 'scale(1) translateX(0px) translateY(0px)',
                    opacityx11: 1,
                    config: config,
                };
            } else {
                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 1,
                    config: config,
                };
            }
        });
        setInitialValues((prev) => {
            if (isAnimated11) {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 1,
                    config: config,
                };
            } else {
                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 1,
                    config: config,
                };
            }
        });


    };



    // start animation from main page
    const [isStartAnimated, setIsStartAnimated] = useState(0);


    // Animation for image 1
    // const [isAnimated1, setIsAnimated1] = useState(false);
    const [isAnimated, setIsAnimated] = useState(false);



    // Animation for text 1
    // const [isAnimatedt1, setIsAnimatedt1] = useState(false);
    const [targetValuest, setTargetValuest] = useState({
        xx11t: 0,
        transformx11t: 'translateX(0px)',
        opacityx11t: 1,
        configt: config,
    });
    const [initialValuest, setInitialValuest] = useState({
        xx11t: 0,
        transformx11t: 'translateX(-86px)',
        opacityx11t: 1,
        configt: config,
    });
    const { xx11t, transformx11t, opacityx11t } = useSpring({
        from: initialValuest,
        to: targetValuest,
        config: config,
        // onRest: () => {
        //     if (isClicked) {
        //       // Trigger button click after animation is complete
        //       handleClick();
        //     }
        //   },
    });
    const [targetValuesauto, setTargetValuesauto] = useState({
        xx11auto: 0,
        transformx11auto: 'translateX(0px)',
        opacityx11auto: 1,
        configauto: config,
    });
    const [initialValuesauto, setInitialValuesauto] = useState({
        xx11auto: 0,
        transformx11auto: 'translateX(-86px)',
        opacityx11auto: 1,
        configauto: config,
    });
    const { xx11auto, transformx11auto, opacityx11auto } = useSpring({
        from: initialValuesauto,
        to: targetValuesauto,
        config: configauto,
        onRest: () => {
            if (isClicked) {
                // Trigger button click after animation is complete
                handleClick();
            }
        },
    });
    // const { xtx, transformtx } = useSpring({
    //     from: { xtx: 0, transformtx: ' translateX(-86px)' },
    //     to: { xtx: isAnimatedt1 ? 0 : 0, transformtx: isAnimatedt1 ? ' translateX(-86px)' : ' translateX(0px)' },
    //     config: config, // Adjust the duration as needed
    //     onRest: () => {
    //         setIsAnimatedt1(false);
    //     },

    // });

    // const handleClickt1 = () => {
    //     setIsAnimatedt1((prev) => true);
    // };





    // Animation for image 2



    const { x, transform } = useSpring({
        from: { x: 0, transform: 'scale(0.65) translateX(840px)' },
        to: { x: isAnimated ? 0 : 0, transform: isAnimated ? 'scale(1) translateX(0px)' : 'scale(0.65) translateX(840px)' },
        config: config,
    });

    const handleClick = () => {
        if (isTransitioning) return;
        isTransitioning = true;
        transformx11t.reset();
        transformx11auto.reset();
        setIsAnimated(true);
        // transformx11.reset();
        // opacityx11.reset();

        if (isStartAnimated >= 4) {
            setIsStartAnimated(1)
        } else {
            setIsStartAnimated((prev) => prev + 1);
        }
        console.log("isTransitioning")
        console.log(isTransitioning)
        console.log("isStartAnimated")
        console.log(isStartAnimated)
        if (isStartAnimated == 1) {
            // handleClickt1();

            //     transformx44.reset();
            //    opacityx44.reset();
            setTargetValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    setIsAnimated11((prev) => false);
                    return {
                        xx11: 0,
                        transformx11: initialTransformX1,
                        opacityx11: 0,
                        config: config,
                    };

                }
            });
            setInitialValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setIsAnimated22((prev) => true);
            setTargetValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx22: 0,
                        transformx22: initialTransformX,
                        opacityx22: 0,
                        config: config,
                    };
                }
            });
            setInitialValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: initialTransformX,
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx22: 0,
                        transformx22: initialTransformX,
                        opacityx22: 1,
                        config: config,
                    };
                }
            });


            setTargetValues3((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx33: 0,
                    transformx33: initialTransformX,
                    opacityx33: 1,
                    config: config,
                };

            });
            setInitialValues3((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx33: 0,
                    transformx33: initialTransformX,
                    opacityx33: 0,
                    config: config,
                };

            });

            setTargetValues4((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx44: 0,
                    transformx44: initialTransformX,
                    opacityx44: 0,
                    config: config,
                };

            });
            setInitialValues4((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx44: 0,
                    transformx44: initialTransformX,
                    opacityx44: 0,
                    config: config,
                };

            });
            //isTransitioning=false;
        }


        if (isStartAnimated == 2) {
            // handleClickt1();
            // transformx11.reset();
            // opacityx11.reset();
            setTargetValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    setIsAnimated22((prev) => false);
                    return {
                        xx22: 0,
                        transformx22: initialTransformX1,
                        opacityx22: 0,
                        config: config,
                    };
                }
            });
            setInitialValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx22: 0,
                        transformx22: initialTransformX,
                        opacityx22: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setIsAnimated33((prev) => true);
            setTargetValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx33: 0,
                        transformx33: initialTransformX,
                        opacityx33: 0,
                        config: config,
                    };
                }
            });
            setInitialValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: initialTransformX,
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx33: 0,
                        transformx33: initialTransformX,
                        opacityx33: 1,
                        config: config,
                    };
                }
            });


            setTargetValues4((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx44: 0,
                    transformx44: initialTransformX,
                    opacityx44: 1,
                    config: config,
                };

            });
            setInitialValues4((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx44: 0,
                    transformx44: initialTransformX,
                    opacityx44: 0,
                    config: config,
                };

            });


            setTargetValues((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 0,
                    config: config,
                };

            });
            setInitialValues((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 0,
                    config: config,
                };

            });
            // isTransitioning=false;
        }


        if (isStartAnimated == 3) {
            // handleClickt1();
            //    transformx22.reset();
            //    opacityx22.reset();
            setTargetValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    setIsAnimated33((prev) => false);
                    return {
                        xx33: 0,
                        transformx33: initialTransformX1,
                        opacityx33: 0,
                        config: config,
                    };
                }
            });
            setInitialValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx33: 0,
                        transformx33: initialTransformX,
                        opacityx33: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setIsAnimated44((prev) => true);
            setTargetValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx44: 0,
                        transformx44: 'scale(0.65) translateY(840px) translateY(0px)',
                        opacityx44: 1,
                        config: config,
                    };
                }
            });
            setInitialValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: initialTransformX,
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx44: 0,
                        transformx44: initialTransformX,
                        opacityx44: 1,
                        config: config,
                    };
                }
            });


            setTargetValues((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 1,
                    config: config,
                };

            });
            setInitialValues((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 0,
                    config: config,
                };

            });


            setTargetValues2((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx22: 0,
                    transformx22: initialTransformX,
                    opacityx22: 0,
                    config: config,
                };

            });
            setInitialValues2((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx22: 0,
                    transformx22: initialTransformX,
                    opacityx22: 0,
                    config: config,
                };

            });


            //isTransitioning=false;
        }


        if (isStartAnimated == 4) {
            // handleClickt1();
            //     transformx33.reset();
            //    opacityx33.reset();
            setTargetValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(-339px) translateY(0px)',
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    setIsAnimated44((prev) => false);
                    return {
                        xx44: 0,
                        transformx44: initialTransformX1,
                        opacityx44: 0,
                        config: config,
                    };
                }
            });
            setInitialValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx44: 0,
                        transformx44: initialTransformX,
                        opacityx44: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setIsAnimated11((prev) => true);
            setTargetValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx11: 0,
                        transformx11: initialTransformX,
                        opacityx11: 0,
                        config: config,
                    };
                }
            });
            setInitialValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: initialTransformX,
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx11: 0,
                        transformx11: initialTransformX,
                        opacityx11: 1,
                        config: config,
                    };
                }
            });
            setTargetValues2((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx22: 0,
                    transformx22: initialTransformX,
                    opacityx22: 1,
                    config: config,
                };

            });
            setInitialValues2((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx22: 0,
                    transformx22: initialTransformX,
                    opacityx22: 0,
                    config: config,
                };

            });

            setTargetValues3((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx33: 0,
                    transformx33: initialTransformX,
                    opacityx33: 0,
                    config: config,
                };

            });
            setInitialValues3((prev) => {

                // When isAnimated1 changes from true to false, move the div back to the original location
                return {
                    xx33: 0,
                    transformx33: initialTransformX,
                    opacityx33: 0,
                    config: config,
                };

            });

            // isTransitioning=false;
        }


    };


    const handlebackClick = () => {

        transformx11t.reset();

        setIsAnimated(true);
        // transformx11.reset();
        // opacityx11.reset();

        if (isStartAnimated == 1) {
            setIsStartAnimated(4)
        } else {
            setIsStartAnimated((prev) => prev - 1);
        }
        if (isStartAnimated == 1) {
            // handleClickt1();
            // transformx11.reset();
            // opacityx11.reset();
            setTargetValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    setIsAnimated11((prev) => false);
                    return {
                        xx11: 0,
                        transformx11: initialTransformX,
                        opacityx11: 1,
                        config: config,
                    };
                }
            });
            setInitialValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: initialTransformX,
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',

                        opacityx11: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setTargetValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx44: 0,
                        config: config,
                    };
                }
            });
            setInitialValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    setIsAnimated44((prev) => true);
                    return {
                        xx44: 0,
                        transformx44: initialTransformX1,
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    return {
                        xx44: 0,
                        transformx44: initialTransformX1,
                        opacityx44: 1,
                        config: config,
                    };
                }
            });

            setTargetValues3((prev) => {


                return {
                    xx33: 0,
                    transformx33: initialTransformX1,
                    opacityx33: 0,
                    config: config,
                };


            });
            setInitialValues3((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx33: 0,
                    transformx33: 'scale(1) translateX(0px) translateY(0px)',
                    opacityx33: 0,
                    config: config,
                };
            });

            setTargetValues2((prev) => {


                return {
                    xx22: 0,
                    transformx22: initialTransformX,
                    opacityx22: 0,
                    config: config,
                };


            });
            setInitialValues2((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx22: 0,
                    transformx22: initialTransformX,
                    opacityx22: 0,
                    config: config,
                };
            });

        }


        if (isStartAnimated == 2) {
            // handleClickt1();
            // transformx11.reset();
            // opacityx11.reset();
            setTargetValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    setIsAnimated22((prev) => false);
                    return {
                        xx22: 0,
                        transformx22: initialTransformX,
                        opacityx22: 1,
                        config: config,
                    };
                }
            });
            setInitialValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: initialTransformX,
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',

                        opacityx22: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setTargetValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx11: 0,
                        transformx11: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx11: 0,
                        config: config,
                    };
                }
            });
            setInitialValues((prev) => {
                if (!isAnimated11) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    setIsAnimated11((prev) => true);
                    return {
                        xx11: 0,
                        transformx11: initialTransformX1,
                        opacityx11: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    return {
                        xx11: 0,
                        transformx11: initialTransformX1,
                        opacityx11: 1,
                        config: config,
                    };
                }
            });

            setTargetValues4((prev) => {


                return {
                    xx44: 0,
                    transformx44: initialTransformX1,
                    opacityx44: 0,
                    config: config,
                };


            });
            setInitialValues4((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx44: 0,
                    transformx44: 'scale(1) translateX(0px) translateY(0px)',
                    opacityx44: 0,
                    config: config,
                };
            });


            setTargetValues3((prev) => {


                return {
                    xx33: 0,
                    transformx33: initialTransformX,
                    opacityx33: 0,
                    config: config,
                };


            });
            setInitialValues3((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx33: 0,
                    transformx33: initialTransformX,
                    opacityx33: 0,
                    config: config,
                };
            });

        }

        if (isStartAnimated == 3) {
            // handleClickt1();
            // transformx11.reset();
            // opacityx11.reset();
            setTargetValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    setIsAnimated33((prev) => false);
                    return {
                        xx33: 0,
                        transformx33: initialTransformX,
                        opacityx33: 1,
                        config: config,
                    };
                }
            });
            setInitialValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: initialTransformX,
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',

                        opacityx33: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setTargetValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx22: 0,
                        transformx22: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx22: 0,
                        config: config,
                    };
                }
            });
            setInitialValues2((prev) => {
                if (!isAnimated22) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    setIsAnimated22((prev) => true);
                    return {
                        xx22: 0,
                        transformx22: initialTransformX1,
                        opacityx22: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    return {
                        xx22: 0,
                        transformx22: initialTransformX1,
                        opacityx22: 1,
                        config: config,
                    };
                }
            });

            setTargetValues((prev) => {


                return {
                    xx11: 0,
                    transformx11: initialTransformX1,
                    opacityx11: 0,
                    config: config,
                };


            });
            setInitialValues((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx11: 0,
                    transformx11: 'scale(1) translateX(0px) translateY(0px)',
                    opacityx11: 0,
                    config: config,
                };


            });

            setTargetValues4((prev) => {


                return {
                    xx44: 0,
                    transformx44: initialTransformX,
                    opacityx44: 0,
                    config: config,
                };


            });
            setInitialValues4((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx44: 0,
                    transformx44: initialTransformX,
                    opacityx44: 0,
                    config: config,
                };
            });

        }

        if (isStartAnimated == 4) {
            // handleClickt1();
            // transformx11.reset();
            // opacityx11.reset();
            setTargetValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    setIsAnimated44((prev) => false);
                    return {
                        xx44: 0,
                        transformx44: initialTransformX,
                        opacityx44: 1,
                        config: config,
                    };
                }
            });
            setInitialValues4((prev) => {
                if (!isAnimated44) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx44: 0,
                        transformx44: initialTransformX,
                        opacityx44: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx44: 0,
                        transformx44: 'scale(1) translateX(0px) translateY(0px)',

                        opacityx44: 1,
                        config: config,
                    };
                }
            });

            // handle click 2
            setTargetValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location
                    return {
                        xx33: 0,
                        transformx33: 'scale(1) translateX(0px) translateY(0px)',
                        opacityx33: 0,
                        config: config,
                    };
                }
            });
            setInitialValues3((prev) => {
                if (!isAnimated33) {
                    // When isAnimated1 changes from false to true, move the div to a new location
                    setIsAnimated33((prev) => true);
                    return {
                        xx33: 0,
                        transformx33: initialTransformX1,
                        opacityx33: 1,
                        config: config,
                    };
                } else {
                    // When isAnimated1 changes from true to false, move the div back to the original location

                    return {
                        xx33: 0,
                        transformx33: initialTransformX1,
                        opacityx33: 1,
                        config: config,
                    };
                }
            });

            setTargetValues2((prev) => {


                return {
                    xx22: 0,
                    transformx22: initialTransformX1,
                    opacityx22: 0,
                    config: config,
                };


            });
            setInitialValues2((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx22: 0,
                    transformx22: 'scale(1) translateX(0px) translateY(0px)',
                    opacityx22: 0,
                    config: config,
                };
            });

            setTargetValues((prev) => {


                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 0,
                    config: config,
                };


            });
            setInitialValues((prev) => {
                // When isAnimated1 changes from false to true, move the div to a new location
                return {
                    xx11: 0,
                    transformx11: initialTransformX,
                    opacityx11: 0,
                    config: config,
                };
            });
        }

    };


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       // Reset the button click state every 10 seconds
    //       setClicked(false);
    //     }, 10000);

    //     return () => {
    //       clearInterval(interval);
    //     };
    //   }, []);

    //   useEffect(() => {
    //     // Trigger button click on component mount
    //     buttonRef.current.click();
    //   }, []);



    // document.addEventListener("DOMContentLoaded", () => {
    // function clickButton() {
    //    document.getElementById("nextbutton").click();
    //  // myButton.click();
    // }

    // setInterval(clickButton, 10000);

    // })

    // if (isAnimated){
    // const sliderContainer = document.querySelector(".back-window");
    // sliderContainer && sliderContainer.addEventListener("transitionend", () => {
    //     isTransitioning = false;
    // })
    // //setInterval(handleClick, 5000);
    // setInterval(() => {
    //     handleClick();
    //   }, 10000)
    //handle for Main page click
    // const mainhandleClick = () => {
    //     if (isStartAnimated == 4) {
    //         setIsStartAnimated(1)
    //     } else {
    //         setIsStartAnimated((prev) => prev + 1);
    //     }
    //     //  setIsStartAnimated((prev) => prev+1);
    //     handleClick1();
    //    // handleClickt1();

    // };

    return (
        <>

<section>
        <div className="main-banner position-relative overflow-hidden">
            <div className="social-media">
                <a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                <a href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                <a href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
            </div>
            <div className="banner-slider">
                <div className="main-slider">
                    <div className="welcome-slide position-absolute top-0 h-100 w-100" style={{ display: isStartAnimated == 0 ? 'block' : 'none' }}>
                        <div className="slider-bg-image h-100">
                            <img src="/images/visuals/bg1.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="welcome-text">
                            <span>Welcome to Atmosphere Living</span>
                            <h1><span>Exclusive like you</span></h1>
                        </div>
                        <div className="explore-now position-absolute rounded-pill d-flex flex-column align-items-center justify-content-center m-auto start-0 end-0 bottom-0 mb-4">
                            <span className="text-white h5 fw-light mb-4 text-uppercase">Explore</span>
                            <button  onClick={handleButtonClick} className="slider-btn bg-transparent text-white rounded-pill position-relative">
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        
                    </div>
                    <div className="slider-content position-relative">
                        <div className="slider-bg position-absolute start-0 top-0 h-100 w-100" style={{ display: isStartAnimated == 4 ? 'block' : 'none' }}>
                            <div className="slider-bg-image h-100">
                                <img src="/images/visuals/bg2.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="slider-bg position-absolute start-0 top-0 h-100 w-100" style={{ display: isStartAnimated == 1 ? 'block' : 'none' }}>
                            <div className="slider-bg-image h-100">
                                <img src="/images/visuals/bg3.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="slider-bg position-absolute start-0 top-0 h-100 w-100" style={{ display: isStartAnimated == 2 ? 'block' : 'none' }}>
                            <div className="slider-bg-image h-100">
                                <img src="/images/visuals/bg4.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="slider-bg position-absolute start-0 top-0 h-100 w-100" style={{ display: isStartAnimated == 3 ? 'block' : 'none' }}>
                            <div className="slider-bg-image h-100">
                                <img src="/images/visuals/bg5.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                      
                        
    
                        <div className="slider-body">
                            <div className="container-fluid wide-container">
                                <div className="slider-card">
                                    <div className="row align-items-center">
                                        <div className="atamosphere-left-text">
                                            <div className="slider-text" style={{ display: isStartAnimated == 1 ? 'block' : 'none' }}>
                                                <animated.h1
                                                 style={{
                                                    opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                    transform: transformx11t.interpolate(
                                                        (scaleValue, translateXValue) => `${scaleValue} `
                                                    ),
                                                    display: isStartAnimated == 1 ? 'block' : 'none',
                                                }}
                                                 className="animate__animated" data-animation-in="animate__fadeInLeft"><span>Branded</span>
                                                    <br/> Residences
                                                </animated.h1>
                                                <p>Experience intuitive luxury crafted with your desires at heart, where every detail is tailored to exceed expectations.</p>
                                                <a href="#" className=""><span className="btn btn-border-yellow fw-bold">Explore Now</span><span className="ms-xl-4 ms-3"><img src="/images/icon/yellow-long-arrow.svg" alt="Next" title="Next"/></span></a>
                                            </div>
                                            <div className="slider-text" style={{ display: isStartAnimated == 2 ? 'block' : 'none' }}
                                                //  style={{
                                                //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                //             }}

                                                >
                                                    <animated.h1
                                                        style={{
                                                            opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                            transform: transformx11t.interpolate(
                                                                (scaleValue, translateXValue) => `${scaleValue} `
                                                            ),
                                                            display: isStartAnimated == 2 ? 'block' : 'none',
                                                        }}
                                                        className="animate__animated"
                                                        data-animation-in="animate__fadeInLeft">
                                                        <span>Concierge</span> <br /> Services
                                                    </animated.h1>
                                                    <p>Experience intuitive luxury crafted with your desires at heart, where every detail is tailored to exceed expectations.</p>
                                                <a href="#" className=""><span className="btn btn-border-yellow fw-bold">Explore Now</span><span className="ms-xl-4 ms-3"><img src="/images/icon/yellow-long-arrow.svg" alt="Next" title="Next"/></span></a>

                                                    {/* <a href="" className="btn btn-border-yellow">Explore Now</a> */}
                                                </div>
                                                {/* </div> */}
                                                {/* <div className="slider-text silder-text-hide"> */}
                                                <div className="slider-text" style={{ display: isStartAnimated == 3 ? 'block' : 'none' }}
                                                //  style={{
                                                //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                //             }}

                                                >
                                                    <animated.h1
                                                        style={{
                                                            opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                            transform: transformx11t.interpolate(
                                                                (scaleValue, translateXValue) => `${scaleValue} `
                                                            ),
                                                            display: isStartAnimated == 3 ? 'block' : 'none',
                                                        }}
                                                        className="animate__animated"
                                                        data-animation-in="animate__fadeInLeft"><span>Rental</span>
                                                        <br /> Income Pool
                                                    </animated.h1>
                                                    <p>Experience intuitive luxury crafted with your desires at heart, where every detail is tailored to exceed expectations.</p>
                                                <a href="#" className=""><span className="btn btn-border-yellow fw-bold">Explore Now</span><span className="ms-xl-4 ms-3"><img src="/images/icon/yellow-long-arrow.svg" alt="Next" title="Next"/></span></a>

                                                    {/* <a href="" className="btn btn-border-yellow">Explore Now</a> */}
                                                </div>
                                                {/* </div> */}
                                                {/* <div className="slider-text silder-text-hide"> */}

                                                <div className="slider-text" style={{ display: isStartAnimated == 4 ? 'block' : 'none' }}
                                                //  style={{
                                                //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                //             }}

                                                >  <animated.h1
                                                    style={{
                                                        opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                        transform: transformx11t.interpolate(
                                                            (scaleValue, translateXValue) => `${scaleValue} `
                                                        ),
                                                        display: isStartAnimated == 4 ? 'block' : 'none',
                                                    }}
                                                    className="animate__animated"
                                                    data-animation-in="animate__fadeInLeft"><span>Exclusive
                                                        </span> <br /> Privileges </animated.h1>
                                                        <p>Experience intuitive luxury crafted with your desires at heart, where every detail is tailored to exceed expectations.</p>
                                                <a href="#" className=""><span className="btn btn-border-yellow fw-bold">Explore Now</span><span className="ms-xl-4 ms-3"><img src="/images/icon/yellow-long-arrow.svg" alt="Next" title="Next"/></span></a>

                                                    {/* <a href="" className="btn btn-border-yellow">Explore Now</a> */}
                                                </div>
                                                <animated.div className="slider-text"
                                                    //  style={{
                                                    //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                    //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                    //             }}
                                                    style={{
                                                        opacity: opacityx11auto.interpolate((opacityValue) => opacityValue),
                                                        transform: transformx11auto.interpolate(
                                                            (scaleValue, translateXValue) => `${scaleValue} `
                                                        ),
                                                        display: 'none',
                                                    }}
                                                >
                                                </animated.div>
                                        </div>
                                        <div className="atamosphere-image-slide position-relative">
                                            <div className="atamosphere-slide-window position-relative">
                                                <div 
                                                
                                                // className={`${isAnimated1 ? 'front-window active-slide' : 'front-window '}`}
                                                 className="front-window  demo-slide position-relative start-0 opacity-0"
                                                >
                                                    <div className="features-window d-flex justify-content-end align-items-start">
                                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                            <span className="d-flex overflow-hidden"><img className="w-100" src="/images/visuals/sl1.jpg" alt=""/></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <animated.div 
                                                 style={{
                                                    opacity: opacityx11.interpolate((opacityValue) => opacityValue),
                                                    transform: transformx11.interpolate(
                                                        (scaleValue, translateXValue) => `${scaleValue} `
                                                    ),

                                                }}
                                                className={`${isStartAnimated==1 ? 'front-window active-slide' : 'front-window '}`}

                                                // className="front-window active-slide"
                                                >
                                                    <div className="features-window d-flex justify-content-end align-items-start">
                                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                            <span className="d-flex overflow-hidden"><img className="w-100" src="/images/visuals/sl1.jpg" alt=""/></span>
                                                        </div>
                                                    </div>
                                                </animated.div>
                                                <animated.div 
                                                 style={{
                                                    opacity: opacityx22.interpolate((opacityValue) => opacityValue),
                                                    transform: transformx22.interpolate(
                                                        (scaleValue, translateXValue) => `${scaleValue} `
                                                    ),

                                                }}
                                                className={`${isStartAnimated==2 ? 'front-window active-slide first-visual' : 'front-window first-visual'}`}

                                                // className="front-window"
                                                >
                                                    <div className="features-window d-flex justify-content-end align-items-start">
                                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                            <span className="d-flex overflow-hidden"><img className="w-100" src="/images/visuals/sl2.jpg" alt=""/></span>
                                                        </div>
                                                    </div>
                                                </animated.div>
                                                <animated.div 
                                                 style={{
                                                    opacity: opacityx33.interpolate((opacityValue) => opacityValue),
                                                    transform: transformx33.interpolate(
                                                        (scaleValue, translateXValue) => `${scaleValue} `
                                                    ),

                                                }}
                                                className={`${isStartAnimated==3 ? 'front-window active-slide' : 'front-window '}`}

                                                // className="front-window"
                                                >
                                                    <div className="features-window d-flex justify-content-end align-items-start">
                                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                            <span className="d-flex overflow-hidden"><img className="w-100" src="/images/visuals/sl3.jpg" alt=""/></span>
                                                        </div>
                                                    </div>
                                                </animated.div>
                                                <animated.div 
                                                 style={{
                                                    opacity: opacityx44.interpolate((opacityValue) => opacityValue),
                                                    transform: transformx44.interpolate(
                                                        (scaleValue, translateXValue) => `${scaleValue} `
                                                    ),

                                                }}
                                                className={`${isStartAnimated==4 ? 'front-window active-slide' : 'front-window '}`}

                                                // className="front-window"
                                                >
                                                    <div className="features-window d-flex justify-content-end align-items-start">
                                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                            <span className="d-flex overflow-hidden"><img className="w-100" src="/images/visuals/sl4.jpg" alt=""/></span>
                                                        </div>
                                                    </div>
                                                </animated.div>
                                                {/* <animated.div 
                                                 style={{
                                                    opacity: opacityx55.interpolate((opacityValue) => opacityValue),
                                                    transform: transformx55.interpolate(
                                                        (scaleValue, translateXValue) => `${scaleValue} `
                                                    ),

                                                }}
                                                className={`${isStartAnimated==5 ? 'front-window active-slide' : 'front-window '}`}

                                                // className="front-window"
                                                >
                                                    <div className="features-window d-flex justify-content-end align-items-start">
                                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                            <span className="d-flex overflow-hidden"><img className="w-100" src="/images/visuals/sl5.jpg" alt=""/></span>
                                                        </div>
                                                    </div>
                                                </animated.div> */}
                                            </div>
                                            <div className="slider-action-control d-flex align-items-center mt-xl-5 mt-4 position-relative">
                                                <div className="slide-btn-wrapper d-flex">
                                                    <button className="btn me-3 slider-btn btn-prev text-white rounded-pill d-flex align-items-center justify-content-center" onClick={handlebackClick} style={{ display: !isAnimated ? 'none' : 'block' }}><i className="fa fa-chevron-left"></i></button>
                                                    <button ref={buttonRef} className="btn slider-btn btn-next text-white rounded-pill d-flex align-items-center justify-content-center" onClick={handleClick}><i className="fa fa-chevron-right"></i></button>
                                                </div>
                                                <div className="slider-progress position-relative d-flex align-items-center justify-content-between">
                                                    <div className="progress" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ backgroundSize: isStartAnimated * 25 + '% 100%' }}>
                                                        <span className="slider__label sr-only"></span>
                                                    </div>
                                                    <span className="text-white" id="slider-counter">{isStartAnimated}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            {/* <section className="main-banner">
                <div className="social-media">
                    <a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    <a href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                    <a href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                </div>
                <div className="banner-slider h-100">
                    <div className="main-slider h-100">
                        <div className="welcome-slide h-100" style={{ display: isStartAnimated == 0 ? 'block' : 'none' }}>
                            <div className="slider-bg-image h-100">
                                <img
                                    src="/images/visuals/bg1.jpg"
                                    alt=""
                                    width={2500}
                                    height={1080}
                                    className='img-fluid'
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </div>
                            <div className="welcome-text">
                                <span>Welcome to Atmosphere Living</span>
                                <h1><span>Exclusive</span> like you</h1>
                            </div>
                            <button id="mainbannerid" className="slider-btn" onClick={handleButtonClick}>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="slider-content h-100" >
                            <div className="slider-bg" style={{ display: isStartAnimated == 4 ? 'block' : 'none' }}>
                                <div className="slider-bg-image h-100">
                                    <img
                                        src="/images/visuals/bg2.jpg"
                                        alt=""
                                        width={2500}
                                        height={1080}
                                    />
                                </div>
                            </div>
                            <div className="slider-bg" style={{ display: isStartAnimated == 1 ? 'block' : 'none' }}>
                                <div className="slider-bg-image h-100">
                                    <img
                                        src="/images/visuals/bg3.jpg"
                                        alt=""
                                        width={2500}
                                        height={1080}
                                    />
                                </div>
                            </div>
                            <div className="slider-bg" style={{ display: isStartAnimated == 2 ? 'block' : 'none' }}>
                                <div className="slider-bg-image h-100">
                                    <img
                                        src="/images/visuals/bg4.jpg"
                                        alt=""
                                        width={2500}
                                        height={1080}
                                    />
                                </div>
                            </div>
                            <div className="slider-bg" style={{ display: isStartAnimated == 3 ? 'block' : 'none' }}>
                                <div className="slider-bg-image h-100">
                                    <img
                                        src="/images/visuals/bg5.jpg"
                                        alt=""
                                        width={2500}
                                        height={1080}
                                    />
                                </div>
                            </div>


                            <div className="slider-body">
                                <div className="container">
                                    <div className="slider-card">
                                        <div className="row align-items-center">
                                            <div className="col-lg-5 col-md-5">
                                                <div className="slider-text" style={{ display: isStartAnimated == 1 ? 'block' : 'none' }}


                                                >
                                                    <animated.h1
                                                        style={{
                                                            opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                            transform: transformx11t.interpolate(
                                                                (scaleValue, translateXValue) => `${scaleValue} `
                                                            ),
                                                            display: isStartAnimated == 1 ? 'block' : 'none',
                                                        }}
                                                        className="animate__animated"
                                                        data-animation-in="animate__fadeInLeft"><span>Branded</span>
                                                        <br /> Residences
                                                    </animated.h1>
                                                    <p>Experience intuitive luxury at its finest, crafted with your
                                                        desires at
                                                        heart, where every detail is tailored to exceed
                                                        expectations.</p>
                                                    <a href="" className="btn btn-border-yellow">Explore Now</a>
                                                </div>
                                                <div className="slider-text" style={{ display: isStartAnimated == 2 ? 'block' : 'none' }}
                                                //  style={{
                                                //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                //             }}

                                                >
                                                    <animated.h1
                                                        style={{
                                                            opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                            transform: transformx11t.interpolate(
                                                                (scaleValue, translateXValue) => `${scaleValue} `
                                                            ),
                                                            display: isStartAnimated == 2 ? 'block' : 'none',
                                                        }}
                                                        className="animate__animated"
                                                        data-animation-in="animate__fadeInLeft">
                                                        <span>Concierge</span> <br /> Services
                                                    </animated.h1>
                                                    <p>Experience intuitive luxury at its finest, crafted with your
                                                        desires at
                                                        heart, where every detail is tailored to exceed
                                                        expectations.</p>
                                                    <a href="" className="btn btn-border-yellow">Explore Now</a>
                                                </div>
                                                <div className="slider-text" style={{ display: isStartAnimated == 3 ? 'block' : 'none' }}
                                                //  style={{
                                                //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                //             }}

                                                >
                                                    <animated.h1
                                                        style={{
                                                            opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                            transform: transformx11t.interpolate(
                                                                (scaleValue, translateXValue) => `${scaleValue} `
                                                            ),
                                                            display: isStartAnimated == 3 ? 'block' : 'none',
                                                        }}
                                                        className="animate__animated"
                                                        data-animation-in="animate__fadeInLeft"><span>Rental</span>
                                                        <br /> Income Pool
                                                    </animated.h1>
                                                    <p>Experience intuitive luxury at its finest, crafted with your
                                                        desires at
                                                        heart, where every detail is tailored to exceed
                                                        expectations.</p>
                                                    <a href="" className="btn btn-border-yellow">Explore Now</a>
                                                </div>

                                                <div className="slider-text" style={{ display: isStartAnimated == 4 ? 'block' : 'none' }}
                                                //  style={{
                                                //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                //             }}

                                                >  <animated.h1
                                                    style={{
                                                        opacity: opacityx11t.interpolate((opacityValue) => opacityValue),
                                                        transform: transformx11t.interpolate(
                                                            (scaleValue, translateXValue) => `${scaleValue} `
                                                        ),
                                                        display: isStartAnimated == 4 ? 'block' : 'none',
                                                    }}
                                                    className="animate__animated"
                                                    data-animation-in="animate__fadeInLeft"><span>Exclusive
                                                        </span> <br /> Privileges </animated.h1>
                                                    <p>Experience intuitive luxury crafted with your
                                                        desires at
                                                        heart, where every detail is tailored to exceed
                                                        expectations.</p>
                                                    <a href="" className="btn btn-border-yellow">Explore Now</a>
                                                </div>
                                                <animated.div className="slider-text"
                                                    //  style={{
                                                    //                 transform: transformtx.interpolate((scaleValue, translateXValue) => `${scaleValue} `),
                                                    //                 marginLeft: xtx.interpolate((xValue) => `${xValue}px`),
                                                    //             }}
                                                    style={{
                                                        opacity: opacityx11auto.interpolate((opacityValue) => opacityValue),
                                                        transform: transformx11auto.interpolate(
                                                            (scaleValue, translateXValue) => `${scaleValue} `
                                                        ),
                                                        display: 'none',
                                                    }}
                                                >
                                                </animated.div>
                                            </div>
                                            <div className="col-lg-7 col-md-7">
                                                <div className="window-wrapper">
                                                    
                                                    <div className="blur-window">
                                                        <animated.div className={`${isAnimated1 ? 'back-window first-visual1' : 'back-window blur-visual first-visual1'}`}

                                                            style={{
                                                                opacity: opacityx11.interpolate((opacityValue) => opacityValue),
                                                                transform: transformx11.interpolate(
                                                                    (scaleValue, translateXValue) => `${scaleValue} `
                                                                ),

                                                            }}

                                                        >
                                                            <div className="slider-visual">
                                                                <img
                                                                    src="/images/visuals/sl1.jpg"
                                                                    alt=""
                                                                    width={303}
                                                                    height={435.98}
                                                                />

                                                            </div>
                                                            <div className="window-border blur-border"></div>
                                                        </animated.div>
                                                        <animated.div className={`${isAnimated ? 'back-window  first-visual' : 'back-window blur-visual first-visual'}`}

                                                            style={{
                                                                opacity: opacityx22.interpolate((opacityValue) => opacityValue),
                                                                transform: transformx22.interpolate(
                                                                    (scaleValue, translateXValue) => `${scaleValue} `
                                                                ),

                                                            }}
                                                         
                                                        >
                                                            <div className="slider-visual">
                                                                <img
                                                                    src="/images/visuals/sl2.jpg"
                                                                    alt=""
                                                                    width={303}
                                                                    height={435.98}
                                                                />

                                                            </div>
                                                            <div className="window-border blur-border"></div>
                                                        </animated.div>
                                                        <animated.div className={`${isAnimated1 ? 'back-window ' : 'back-window blur-visual '}`}

                                                            style={{
                                                                opacity: opacityx33.interpolate((opacityValue) => opacityValue),
                                                                transform: transformx33.interpolate(
                                                                    (scaleValue, translateXValue) => `${scaleValue} `
                                                                ),

                                                            }}

                                                        >
                                                            <div className="slider-visual">
                                                                <img
                                                                    src="/images/visuals/sl3.jpg"
                                                                    alt=""
                                                                    width={303}
                                                                    height={435.98}
                                                                />

                                                            </div>
                                                            <div className="window-border blur-border"></div>
                                                        </animated.div>
                                                        <animated.div className={`${isAnimated1 ? 'back-window ' : 'back-window blur-visual '}`}

                                                            style={{
                                                                opacity: opacityx44.interpolate((opacityValue) => opacityValue),
                                                                transform: transformx44.interpolate(
                                                                    (scaleValue, translateXValue) => `${scaleValue} `
                                                                ),

                                                            }}

                                                        >
                                                            <div className="slider-visual">
                                                                <img
                                                                    src="/images/visuals/sl4.jpg"
                                                                    alt=""
                                                                    width={303}
                                                                    height={435.98}
                                                                />

                                                            </div>
                                                            <div className="window-border blur-border"></div>
                                                        </animated.div>
                                                        <animated.div className={`${isAnimated1 ? 'back-window ' : 'back-window blur-visual '}`}

                                                            style={{
                                                                opacity: opacityx55.interpolate((opacityValue) => opacityValue),
                                                                transform: transformx55.interpolate(
                                                                    (scaleValue, translateXValue) => `${scaleValue} `
                                                                ),

                                                            }}

                                                        >
                                                            <div className="slider-visual">
                                                                <img
                                                                    src="/images/visuals/sl5.jpg"
                                                                    alt=""
                                                                    width={303}
                                                                    height={435.98}
                                                                />

                                                            </div>
                                                            <div className="window-border blur-border"></div>
                                                        </animated.div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slide-btn-wrapper">
                                <button className="btn slider-btn btn-prev" onClick={handlebackClick} style={{ display: !isAnimated ? 'none' : 'block' }}><i className="fa fa-chevron-left"></i></button>
                                <button ref={buttonRef} id="nextbutton" className="btn slider-btn btn-next" onClick={handleClick} ><i className="fa fa-chevron-right"></i></button>
                            </div>
                            <div className="slider-progress">
                                <div className="progress" role="progressbar" aria-valuemin={0} aria-valuemax={100} style={{ backgroundSize: isStartAnimated * 25 + '% 100%' }}>
                                    <span className="slider__label sr-only" />
                                </div>
                                <span className="text-white" id="slider-counter">{isStartAnimated}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}





        </>
    )
}
