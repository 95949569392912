
import React, { useState } from 'react'
import { sendEnquireData } from '../dataService/DataService';
//import nodemailer from "nodemailer"
import { toast } from "react-toastify";
import { useMutation } from 'react-query';

const OurManagement = () => {

    const [formData, setFormData] = useState({
        name: '',
         phone: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const newErrors = { ...errors };

        if (name === "name" && !value) {
            newErrors.name = "Please enter full name";
        } else if (name === "name") {
            delete newErrors.name; // Clear the error if the field is valid
        }

        if (name === "email" && !value) {
            newErrors.email = "Please enter email";
        } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = "Please enter valid email";
        } else if (name === "email") {
            delete newErrors.email;
        }

        if (name === "phone" && !value) {
            newErrors.phone = "Please enter phone no.";
        } else if (name === "phone" && !/^\d{10}$/.test(value)) {
            newErrors.phone = "Please enter valid phone no.";
        } else if (name === "phone") {
            delete newErrors.phone;
        }

        if (name === "message" && !value) {
            newErrors.message = "Please enter message";
        } else if (name === "message") {
            delete newErrors.message;
        }

        // Set the updated errors object
        setErrors(newErrors);

    };
    function sendEmail() {
        console.log("button blicked ")
        console.log("formData")
        console.log(formData)
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Please enter full name";
        }
        if (!formData.email) {
            newErrors.email = "Please enter email";
        }
        if (!formData.phone) {
            newErrors.phone = "Please enter phone no.";
        }
        if (!formData.message) {
            newErrors.message = "Please enter message";
        }
        if (Object.keys(newErrors).length === 0) {
            sendEnquireDat.mutate(formData);
            setErrors(newErrors);
        } else {
            setErrors(newErrors);

        }
    }

    const sendEnquireDat = useMutation(sendEnquireData, {
        onError: (error) => {
            toast.error(error.message);

        },
        onSuccess: (data) => {

            toast.success("Enquiry submitted. We will contact you soon.");
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: '',
            });
            // console.log("Succesful",{data});
        },
    });
  return (
   <>
  {/* <!-- Banner Inner --> */}
    <section>
        <div className="banner-inner customerrelation-visual overflow-hidden">
            <div className="container container-custom h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-md-8">
                        <div className="banner-title text-center">
                            <h1><span>Leadership</span></h1>
                            <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                                <div className="logo-circle">
                                    <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                                </div>
                            </div>
                            <div className="col-md-12 d-none">
                                <div className="mt-lg-5 mt-4">
                                    <p className="text-white m-0">Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Founder --> */}
    <section>
        <div className="founder overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="founder-info">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-5 d-none d-md-flex">
                                    <div className="founder-wrap" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3 mb-0">
                                            <span className="d-flex overflow-hidden"><img src="/images/visuals/sandeep-ahuja.jpg" alt="" /></span>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-md-4 --> */}
                                <div className="col-lg-8 col-md-7">
                                    <div className="about-founder-text" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="founder-message">
                                            <h2><span>Sandeep</span> Ahuja</h2>
                                            <span className="position-name position-relative text-capitalize mt-lg-4 mt-3 d-flex align-items-center ">Managing Director, (Atmosphere Living)</span>
                                        </div>
                                        <div className="founder-wrap d-md-none">
                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3 mb-0">
                                            <span className="d-flex overflow-hidden"><img src="/images/visuals/sandeep-ahuja.jpg" alt="" /></span>
                                        </div>
                                        </div>
                                        <p>Sandeep is a visionary leader owing to his rich and seasoned career spanning Media, Hospitality and Retail. Notably, during his time at Tata Housing, he acquired 26 projects, valued at USD 3 billion, spanning 10 vibrant cities and set up operations in international markets. As head of “New Business Initiatives” he also introduced the "Riva" Senior Living project in Bangalore.</p>
                                        <p>But his success is not just in the numbers; it is his passion for innovation and positive change. At Atmosphere Living, Sandeep's innovative spirit soars. Here, he is rewriting the rules of luxury for homes, crafting a world where exceptional living becomes a reality.</p>
                                        <div className="founder-action mt-4 d-none">
                                            <a className="yellow-btn-wrap d-inline-blcok" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">read more</span>
                                                <span className="ms-4"><img src="/images/icon/vertically-arrow.svg" alt="Next" title="Next"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="founder-info salil">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-md-7">
                                    <div className="about-founder-text text-end" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="founder-message">
                                            <h2><span>Salil</span> Panigrahi</h2>
                                            <span className="position-name justify-content-end position-relative text-capitalize mt-lg-4 mt-3 d-flex align-items-center position-right-alt">Managing Director, (Atmosphere Core)</span>
                                        </div>
                                        <div className="founder-wrap d-md-none">
                                        <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3 mb-0">
                                            <span className="d-flex overflow-hidden"><img src="/images/visuals/sahil-panigrahi.jpg" alt="" /></span>
                                        </div>
                                        </div>
                                        <p>A visionary hotelier par excellence, Salil is the Managing Director of Atmosphere Core, a leading hospitality company with three distinct leisure brands. In just a decade, he’s overseen the launch of eight breathtaking resorts in the Maldives, with even grander plans on the horizon.</p>
                                        <p>He received the Pravasi Bharatiya Samman Award (PBSA) from the Government of India, recognising his significant contributions to the tourism sector in the Maldives. <br/>His passion for delivering exceptional guest experiences and innovative design led him to create some iconic resorts in the Maldives.</p>
                                        <p>Salil brings his magical touch to Atmosphere Living, crafting homes where you not only dream but where dreams become a reality.</p>
                                        <div className="founder-action mt-4 d-none">
                                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">read more</span>
                                                <span className="ms-4"><img src="/images/icon/vertically-arrow.svg" alt="Next" title="Next"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-md-8 --> */}
                                <div className="col-lg-4 col-md-5 d-none d-md-flex">
                                    <div className="founder-wrap reverse-img ms-lg-auto" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3 mb-0">
                                            <span className="d-flex overflow-hidden"><img src="/images/visuals/sahil-panigrahi.jpg" alt="" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 d-none">
                        <div className="founder-info">
                            <div className="row">
                                <div className="col-lg-4 col-md-5">
                                    <div className="founder-wrap" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="founder-img">
                                            <img src="/images/visuals/founder3.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="founder-about text-center">
                                            <h3>Cynthia Dalton</h3>
                                            <span>Co-founder</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-lg-4 --> */}
                                <div className="col-lg-8 col-md-7">
                                    <div className="about-founder-text" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="founder-message">
                                            <h2>Co - <span>Founder</span></h2>
                                        </div>
                                        <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <div className="founder-action mt-4">
                                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">read more</span>
                                                <span className="ms-4"><img src="/images/icon/vertically-arrow.svg" alt="Next" title="Next"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /col-lg-8 --> */}
                            </div>
                            {/* <!-- /row --> */}
                        </div>
                        {/* <!-- /founder-info --> */}
                    </div>
                    {/* <!-- /col-12 --> */}
                    
                </div>
                {/* <!-- /row --> */}
            </div>
            {/* <!-- /container --> */}
        </div>
        {/* <!-- /founder --> */}
    </section>

    {/* <!-- Management --> */}
    <section>
        <div className="management overflow-hidden d-none">
            <div className="container container-custom">
                <div className="row align-items-center" data-aos="fade-down" data-aos-duration="1000">
                    <div className="col-lg-9 col-md-7">
                        <div className="heading-type-two text-capitalize">
                            <h2>Our <span>management</span></h2>
                        </div>
                    </div>
                    <div className="col-lg-3  col-md-5 text-end">
                        <div className="management-action">
                            <a className="yellow-btn-wrap d-inline-block" href="#"><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                                <span className="ms-3"><img src="/images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="2500">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management1.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about text-center">
                                <h3>Abhijeet Sharma</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="2000">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management2.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about dark-green-bg text-center">
                                <h3>Rachel Hughes</h3>
                                <span>Sales Head</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="1500">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management3.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about text-center">
                                <h3>Emily Gray</h3>
                                <span>Human Resource</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6" data-aos="fade-right" data-aos-duration="1000">
                        <div className="management-card">
                            <div className="management-visual">
                                <img src="/images/visuals/management4.jpg" alt="" className="img-fluid"/>
                            </div>
                            <div className="founder-about dark-green-bg text-center">
                                <h3>Alison Grant</h3>
                                <span>Designation</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <!--Welcome Our Curiosity--> */}
    <section className="welcome-our-curiosity">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <form action="#" method="get">
                    
                        <div className="curiosity-form m-auto">
                            <div className='row'>
                            <div className='col-md-4'>
                                <div className="form-input mb-4">
                                    <input type="text" placeholder="Your name"  id="" className="form-control border-0 rounded-0" name="name" value={formData.name} onChange={handleChange} required />
                                    <p className="form-error text-danger">
                                                        {errors.name ? errors.name : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                            <div className="form-input mb-4">
                                    <input type="email"placeholder="Your email"   id="" className="form-control border-0 rounded-0" name="email" value={formData.email} onChange={handleChange} required/>
                                    <p className="form-error text-danger">
                                                        {errors.email ? errors.email : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                            <div className="form-input mb-4">
                                    <input type="tel"placeholder="Phone"   id="" className="form-control border-0 rounded-0" name="phone" value={formData.phone} onChange={handleChange} required/>
                                    <p className="form-error text-danger">
                                                        {errors.phone ? errors.phone : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-input h-100">
                                    <textarea placeholder="Message" className="textarea-field form-control border-0 rounded-0" name="message" value={formData.message} onChange={handleChange} required></textarea>
                                    <p className="form-error text-danger">
                                                        {errors.message ? errors.message : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-12 text-end mt-4'>
                                <div className="form-submit">
                                {sendEnquireDat.isLoading ? (<button className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                            <span role="status">Loading...</span>
                                                        </button>) : (<button type="submit" onClick={sendEmail} className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white">Submit</button>)}
                                    {/* <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white"/> */}
                                </div>
                            </div>
                        </div>
                            <div className="form-checkbox d-none d-md-none justify-content-center position-relative">
                                <label htmlFor="TermConditionOne" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermConditionOne"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                            
                        </div>
                        <div className="form-checkbox d-none mt-lg-5 mt-4 justify-content-center position-relative">
                            <label htmlFor="TermCondition" className="d-flex align-items-center">
                                <input type="checkbox" name="" id="TermCondition"/>
                                <div className="check-term"></div>
                                <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    {/* <section className="banner-inner blog-visual position-relative">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="banner-title text-center">
                    <h1>Our <span>Management</span></h1>
                        <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                            <div className="logo-circle">
                                <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                            </div>
                        </div>
                        <div className="short-intro-text mt-xl-5 mt-4">
                        <p className='text-white'>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="founder">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="founder-media-object">
                        <div className="row justify-content-around">
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="founder-media-object-visual">
                                    <img src="/images/visuals/management1.jpg" alt="" className="img-fluid"/>
                                    <div className="founder-about">
                                        <h3>Andrew Davies</h3>
                                        <span>Marketing Manager</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="fade-left" data-aos-duration="1000">
                                <div className="founder-media-object-body">
                                    <div className="heading-type-two">
                                        <h2>Marketing <span>manager</span></h2>
                                    </div>
                                    <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...</p>
                                    <div className="founder-action mt-4">
                                        <a href="" className="btn btn-atmosphere btn-yellow">Read more</a>
                                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-around flex-row-reverse">
                            <div className="col-md-3" data-aos="fade-left" data-aos-duration="1000">
                                <div className="founder-media-object-visual">
                                    <img src="/images/visuals/management2.jpg" alt="" className="img-fluid"/>
                                    <div className="founder-about">
                                        <h3>Rachel Hughes</h3>
                                        <span>Sales Head</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="fade-right" data-aos-duration="1000">
                                <div className="founder-media-object-body">
                                    <div className="heading-type-two">
                                        <h2>Sales <span>Head</span></h2>
                                    </div>
                                    <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...</p>
                                    <div className="founder-action mt-4">
                                        <a href="" className="btn btn-atmosphere btn-yellow">Read more</a>
                                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-around">
                            <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                                <div className="founder-media-object-visual">
                                    <img src="/images/visuals/management3.jpg" alt="" className="img-fluid"/>
                                    <div className="founder-about">
                                        <h3>Emily Gray</h3>
                                        <span>Human Resource</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7" data-aos="fade-left" data-aos-duration="1000">
                                <div className="founder-media-object-body">
                                    <div className="heading-type-two">
                                        <h2>Human <span>Resource</span></h2>
                                    </div>
                                    <p>Atmosphere is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book...</p>
                                    <div className="founder-action mt-4">
                                        <a href="" className="btn btn-atmosphere btn-yellow">Read more</a>
                                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="management">
        <div className="container container-custom">
            <div className="row align-items-center" data-aos="fade-down" data-aos-duration="1000">
                <div className="col-md-9">
                    <div className="heading-type-two">
                        <h2>Our <span>Team</span></h2>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="management-action mt-4">
                        <a href="our-team.html" className="btn btn-atmosphere btn-yellow">Know more</a>
                        <img src="/images/icon/arrow-banner-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="2500">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management1.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="2000">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management2.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="1500">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management3.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="fade-right" data-aos-duration="1000">
                    <div className="management-card">
                        <div className="management-visual">
                            <img src="/images/visuals/management4.jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="management-body">
                            <h4>Abhijeet Sharma</h4>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="contact-form-inner">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-form mt-0">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="contact-form-heading text-center">
                                    <h2>Our doors are wide open and we welcome your curiosity</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="text" className="form-control" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-form-input">
                                            <input type="email" className="form-control" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-5">
                                        <div className="contact-form-radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                                <label className="form-check-label" for="terms">
                          I accept <b>Terms &amp; Conditions</b> for procesing personal data
                        </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-form-input">
                                    <button type="submit" className="btn btn-light w-100">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
   </>
  )
}

export default OurManagement