import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({  ...settings }) => {
    return (
        <Slider {...settings} >
             <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/chopper.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className="f-wraper">
                                                        <span>Luxury Travel</span>
                                                        <p>Travel in style with our on-demand luxury transportation services.
                                                        </p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/crown.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                <div className="f-wraper">
                                                    <span>VIP Access</span>
                                                    <p>Get priority access to global sporting events, concerts and more.
                                                    </p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/elite-community.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                <div className="f-wraper">
                                                    <span>Elite Community</span>
                                                    <p>Immerse in a vibrant community that appreciates the finer things in life.</p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/elegant-clubhouse.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                <div className="f-wraper">
                                                    <span>Elegant Clubhouse</span>
                                                    <p>Socialise in elegant lounges, bars, and entertainment spaces.
                                                    </p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/chopper.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                <div className="f-wraper">
                                                    <span>Chopper Service</span>
                                                    <p>Take your travel to new heights with a chopper on call.
                                                    </p>
                                                    </div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        </Slider>
    );
};

export default CustomSlider;
