import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({  ...settings }) => {
    return (
        <Slider {...settings} >
          <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/personal-butler.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Personal Butler</span>
                                                    <p>Always at your service for simple and special requests.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/meeting.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Meeting Rooms</span>
                                                    <p>Modern spaces perfect for co-working and business gatherings.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/housekeeping.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>24x7 Housekeeping</span>
                                                    <p>Always feel at home in a perfectly maintained residence.</p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/gourmet-expericence.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Gourmet Experiences</span>
                                                    <p>In-residence dining, cafes, and bars serve culinary delights.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="/images/icon/personal-butler.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <div className='f-wraper'>
                                                    <span>Personal Butler</span>
                                                    <p>Always at your service for simple and special requests.
                                                    </p></div>
                                                    <div className="bottom-card-action text-end">
                                                        <img class="d-inline" src="/images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        </Slider>
    );
};

export default CustomSlider;
