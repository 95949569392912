import React, { useState } from 'react'
import { sendEnquireData } from '../dataService/DataService';
//import nodemailer from "nodemailer"
import { toast } from "react-toastify";
import { useMutation } from 'react-query';

const ResponsibleDevelopment = () => {
    const [formData, setFormData] = useState({
        name: '',
         phone: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const newErrors = { ...errors };

        if (name === "name" && !value) {
            newErrors.name = "Please enter full name";
        } else if (name === "name") {
            delete newErrors.name; // Clear the error if the field is valid
        }

        if (name === "email" && !value) {
            newErrors.email = "Please enter email";
        } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = "Please enter valid email";
        } else if (name === "email") {
            delete newErrors.email;
        }

        if (name === "phone" && !value) {
            newErrors.phone = "Please enter phone no.";
        } else if (name === "phone" && !/^\d{10}$/.test(value)) {
            newErrors.phone = "Please enter valid phone no.";
        } else if (name === "phone") {
            delete newErrors.phone;
        }

        if (name === "message" && !value) {
            newErrors.message = "Please enter message";
        } else if (name === "message") {
            delete newErrors.message;
        }

        // Set the updated errors object
        setErrors(newErrors);

    };
    function sendEmail() {
        console.log("button blicked ")
        console.log("formData")
        console.log(formData)
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Please enter full name";
        }
        if (!formData.email) {
            newErrors.email = "Please enter email";
        }
        if (!formData.phone) {
            newErrors.phone = "Please enter phone no.";
        }
        if (!formData.message) {
            newErrors.message = "Please enter message";
        }
        if (Object.keys(newErrors).length === 0) {
            sendEnquireDat.mutate(formData);
            setErrors(newErrors);
        } else {
            setErrors(newErrors);

        }
    }

    const sendEnquireDat = useMutation(sendEnquireData, {
        onError: (error) => {
            toast.error(error.message);

        },
        onSuccess: (data) => {

            toast.success("Enquiry submitted. We will contact you soon.");
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: '',
            });
            // console.log("Succesful",{data});
        },
    });
  return (
    <>
   <section>
        <div className="banner-inner responsible-development overflow-hidden">
            <div className="container container-custom h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-md-8">
                        <div className="banner-title text-center">
                            <h1>Responsible <span>Development</span></h1>
                            <div className="page-title-logo position-relative m-auto mt-lg-5 mt-4">
                                <div className="logo-circle">
                                    <img src="/images/icon/logo-circle.svg" alt="Atamosphere" title="Atmosphere"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Sustainability --> */}
    <section>
        <div className="sustainability sustainability-points pb-lg-5 pb-4">
            <div className="container">
                <div className="row" data-aos="fade-down" data-aos-duration="1000">
                    <div className="col-lg-8 col-md-7">
                        <div className="heading-type-two">
                            <h2>Shaping a <span>better tomorrow</span></h2>
                        </div>
                        <div className="sustainability-body">
                            <p>Being responsible in our operations isn't just a checkmark on our list, it is woven into our pursuit of well-being and building a better tomorrow for everyone.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="download-brochure-action mb-lg-0 mb-4 text-md-end">
                            <a target="blank" href="/images/AtmosphereLivingBrochure.pdf" className="green-btn-wrap"><span className="btn theme-btn green-theme-btn md-btn rounded-0 text-uppercase text-white fw-semibold">Download Brochure <img src="/images/icon/download.svg" alt="Download" title="Download"/></span></a>
                            <a href=""></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sustainability-inner pt-4">

                <div className="sustainability-panel h-100">
                    <div className="container container-custom h-100">
                        <div className="row">
                            <div className="col-lg-6 order-lg-0 order-2">
                                <div className="sustainability-description d-flex flex-column">
                                    <div className="sustainability-point-card d-flex align-items-center mt-0" data-aos="fade-down" data-aos-duration="1000">
                                        <div className="point-card-icon">
                                            <img src="/images/icon/environment-sustainability.svg" alt=""/>
                                        </div>
                                        <div className="point-card-body">
                                            <h5>Eco-conscious</h5>
                                            <p>We craft modern living spaces with an eco-conscious touch.</p>
                                        </div>
                                    </div>
                                    <div className="sustainability-point-card d-flex align-items-center" data-aos="fade-down" data-aos-duration="1500">
                                        <div className="point-card-icon">
                                            <img src="/images/icon/eco-friendly-recycle.svg" alt=""/>
                                        </div>
                                        <div className="point-card-body">
                                            <h5>Lush Living</h5>
                                            <p>We blend urban comfort seamlessly with serene natural surroundings.</p>
                                        </div>
                                    </div>
                                    <div className="sustainability-point-card d-flex align-items-center" data-aos="fade-down" data-aos-duration="2000">
                                        <div className="point-card-icon">
                                            <img src="/images/icon/environment-sustainability.svg" alt=""/>
                                        </div>
                                        <div className="point-card-body">
                                            <h5>Waste Wise</h5>
                                            <p>We use smart recycling solutions for efficient waste and water management.</p>
                                        </div>
                                    </div>
                                    <div className="sustainability-point-card d-flex align-items-center mb-0" data-aos="fade-down" data-aos-duration="2500">
                                        <div className="point-card-icon">
                                            <img src="/images/icon/eco-friendly-recycle.svg" alt=""/>
                                        </div>
                                        <div className="point-card-body">
                                            <h5>Carbon Conscious</h5>
                                            <p>We are responsible in our operations to minimise our environmental impact.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="sustainability-visual" data-aos="fade-left" data-aos-duration="1000">
                                    <img src="/images/visuals/solar-house.jpg" alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Villas --> */}
    <section>
        <div className="villas">
            <div className="container container-custom">
                <div className="row">
                    <div className="col-md-12" data-aos="fade-down" data-aos-duration="1000">
                        <div className="heading-type-two text-center">
                            <h2>We build <span>eco-conscious</span> residences</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-2">
                        <ul className="villa-panel list-unstyled d-flex flex-wrap">
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/water.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Recycling </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/waste-green-icon.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Solar Panels </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/tree-white-icon.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Vegetation</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/waste-green-icon.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Composting </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        <div className="villa-inner-visual m-auto mt-4">
                            <img src="/images/visuals/villa1.png" alt="" className="img-fluid" data-aos="fade-up" data-aos-duration="2000"/>
                            <ul className="list-unstyled m-auto d-flex justify-content-between position-absolute start-0 end-0 top-0 w-100">
                                <li>
                                    <div className="eco-friendly-label text-center" data-aos="fade-down" data-aos-duration="2000">
                                        <span>LED Lights</span>
                                        <div className="label-bottom-lines mt-3 position-relative"></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="eco-friendly-label position-relative text-center" data-aos="fade-down" data-aos-duration="2000">
                                        <span>Green Roof</span>
                                        <div className="label-bottom-lines mt-3 position-relative"></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="eco-friendly-label position-relative text-center" data-aos="fade-down" data-aos-duration="2000">
                                        <span>Rainwater Harvesting</span>
                                        <div className="label-bottom-lines mt-3 position-relative"></div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="bottom-list-points list-unstyled m-auto d-flex justify-content-between position-absolute start-0 end-0 bottom-0 w-100">
                                <li>
                                    <div className="eco-friendly-label text-center d-flex flex-column-reverse" data-aos="fade-down" data-aos-duration="2000">
                                        <span>Energy-Efficient</span>
                                        <div className="label-bottom-lines mb-3 position-relative"></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="eco-friendly-label text-center d-flex flex-column-reverse" data-aos="fade-down" data-aos-duration="2000">
                                        <span>Smart-Controlled</span>
                                        <div className="label-bottom-lines mb-3 position-relative"></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <ul className="villa-panel right-villa-panel d-flex justify-content-end list-unstyled d-flex flex-wrap">
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/light-green-icon.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Biodiversity</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/light.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Landscaping</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/tree-green-icon.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Water Wise</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="villa-card d-flex align-items-center justify-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="eco-friendly-card">
                                        <div className="villa-card-icon text-center">
                                            <img src="/images/icon/light.svg" alt=""/>
                                        </div>
                                        <div className="villa-card-body w-100 text-center text-white text-capitalize">
                                            <span>Smart Tech</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!--Welcome Our Curiosity--> */}
    <section className="welcome-our-curiosity">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <form action="#" method="get">
                    
                        <div className="curiosity-form m-auto">
                            <div className='row'>
                            <div className='col-md-4'>
                                <div className="form-input mb-4">
                                    <input type="text" placeholder="Your name"  id="" className="form-control border-0 rounded-0" name="name" value={formData.name} onChange={handleChange} required />
                                    <p className="form-error text-danger">
                                                        {errors.name ? errors.name : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                            <div className="form-input mb-4">
                                    <input type="email"placeholder="Your email"   id="" className="form-control border-0 rounded-0" name="email" value={formData.email} onChange={handleChange} required/>
                                    <p className="form-error text-danger">
                                                        {errors.email ? errors.email : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                            <div className="form-input mb-4">
                                    <input type="tel"placeholder="Phone"   id="" className="form-control border-0 rounded-0" name="phone" value={formData.phone} onChange={handleChange} required/>
                                    <p className="form-error text-danger">
                                                        {errors.phone ? errors.phone : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-input h-100">
                                    <textarea placeholder="Message" className="textarea-field form-control border-0 rounded-0" name="message" value={formData.message} onChange={handleChange} required></textarea>
                                    <p className="form-error text-danger">
                                                        {errors.message ? errors.message : ""}
                                                    </p>
                                </div>
                            </div>
                            <div className='col-12 text-end mt-4'>
                                <div className="form-submit">
                                {sendEnquireDat.isLoading ? (<button className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                            <span role="status">Loading...</span>
                                                        </button>) : (<button type="submit" onClick={sendEmail} className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white">Submit</button>)}
                                    {/* <input type="submit" value="SUBMIT" name="" id="" className="btn theme-btn yellow-theme-btn rounded-0 fw-medium text-white"/> */}
                                </div>
                            </div>
                        </div>
                            <div className="form-checkbox d-none d-md-none justify-content-center position-relative">
                                <label htmlFor="TermConditionOne" className="d-flex align-items-center">
                                    <input type="checkbox" name="" id="TermConditionOne"/>
                                    <div className="check-term"></div>
                                    <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                                </label>
                            </div>
                            
                        </div>
                        <div className="form-checkbox d-none mt-lg-5 mt-4 justify-content-center position-relative">
                            <label htmlFor="TermCondition" className="d-flex align-items-center">
                                <input type="checkbox" name="" id="TermCondition"/>
                                <div className="check-term"></div>
                                <span>I accept <a href="#" className="fw-semibold text-decoration-none">Terms & Conditions</a> for processing personal data</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    {/* <section className="banner-inner sustainability-visual">
        <div className="container container-custom h-100">
            <div className="row justify-content-center h-100">
                <div className="col-md-8">
                    <div className="banner-title text-center">
                        <h1>Responsible Development</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="sustainability sustainability-page">
        <div className="container container-custom">
            <div className="row align-items-center justify-content-end" data-aos="fade-down" data-aos-duration="1000">
                <div className="col-md-6">
                    <div className="heading-type-two">
                        <h2>Living Responsibly for a <span>better tomorrow</span></h2>
                    </div> 
                    <div className="sustainability-body">
                        <p>Sustainability isn't a mere checkbox on our list; it's woven into the very fabric of our ethos, rooted in the pursuit of well-being and the joy of giving. Sustainability is not just a responsibility but a joyous celebration of a brighter, greener future.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="sustainability-action text-end">
                        <a href="" className="btn btn-atmosphere btn-green">DOWNLOAD BROCHURE</a>
                        <img src="/images/icon/arrowgreen-right-icon.svg" alt="" className="ms-4"/>
                    </div>
                </div>
            </div>
        </div>

        <div className="sustainability-inner pt-4">

            <div className="sustainability-panel h-100">
                <div className="container container-custom h-100">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sustainability-description">
                                <div className="sustainability-point-card mt-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div className="point-card-icon">
                                        <img src="/images/icon/environment-sustainability.svg" alt=""/>
                                    </div>
                                    <div className="point-card-body">
                                        <h5>Environmental Stewardship</h5>
                                        <p>Our eco-conscious approach involves innovative land use to preserve local heritage.</p>
                                    </div>
                                </div>
                                <div className="sustainability-point-card" data-aos="fade-down" data-aos-duration="1500">
                                    <div className="point-card-icon">
                                        <img src="/images/icon/eco-friendly-recycle.svg" alt=""/>
                                    </div>
                                    <div className="point-card-body">
                                        <h5>Eco-friendly Measures</h5>
                                        <p>We utilise technology to minimise water & energy use, waste management, and using renewables when possible.</p>
                                    </div>
                                </div>
                                <div className="sustainability-point-card" data-aos="fade-down" data-aos-duration="2000">
                                    <div className="point-card-icon">
                                        <img src="/images/icon/environment-sustainability.svg" alt=""/>
                                    </div>
                                    <div className="point-card-body">
                                        <h5>Green Spaces & Biodiversity</h5>
                                        <p>Our landscaping preserves native plants, fosters wildlife habitats, and enhances residents' well-being through ecological balance.</p>
                                    </div>
                                </div>
                                <div className="sustainability-point-card mb-0" data-aos="fade-down" data-aos-duration="2500">
                                    <div className="point-card-icon">
                                        <img src="/images/icon/eco-friendly-recycle.svg" alt=""/>
                                    </div>
                                    <div className="point-card-body">
                                        <h5>Well-being & Healthy Living</h5>
                                        <p>Promoting well-being with sustainable design, our residences offer natural ventilation, non-toxic materials, and abundant natural light.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sustainability-visual" data-aos="fade-left" data-aos-duration="1000">
                                <img src="/images/visuals/solar-house.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="villas">
        <div className="container container-custom">
            <div className="row">
                <div className="col-md-12" data-aos="fade-down" data-aos-duration="1000">
                    <div className="heading-type-two text-center">
                        <h2>We build <span>Eco-friendly</span> Villas</h2>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-md-2">
                    <div className="villa-panel villa-panel-left">
                        <div className="villa-card" data-aos="fade-right" data-aos-duration="1000">
                            <div className="villa-card-icon">
                                <img src="/images/icon/water.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>water</span>
                            </div>
                        </div>
                        <div className="villa-card" data-aos="fade-right" data-aos-duration="1500">
                            <div className="villa-card-icon">
                                <img src="/images/icon/waste-green-icon.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>waste</span>
                            </div>
                        </div>
                        <div className="villa-card" data-aos="fade-right" data-aos-duration="2000">
                            <div className="villa-card-icon">
                                <img src="/images/icon/tree-white-icon.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>Vegetation</span>
                            </div>
                        </div>
                        <div className="villa-card" data-aos="fade-right" data-aos-duration="2500">
                            <div className="villa-card-icon">
                                <img src="/images/icon/waste-green-icon.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>waste</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="villa-inner-visual">
                        <img src="/images/visuals/villa1.png" alt="" className="img-fluid" data-aos="fade-up" data-aos-duration="2000"/>
                        <div className="label label-one" data-aos="fade-down" data-aos-duration="2000">
                            <span>Lorem ipsum</span>
                        </div>
                        <div className="label label-two" data-aos="fade-down" data-aos-duration="2000">
                            <span>Lorem ipsum</span>
                        </div>
                        <div className="label label-three" data-aos="fade-down" data-aos-duration="2000">
                            <span>Lorem ipsum</span>
                        </div>
                        <div className="label label-four" data-aos="fade-up" data-aos-duration="2000">
                            <span>Lorem ipsum</span>
                        </div>

                        <div className="label label-five" data-aos="fade-up" data-aos-duration="2000">
                            <span>Lorem ipsum</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="villa-panel villa-panel-right">
                        <div className="villa-card" data-aos="fade-left" data-aos-duration="1000">
                            <div className="villa-card-icon">
                                <img src="/images/icon/light-green-icon.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>energy</span>
                            </div>
                        </div>
                        <div className="villa-card" data-aos="fade-left" data-aos-duration="1500">
                            <div className="villa-card-icon">
                                <img src="/images/icon/light.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>LED lights</span>
                            </div>
                        </div>
                        <div className="villa-card" data-aos="fade-left" data-aos-duration="2000">
                            <div className="villa-card-icon">
                                <img src="/images/icon/tree-green-icon.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>vegetation</span>
                            </div>
                        </div>
                        <div className="villa-card" data-aos="fade-left" data-aos-duration="2500">
                            <div className="villa-card-icon">
                                <img src="/images/icon/light.svg" alt=""/>
                            </div>
                            <div className="villa-card-body">
                                <span>LED lights</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="contact-form">
        <div className="container container-custom">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="contact-form-heading text-center">
                        <h2>Our doors are wide open and we welcome your curiosity</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-md-7">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="contact-form-input">
                                <input type="text" className="form-control" placeholder="Your Name"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-form-input">
                                <input type="email" className="form-control" placeholder="Your Email"/>
                            </div>
                        </div>
                        <div className="col-md-8 mt-5">
                            <div className="contact-form-radio">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="terms"/>
                                    <label className="form-check-label" for="terms">
                    I accept <b>Terms &amp; Conditions</b> for procesing personal data
                  </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contact-form-input">
                        <button type="submit" className="btn btn-light w-100">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>
  )
}

export default ResponsibleDevelopment