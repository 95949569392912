'use client'
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlider1 from './Slider1';
import CustomSlider2 from './Slider2';
import CustomSlider3 from './Slider3';
import CustomSlider4 from './Slider4';
import { NavLink } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import Image from 'next/image'
// import { useSpring, animated } from '@react-spring/web'
// import { useState,useEffect,useRef } from 'react';
// import Slider from "react-slick";
export default function Section1() {
    const sliderSettings = {
       // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768, 
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      };
    // const settings={
    //    // dots: true,  
    //     // infinite: true,
    //     // speed: 500,
    //     // slidesToShow: 4,
    //     // slidesToScroll: 1,
    //     // centerPadding: "60px",
    //     className: "center",
    //     infinite: true,
    //     centerPadding: "10px",
    //     slidesToShow: 4,
    //     swipeToSlide: true,
    // }
return (
<>

<section>
        <div className="feature-wrapper position-relative">
            <div className="tab-header">
                <ul className="nav nav-pills d-flex flex-nowrap" id="pills-tab" role="tablist">
                    <li className="nav-item w-100" role="presentation">
                        <button className="nav-link smooth w-100 h-100 d-flex rounded-0 align-items-center justify-content-center text-capitalize active" id="pills-brandedresidences-tab" data-bs-toggle="pill" data-bs-target="#pills-brandedresidences" type="button" role="tab" aria-controls="pills-brandedresidences" aria-selected="true">
                            <img className="me-xl-3" src="../images/icon/branded-residances.svg" alt=""/>
                            <span>Branded Residences </span>
                        </button>
                    </li>
                    <li className="nav-item w-100" role="presentation">
                        <button className="nav-link smooth w-100 h-100 d-flex rounded-0 align-items-center justify-content-center text-capitalize" id="pills-conciergeservices-tab" data-bs-toggle="pill" data-bs-target="#pills-conciergeservices" type="button" role="tab" aria-controls="pills-conciergeservices" aria-selected="false">
                            <img className="me-xl-3" src="../images/icon/concierge-services.svg" alt=""/> 
                            <span>Concierge Services</span></button>
                    </li>
                    <li className="nav-item w-100" role="presentation">
                        <button className="nav-link smooth w-100 h-100 d-flex rounded-0 align-items-center justify-content-center text-capitalize" id="pills-exclusiveprivileges-tab" data-bs-toggle="pill" data-bs-target="#pills-exclusiveprivileges" type="button" role="tab" aria-controls="pills-exclusiveprivileges" aria-selected="false">
                            <img className="me-xl-3"  src="../images/icon/crown.svg" alt=""/>
                            <span>Exclusive Privileges</span> </button>
                    </li>
                    <li className="nav-item w-100" role="presentation">
                        <button className="nav-link smooth w-100 h-100 d-flex rounded-0 align-items-center justify-content-center text-capitalize" id="pills-rentalpool-tab" data-bs-toggle="pill" data-bs-target="#pills-rentalpool" type="button" role="tab" aria-controls="pills-rentalpool" aria-selected="false">
                            <img className="me-xl-3"  src="../images/icon/rental-pool.svg" alt=""/>
                            <span>Rental Pool</span> </button>
                    </li>
                </ul>
            </div>
            {/* <!-- /tab-header --> */}

          <div className="tab-container feature-inner-wrapper position-relative">
            <div className="container z-1 position-relative">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-brandedresidences" role="tabpanel" aria-labelledby="pills-brandedresidences-tab" tabindex="0">
                                <div className="feature-tab-pane">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="feature-text">
                                                <div className="feature-heading">
                                                    <div className="small-heading">
                                                        <hr/><span>branded residences</span>
                                                    </div>
                                                    <div className="secondary-heading mb-lg-5 mb-4">
                                                        <h2>A living experience as <span className="yellow-text">unique as you</span></h2>
                                                    </div>
                                                </div>
                                                <div className="feature-body">
                                                    <p>Your residence is a private paradise, an oasis for the senses, with every aspect thoughtfully curated and bespoke to appeal to your discerning tastes.<br/>It is a unique and refined living experience, intently crafted to enhance your life in a space that inspires elegance and sophistication.</p>
                                                    <div className="feature-body-action mt-lg-5 mt-md-4 mt-3">
                                                        <NavLink to="/MediaGallery" className="yellow-btn-wrap d-none" ><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0 ">learn more</span>
                                                            <span className="ms-3"><img src="../images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="features-window d-flex justify-content-end align-items-start">
                                                <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                    <span className="d-flex overflow-hidden"><img src="../images/visuals/feature1.jpg" alt=""/></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-inner-slider position-relative">
                                    <CustomSlider1 tabIndex={0} {...sliderSettings} />
                                        {/* <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/renowned-architects.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Renowned Architects</span>
                                                    <p>They craft a world with every imaginable indulgence under one roof.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/acclaimed-designers.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Acclaimed Designers</span>
                                                    <p>They blend luxury with intuitive design to create captivating spaces.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/bespoke-designs.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Bespoke Designs</span>
                                                    <p>A design-forward residence that reflects and celebrates your individuality.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/gated-community.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Gated Community</span>
                                                    <p>Controlled access points, monitored entrances and security personnel.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide-arrows">
                                            <button className="btn btn-yellow-arrow yellow-prev"><i className="fa fa-chevron-left"></i></button>
                                            <button className="btn btn-yellow-arrow yellow-next"><i className="fa fa-chevron-right"></i></button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /end tab branded residences --> */}

                            <div className="tab-pane fade" id="pills-conciergeservices" role="tabpanel" aria-labelledby="pills-conciergeservices-tab" tabindex="0">
                                <div className="feature-tab-pane">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="feature-text">
                                                <div className="feature-heading">
                                                    <div className="small-heading">
                                                        <hr/><span>concierge service</span>
                                                    </div>
                                                    <div className="secondary-heading mb-lg-5 mb-4">
                                                        <h2>Personalised services with a <span className="yellow-text">personal touch</span></h2>
                                                    </div>
                                                </div>
                                                <div className="feature-body">
                                                    <p>We have tailored our concierge services to anticipate and fulfil your needs, delivering a personalised and private experience. Whether you want to arrange a party or book a chef for an intimate dinner, with a simple request, your every desire is met and exceeded with a personal  touch.</p>
                                                    <div className="feature-body-action mt-lg-5 mt-md-4 mt-3">
                                                        <NavLink to="/MediaGallery" className="yellow-btn-wrap d-none" ><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0  ">learn more</span>
                                                            <span className="ms-3"><img src="../images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="features-window d-flex justify-content-end align-items-start">
                                                <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                    <span className="d-flex overflow-hidden"><img src="../images/visuals/feature2.jpg" alt=""/></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-inner-slider position-relative second-features-tabs">
                                    <CustomSlider2 tabIndex={0} {...sliderSettings} />
                                        {/* <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/personal-butler.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Personal Butler</span>
                                                    <p>Always at your service for simple and special requests.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/meeting.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Meeting Rooms</span>
                                                    <p>Well-equipped spaces are perfect for co-working or conducting business.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/housekeeping.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>24X7 Housekeeping</span>
                                                    <p>Always feel at home in a perfectly maintained residence.</p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/gourmet-expericence.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Gourmet Experiences</span>
                                                    <p>In-residence dining, cafes, and bars serve culinary delights.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="slide-arrows">
                                            <button className="btn btn-yellow-arrow yellow-prev"><i className="fa fa-chevron-left"></i></button>
                                            <button className="btn btn-yellow-arrow yellow-next"><i className="fa fa-chevron-right"></i></button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /end tab concierge services --> */}

                            <div className="tab-pane fade" id="pills-exclusiveprivileges" role="tabpanel" aria-labelledby="pills-exclusiveprivileges-tab" tabindex="0">
                                <div className="feature-tab-pane">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="feature-text">
                                                <div className="feature-heading">
                                                    <div className="small-heading">
                                                        <hr/><span>exclusive privileges</span>
                                                    </div>
                                                    <div className="secondary-heading mb-lg-5 mb-4">
                                                        <h2>Your imagination, <span className="yellow-text">served by us</span></h2>
                                                    </div>
                                                </div>
                                                <div className="feature-body">
                                                    <p>A blank canvas awaits, ready for your imaginative touch. Picture this: in the morning, you hit the spa, followed by a lunch at the clubhouse, and as the night falls, you hop on a scenic aerial tour. The possibilities are endless in a haven where indulgence knows no bounds. We are here to serve everything you can imagine and more.</p>
                                                    <div className="feature-body-action mt-lg-5 mt-md-4 mt-3">
                                                        <NavLink to="/MediaGallery" className="yellow-btn-wrap d-none" ><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0">learn more</span>
                                                            <span className="ms-3"><img src="../images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="features-window d-flex justify-content-end align-items-start">
                                                <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                    <span className="d-flex overflow-hidden"><img src="../images/visuals/feature3.jpg" alt=""/></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-inner-slider position-relative">
                                    <CustomSlider3 tabIndex={0} {...sliderSettings} />  
                                        {/* <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/chopper.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Chopper Service</span>
                                                    <p>Take your travel to new heights with a chopper on call.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/crown.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>VIP Access</span>
                                                    <p>Get priority access to global sporting events, concerts and more.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/elite-community.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Elite Community</span>
                                                    <p>Immerse in a vibrant community of individuals who appreciate the finer...</p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/elegant-clubhouse.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Elegant Clubhouse</span>
                                                    <p>Socialise in elegant lounges, bars, and entertainment spaces.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide-arrows">
                                            <button className="btn btn-yellow-arrow yellow-prev"><i className="fa fa-chevron-left"></i></button>
                                            <button className="btn btn-yellow-arrow yellow-next"><i className="fa fa-chevron-right"></i></button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /end tab exclusive privilages --> */}
                            
                            <div className="tab-pane fade" id="pills-rentalpool" role="tabpanel" aria-labelledby="pills-rentalpool-tab" tabindex="0">
                                <div className="feature-tab-pane">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="feature-text">
                                                <div className="feature-heading">
                                                    <div className="small-heading">
                                                        <hr/><span>rental pool</span>
                                                    </div>
                                                    <div className="secondary-heading mb-lg-5 mb-4">
                                                        <h2>A lifestyle that <span className="yellow-text">keeps rewarding</span></h2>
                                                    </div>
                                                </div>
                                                <div className="feature-body">
                                                    <p>Our branded residences come with ownership benefits beyond property possession, including the opportunity to earn rental income when you are not using it.<br/>Imagine your residence as a gateway to unforgettable experiences for discerning guests while you enjoy the ownership benefits. This rewarding lifestyle continues to give, even when you’re away.</p>
                                                    <div className="feature-body-action mt-lg-5 mt-md-4 mt-3">
                                                        <NavLink to="/MediaGallery" className="yellow-btn-wrap d-none" ><span className="btn theme-btn yellow-theme-btn text-white rounded-0 text-uppercase mb-0 ">learn more</span>
                                                            <span className="ms-3"><img src="../images/icon/green-anchor-arrow.svg" alt="Next" title="Next"/></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="features-window d-flex justify-content-end align-items-start">
                                                <div className="window-visual w-100 overflow-hidden position-relative pe-3 pb-3">
                                                    <span className="d-flex overflow-hidden"><img src="../images/visuals/feature4.jpg" alt=""/></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="features-inner-slider position-relative fourth-features-tabs">
                                    <CustomSlider4 tabIndex={0} {...sliderSettings} /> 
                                        {/* <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/rental-income.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Rental Income</span>
                                                    <p>Generate rental income from your property when not in use.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/flexible-availability.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Flexible Availability</span>
                                                    <p>On-demand availability for your residence, tailored to your preferences.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 yellow-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/prestigious-property.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Prestigious Property</span>
                                                    <p>A prestigious investment for generations to come.</p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card w-100 green-border">
                                            <div className="feature-inner-info">
                                                <div className="feature-card-icon d-flex align-items-center justify-content-center">
                                                    <img src="../images/icon/effortless-ownership.svg" alt=""/>
                                                </div>
                                                <div className="feature-body">
                                                    <span>Effortless Ownership</span>
                                                    <p>We take care of all the hassle, from bookings to maintenance.
                                                    </p>
                                                    <div className="bottom-card-action text-end">
                                                        <img src="../images/icon/thumb-next-arrow.svg" alt="Next" title="Next"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide-arrows">
                                            <button className="btn btn-yellow-arrow yellow-prev"><i className="fa fa-chevron-left"></i></button>
                                            <button className="btn btn-yellow-arrow yellow-next"><i className="fa fa-chevron-right"></i></button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /end tab rental pool --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        </div>
        
        
    </section>

</>




)
    

}